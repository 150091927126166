/**
 * @func getExtent
 * @desc Ermittelt den aktuellen Kartenausschnitt
 * @arg {object} map
 * @memberOf module:Helper
 * @since 2.0.0
 * @returns {array} Kartenausschnitt
 */
const getExtent = map => {
  return map.getView().calculateExtent();
};

/**
 * @func setExtent
 * @desc Zoom auf einen übergebenen Kartenausschnitt
 * @arg {object} map
 * @arg {array} extent
 * @arg {array} [padding=0, 0, 0, 0]
 * @memberOf module:Helper
 * @since 2.0.0
 */
const setExtent = (map, extent, padding = [0, 0, 0, 0]) => {
  if (Array.isArray(extent) && extent.length === 4) {
    map.getView().fit(extent, {
      size: map.getSize(),
      padding: padding
    });
  }
};

export { getExtent, setExtent };
