// OpenLayers
import Draw, { createBox } from 'ol/interaction/Draw';
import * as Extent from 'ol/extent';

// map.nrw
import { ToolsCircleStyle, ToolsPolygonStyle } from './../Styles';

/**
 * @classdesc Erzeugt neue MapExtractByRectTool Instanz
 * @memberOf Module
 * @since 2.0.0
 */
class MapExtractByRectTool {
  /**
   * @desc Erstellt und führt das Setup für das div-Element aus
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.mapCrs = this.map.getView().getProjection().getCode().toUpperCase();

    // Ermittle Tool-Layer, um später die Objekte hinzuzufügen
    this.map.getLayers().forEach(layer => {
      if ((layer.get('name') !== undefined) & (layer.get('name') === 'Tools')) {
        const layers = layer.getLayers().getArray();

        this.toolsSource = {
          marker: null,
          linestring: null,
          polygon: null
        };

        for (let i = 0; i < layers.length; i++) {
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_Marker')) {
            this.toolsSource.marker = layers[i].getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_LineString')) {
            this.toolsSource.linestring = layers[i].getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_Polygon')) {
            this.toolsSource.polygon = layers[i].getSource();
          }
        }
      }
    });

    this.toolDiv = document.getElementById(this.toolId);
    for (const cls of ['itnrwTool', 'ms-1', 'itnrwTool_mapExtractByRectTool']) {
      this.toolDiv.classList.add(cls);
    }
    this.toolDiv.setAttribute('title', 'Rechteck definieren');

    this.toolDiv.addEventListener(
      'mouseover',
      e => {
        this.toolDiv.setAttribute('style', 'background-color: #e1e1e1; cursor: pointer');
      },
      false
    );

    this.toolDiv.addEventListener(
      'mouseout',
      e => {
        this.toolDiv.setAttribute('style', 'background-color: #ffffff; cursor: default');
      },
      false
    );
  }

  /**
   * @desc Aktiviert die Funktionalität
   * @since 2.0.0
   */
  activate() {
    if (this.toolDiv.classList.contains('activated')) {
      this.deactivate();
    } else {
      this.toolDiv.classList.add('activated');

      this.tool = new Draw({
        source: this.toolsSource.polygon,
        type: 'Circle',
        freehand: true,
        geometryFunction: createBox(),
        style: feature => {
          let style;

          if (feature.getGeometry().getType() === 'Circle') {
            style = ToolsCircleStyle(feature);
          } else if (feature.getGeometry().getType() === 'Polygon') {
            style = ToolsPolygonStyle(feature);
          }

          return style;
        }
      });
      this.map.addInteraction(this.tool);

      this.tool.on('drawstart', e => {
        this.toolsSource.polygon.clear();
      });

      this.tool.on('drawend', e => {
        const geojson = {
          type: 'FeatureCollection',
          crs: {
            type: 'name',
            properties: {
              name: this.mapCrs
            }
          },
          features: [
            {
              bbox: [],
              geometry: {
                type: 'Point',
                coordinates: []
              },
              properties: {},
              type: 'Feature'
            }
          ]
        };

        const geom = e.feature.getGeometry();
        const bbox = geom.getExtent();
        const center = Extent.getCenter(bbox);

        geojson.features[0].bbox = bbox;
        geojson.features[0].geometry.coordinates = center;

        // Callback-Funktion aufrufen
        if (typeof window[this.callback] === 'function') {
          window[this.callback](geojson);
        }
      });
    }
  }

  /**
   * @desc Deaktiviert die Funktionalität
   * @since 2.0.0
   */
  deactivate() {
    this.toolDiv.classList.remove('activated');
    this.map.removeInteraction(this.tool);

    // Sourcen leeren
    if (this.toolsSource.marker) {
      this.toolsSource.marker.clear();
    }
    if (this.toolsSource.linestring) {
      this.toolsSource.linestring.clear();
    }
    if (this.toolsSource.polygon) {
      this.toolsSource.polygon.clear();
    }
  }
}

export { MapExtractByRectTool };
