// OpenLayers
import 'ol/ol.css';
import { Overlay } from 'ol';
import { Attribution, defaults as defaultControls } from 'ol/control';
import Control from 'ol/control/Control';
import { platformModifierKeyOnly, shiftKeyOnly, touchOnly } from 'ol/events/condition';
import { defaults as defaultInteractions, DragPan, MouseWheelZoom } from 'ol/interaction';
import DragZoom from 'ol/interaction/DragZoom';
import LayerGroup from 'ol/layer/Group';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';

// map.nrw
import { createGeolocation } from './Controls/Geolocation';
import { createFullscreen } from './Controls/Fullscreen';
import { createZoom } from './Controls/Zoom';
import { getCenter, setCenter, getExtent, setExtent, getZoom, setZoom } from './Helper';
import { HoverInteraction } from './Interaction/Hover';
import { SelectInteraction } from './Interaction/Select';
import { AGSLayer } from './Layer/AGS';
import { GeolocationLayer } from './Layer/Geolocation';
import { GeometryLayer } from './Layer/Geometry';
import { POILayer } from './Layer/POI';
import { SearchLayer } from './Layer/Search';
import { ToolsLayer } from './Layer/Tools';
import { WMSLayer } from './Layer/WMS';
import { WMTSLayer } from './Layer/WMTS';
import { CoordinatePanel } from './Module/CoordinatePanel';
import { SearchPanel } from './Module/SearchPanel';
import { Toggler } from './Module/Toggler';
import { Toolbar } from './Module/Toolbar';
import { Geocoder } from './Geocoder';
import { GeocoderFlur } from './GeocoderFlur';
import { Geometry } from './Geometry';
import { GetFeatureInfo } from './GetFeatureInfo';
import { Parser } from './Parser';
import './Projection';

/**
 * @classdesc Erzeugt eine neue Karteninstanz
 * @since 2.0.0
 */
class InitMap {
  /**
   * @desc Nimmt die konfigurierte Karte / das konfigurierte div-Element und übergibt diese an den Attribut-Parser
   * @arg {object} mapDiv DOM Node der konfigurierten Karte / des konfigurierten div-Elementes
   * @since 2.0.0
   */
  constructor(mapDiv) {
    this.domNode = mapDiv;
    this._parseAttributes();
  }

  /**
   * @desc Entfernt wahlweise alle OpenLayers Features (Adressen, Koordinaten und Flurstücke {@link Layer.POI}, Geometrien {@link Layer.Geometry}, Suchergebenisse {@link Layer.Search} sowie hinzugeladene Dienste {@link Layer.WMS} und {@link Layer.AGS})
   * @arg {string} [type] Weitere, mögliche Werte: addresses, coords, flurstuecke, geometries, layers
   * @example itnrwMaps[0].clear();
   * @example itnrwMaps[0].clear('addresses');
   * @example itnrwMaps[0].clear('coords');
   * @example itnrwMaps[0].clear('flurstuecke');
   * @example itnrwMaps[0].clear('geometries');
   * @example itnrwMaps[0].clear('search');
   * @example itnrwMaps[0].clear('tools');
   * @example itnrwMaps[0].clear('layers');
   * @since 2.0.0
   */
  clear(type) {
    if (type === 'addresses' || type === 'coords' || type === 'flurstuecke') {
      this.poiLayer.cluster.getSource().getSource().clear();
      this.poiLayer.marker.getSource().clear();
    } else if (type === 'geometries') {
      this.geometryLayer.getSource().clear();
    } else if (type === 'search') {
      this.searchLayer.marker.getSource().clear();
      this.searchLayer.polygon.getSource().clear();
    } else if (type === 'tools') {
      this.toolsLayer.marker.getSource().clear();
      this.toolsLayer.polygon.getSource().clear();
    } else if (type === 'layers') {
      [].forEach.call(this.extraLayer, layer => {
        this.map.removeLayer(layer);
      });
      this.extraLayer = [];
    } else {
      this.poiLayer.cluster.getSource().getSource().clear();
      this.poiLayer.marker.getSource().clear();
      this.geometryLayer.getSource().clear();
      this.searchLayer.marker.getSource().clear();
      this.searchLayer.polygon.getSource().clear();
      this.toolsLayer.marker.getSource().clear();
      this.toolsLayer.polygon.getSource().clear();
      [].forEach.call(this.extraLayer, layer => {
        this.map.removeLayer(layer);
      });
      this.extraLayer = [];
    }

    // Deselektierte Features, damit das Popover geschlossen wird
    this.interactions.select.marker.getFeatures().clear();
    this.interactions.select.cluster.getFeatures().clear();
  }

  /**
   * @desc Zeigt eine Benachrichtigung für den Nutzer an
   * @arg {object} element
   * @since 2.0.0
   */
  notify(element) {
    const fadeOut = el => {
      let op = 1;
      const timer = setInterval(() => {
        if (op <= 0.1) {
          clearInterval(timer);
          el.style.display = 'none';
        }
        el.style.opacity = op;
        op -= op * 0.1;
      }, 50);
    };

    const fadeIn = el => {
      let op = 0;
      el.style.opacity = op;
      el.style.display = 'inline-block';

      const timer = setInterval(() => {
        if (op >= 1.0) {
          clearInterval(timer);
        }
        el.style.opacity = op;
        op = op + 0.1;
      }, 50);
    };

    const notification = {
      shown: false,
      show: element => {
        if (!notification.shown) {
          notification.shown = true;
          fadeIn(element);

          setTimeout(() => {
            fadeOut(element);
            notification.shown = false;
          }, 1750);
        }
      }
    };

    notification.show(element);
  }

  /**
   * @desc Setzt die folgende Attribute zur Laufzeit neu und aktualisiert die Kartenansicht: "data-itnrw-extent", "data-itnrw-addresses", "data-itnrw-coords", "data-itnrw-flurstuecke"
   * @arg {string} attribute
   * @arg {string} value
   * @example itnrwMaps[0].set('data-itnrw-extent', '316151.0, 5668812.8, 378829.3, 5691743.9');
   * @example itnrwMaps[0].set('data-itnrw-addresses', '[Mauerstraße 51, 40476 Düsseldorf; IT.NRW; <b>IT.NRW</b><br>Inhalt des Popup-Fensters; IT.NRW; https://map.nrw/images/blue_43_36_8.png], [Kennedydamm 15, 40476 Düsseldorf; IT.NRW Nebenstelle; <b>IT.NRW</b><br>Nebenstelle]');
   * @example itnrwMaps[0].set('data-itnrw-coords', '[epsg:25832; 344565.4, 5679410; IT.NRW Nebenstelle; <strong>IT.NRW</strong> <br> Kennedydamm 15, <br> 40476 Düsseldorf <br> <a href="https://www.it.nrw" target="_blank">IT.NRW</a>]');
   * @example itnrwMaps[0].set('data-itnrw-flurstuecke', '[4304-1-136/3;;;;https://map.nrw/images/blue_43_36_8.png]');
   * @example itnrwMaps[0].set('data-itnrw-geometries', '[polygon; 337930.5, 5675502.1; 347714.5, 5683298.6; 360938.1, 5675043.4; 337930.5, 5675502.1], [extent; 316151.0, 5668812.8, 378829.3, 5691743.9], [circle; 363236.1, 5684747.3; 5000]');
   * @example itnrwMaps[0].set('data-itnrw-layers', '[wms; https://www.wms.nrw.de/geobasis/wms_nw_dop; nw_dop_rgb;; 0.6;], [ags; https://www.gis.nrw.de/arcgis/rest/services/stba/regionalatlas_pro/MapServer; 0]');
   * @since 2.0.0
   */
  set(attribute, value) {
    if (attribute === 'data-itnrw-extent') {
      document.getElementById(this.domNode.id).setAttribute('data-itnrw-extent', value);
      this.config = Parser.parseAttributes(this.domNode);

      let extent = value.split(',');

      if (extent.length === 4) {
        // Entferne Leerzeichen
        extent = [extent[0].trim(), extent[1].trim(), extent[2].trim(), extent[3].trim()];

        setExtent(this.map, extent);
      }
    } else if (attribute === 'data-itnrw-addresses') {
      document.getElementById(this.domNode.id).setAttribute('data-itnrw-addresses', value);
      this.config = Parser.parseAttributes(this.domNode);

      // Lösche den Inhalt des Layers
      this.clear('addresses');

      if (this.config.addresses.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const geocode = new Geocoder({
          addresses: this.config.addresses,
          extent: this.config.extent,
          geocode: true,
          map: this.map,
          zoomlevel: this.config.map.zoomlevel,
          cluster: this.config.cluster.visible
        });
      }
    } else if (attribute === 'data-itnrw-coords') {
      document.getElementById(this.domNode.id).setAttribute('data-itnrw-coords', value);
      this.config = Parser.parseAttributes(this.domNode);

      // Lösche den Inhalt des Layers
      this.clear('coords');

      if (this.config.coords.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const geocode = new Geocoder({
          coordinates: this.config.coords,
          extent: this.config.extent,
          geocode: false,
          map: this.map,
          zoomlevel: this.config.map.zoomlevel,
          cluster: this.config.cluster.visible
        });
      }
    } else if (attribute === 'data-itnrw-flurstuecke') {
      document.getElementById(this.domNode.id).setAttribute('data-itnrw-flurstuecke', value);
      this.config = Parser.parseAttributes(this.domNode);

      // Lösche den Inhalt des Layers
      this.clear('flurstuecke');

      if (this.config.flurstuecke.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const geocode = new GeocoderFlur({
          flurstuecke: this.config.flurstuecke,
          extent: this.config.extent,
          map: this.map,
          zoomlevel: this.config.map.zoomlevel,
          cluster: this.config.cluster.visible
        });
      }
    } else if (attribute === 'data-itnrw-geometries') {
      document.getElementById(this.domNode.id).setAttribute('data-itnrw-geometries', value);
      this.config = Parser.parseAttributes(this.domNode);

      // Lösche den Inhalt des Layers
      this.clear('geometries');

      if (this.config.geometries.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const geometry = new Geometry({
          geometries: this.config.geometries,
          map: this.map
        });
      }
    } else if (attribute === 'data-itnrw-layers') {
      // Lösche den Inhalt des Layers
      this.clear('layers');

      document.getElementById(this.domNode.id).setAttribute('data-itnrw-layers', value);
      this.config = Parser.parseAttributes(this.domNode);

      [].forEach.call(this.config.layers, service => {
        const type = service.type;

        if (type === 'wms') {
          // eslint-disable-next-line no-unused-vars
          const layer = new WMSLayer({
            wms: service,
            map: this.map,
            extraLayer: this.extraLayer
          });
        } else if (type === 'wmts') {
          // eslint-disable-next-line no-unused-vars
          const layer = new WMTSLayer({
            wmts: service,
            map: this.map,
            extraLayer: this.extraLayer
          });
        } else if (type === 'ags') {
          // eslint-disable-next-line no-unused-vars
          const layer = new AGSLayer({
            ags: service,
            map: this.map,
            extraLayer: this.extraLayer
          });
        }
      });
    }
  }

  /**
   * @desc Ruft die Methode {@link Parser.parseAttributes} auf, erstellt die Konfiguration im JSON Format sowie die OpenLayers Konfiguration und lädt die Karte
   * @since 2.0.0
   */
  _parseAttributes() {
    this.config = Parser.parseAttributes(this.domNode);

    // Erstelle div-Element / Container für das Zoomen, Fullscreen
    this._createCustomControlContainer();

    // Erstelle OpenLayers Kartenkonfiguration
    this._createMap();

    // Lade Karte
    this._loadMap();
  }

  /**
   * @desc Erstellt Container für Custom Controls
   * @since 2.0.0
   */
  _createCustomControlContainer() {
    const mapDiv = document.getElementById(this.domNode.id);

    const customControlContainer = document.createElement('div');
    for (const cls of ['m-2', 'btn-group-vertical', 'itnrwCustomControl']) {
      customControlContainer.classList.add(cls);
    }
    mapDiv.appendChild(customControlContainer);
  }

  /**
   * @desc Erstellt OpenLayers Kartenkonfiguration
   * @since 2.0.0
   */
  _createMap() {
    const viewOptions = {
      center: this.config.map.center,
      constrainOnlyCenter: true,
      constrainResolution: true,
      extent: this.config.map.restrictedExtent,
      minZoom: this.config.map.minZoom,
      maxZoom: this.config.map.maxZoom,
      projection: this.config.map.crs,
      showFullExtent: true,
      zoom: this.config.map.zoomlevel
    };

    const view = new View(viewOptions);

    const customControls = [
      new Attribution({
        collapsible: false,
        className: 'ol-attribution itnrwMapAttribution'
      })
    ];

    this.map = new Map({
      layers: [],
      target: this.domNode.id,
      view: view,
      overlays: [],
      // Deaktiviere Default-Controls
      controls: defaultControls({
        attribution: false,
        rotate: false,
        zoom: false
      }).extend(customControls),
      interactions: defaultInteractions({
        dragPan: false,
        altShiftDragRotate: false,
        pinchRotate: false,
        mouseWheelZoom: false
      }).extend([
        new DragZoom({
          className: 'itnrw_DragZoom',
          condition: shiftKeyOnly
        }),
        new MouseWheelZoom({
          condition: evt => {
            if (this.config.map.zoomrestriction && platformModifierKeyOnly(evt) !== true && evt.type === 'wheel') {
              const isOSX = navigator.platform && /(Mac)/i.test(navigator.platform);
              const element = document.getElementById(this.domNode.id + '_notify');

              if (isOSX) {
                element.innerHTML =
                  '<p>Halten Sie die Taste "&#8984;" beim Scrollen gedrückt, um die Karte zu vergrößern</p>';
              } else {
                element.innerHTML = '<p>Verwenden Sie Strg+Scrollen zum Zoomen der Karte</p>';
              }
              this.notify(element);

              return false;
            }

            return true;
          }
        }),
        new DragPan({
          condition: function (evt) {
            const isTouch = touchOnly(evt);
            const isShiftKey = shiftKeyOnly(evt);

            if (isTouch) {
              /**
               * Panning soll auf einem Touch-Device nur mit zwei Fingern funktionieren
               */
              if (this.getPointerCount() === 2) {
                return true;
              } else {
                return false;
              }
            } else {
              /**
               * Beim Aufziehen einer BBox mit Shift darf die Karte nicht verschoben werden
               */
              if (isShiftKey) {
                return false;
              } else {
                return true;
              }
            }
          }
        })
      ])
    });

    this.map.addControl(
      new Control({
        element: document.getElementById(this.domNode.id).querySelector('.itnrwCustomControl')
      })
    );

    /**
     * itnrwMapReady nur einmalig ausführen, wenn die Karte erfolgreich geladen worden ist
     */
    this.map.once('postrender', e => {
      if (typeof window.itnrwMapReady === 'function') {
        window.itnrwMapReady(this.domNode.id, this.map);
      }
    });
  }

  /**
   * @desc Lädt die Karte
   * @since 2.0.0
   */
  _loadMap() {
    // Überprüfe und setze anschließend die Höhe und Breite des "documents" auf 100%
    this._checkDocumentWidthHeight();

    // Setze die Kartenhöhe und -breite entsprechend der Konfiguration
    this._setMapWidthHeight();

    // Erzwingt eine Neuberechnung der Größe des Kartenansichtsfensters. Notwending, wenn sich die Größe des Kartenansichtsfensters ändert!
    this.map.updateSize();

    // HTML div-Elemente für Label (mouse-over) und Popup-Fenster hinzufügen
    this._addElementLabelOnMouseOver();
    this._addElementPopupOnClick();
    this._addElementNotify();
    this._addElementFeatureInfo();
    this._addPanelbar();

    // Füge Overlays zur Karte hinzu
    this._addOverlays();

    // Füge die Hintergrundkarten zur Karte hinzu
    this._addBasemaps();

    // Füge Vektor-Layer für POI, Tools und die Suche, sowie deren Funktionalitäten hinzu (inkl. GetFeatureInfo)
    this._addPOILayer();
    this._addSearchLayer();
    this._addToolsLayer();
    this._addInteractions();

    // Füge Vektor-Layer für Geometrien und die entsprechenden Funktionalitäten hinzu
    this._addGeometryLayer();

    // Füge Vektor-Layer für Geolocation und die entsprechenden Funktionalitäten hinzu
    this._addGeolocationLayer();

    // Füge ein leeres Array unter this hinzu, um alle extra hinzugeladenen Dienste darin zu vereinen, um diese einfacher löschen zu können
    this._addExtraLayer();

    // Verarbeite weitere, vom Nutzer konfigurierte Attribute
    this._processAttributes();

    createZoom(
      this.domNode.id,
      () => {
        this.zoom = this.zoom + 1;
      },
      () => {
        this.zoom = this.zoom - 1;
      }
    );

    if (this.config.map.fullscreen) {
      createFullscreen(this.domNode.id, this);
    }

    if (this.config.map.geolocation && 'geolocation' in navigator) {
      createGeolocation(this.domNode.id, this);
    }
  }

  /**
   * @desc Überprüft die konfigurierte Höhe und Breite und setzt diese durch Aufruf von [_setDocumentWidthHeight]{@link InitMap#_setDocumentWidthHeight}
   * @since 2.0.0
   */
  _checkDocumentWidthHeight() {
    /**
     * Eine konfigurierte Kartenbreite von 100% wird von der API übernommen,
     * die Kartenhöhe von 100% hingegen nicht! Dies ist ungünstig, da in diesem Fall
     * gar keine Karte dargestellt wird.
     *
     * Es muss eine Prüfung erfolgen, ob die gesamte Browserhöhe genutzt werden soll.
     * Die Kartenbreite wird zusätzlich mit in die Prüfung aufgenommen.
     */

    // Regulärer Ausdruck zur Überprüfung auf: "100%" oder "100 %"
    const regex = /100[\s]*%/;
    if (this.config.map.height.match(regex)) {
      this._setDocumentWidthHeight('height');
    }
    if (this.config.map.width.match(regex)) {
      this._setDocumentWidthHeight('width');
    }
  }

  /**
   * @desc Setzt die Höhe und Breite des "document"
   * @arg {*} widthOrHeight
   * @since 2.0.0
   */
  _setDocumentWidthHeight(widthOrHeight) {
    /**
     * Soll die gesamte Browserhöhe bzw. -breite genutzt werden, so sind Styles für html- und body-Tag zu setzen.
     * Dies könnte auch durch Nutzer manuell geschehen, wird aufgrund der Nutzerfreundlichkeit hier dynamisch gesetzt.
     */
    const html = document.getElementsByTagName('html')[0];
    const body = document.getElementsByTagName('body')[0];

    /**
     * Es muss überprüft werden, ob das Attribut 'style' bereits vorhanden ist. Wenn ja, dann muss das Attribut ergänzt
     * und nicht ersetzt werden. Dies ist der Fall, wenn der Nutzer Höhe und Breite mit jeweils 100% angegeben hat.
     * In diesem Fall wird die Methode _setDocumentWidthHeight zweimal ausgeführt.
     */
    let value = html.getAttribute('style');
    if (value) {
      value += ' ' + widthOrHeight + ': 100% !important; margin: 0; padding: 0;';
    } else {
      value = widthOrHeight + ': 100% !important; margin: 0; padding: 0;';
    }

    html.setAttribute('style', value);
    body.setAttribute('style', value);
  }

  /**
   * @desc Setzt die Höhe und Breite der einzelnen Karte
   * @since 2.0.0
   */
  _setMapWidthHeight() {
    /**
     * Die Höhe und Breite der einzelnen Karte (div-Element) wird hier gesetzt.
     * Default-Werte für map.width und map.height werden beim Parsen gesetzt, daher
     * keine separate Überprüfung, ob diese vorhanden sind.
     */
    this.domNode.style.width = this.config.map.width;
    this.domNode.style.height = this.config.map.height;
  }

  /**
   * @desc Fügt ein div-Element zur Darstellung des Textlabel beim Event "mouseover" hinzu
   * @since 2.0.0
   */
  _addElementLabelOnMouseOver() {
    const div = document.createElement('div');
    div.setAttribute('id', this.domNode.id + '_itnrwMapLabel');
    div.setAttribute('class', 'itnrwMapLabel');
    document.body.appendChild(div);
  }

  /**
   * @desc Fügt ein div-Element zur Darstellung des Popups beim Event "click" hinzu
   * @since 2.0.0
   */
  _addElementPopupOnClick() {
    const divCluster = document.createElement('div');
    divCluster.setAttribute('id', this.domNode.id + '_itnrwMapPopupCluster');
    divCluster.setAttribute('class', 'itnrwMapPopup');
    document.body.appendChild(divCluster);

    const divFeatureInfo = document.createElement('div');
    divFeatureInfo.setAttribute('id', this.domNode.id + '_itnrwMapPopupFeatureInfo');
    divFeatureInfo.setAttribute('class', 'itnrwMapPopup');
    document.body.appendChild(divFeatureInfo);

    const divMarker = document.createElement('div');
    divMarker.setAttribute('id', this.domNode.id + '_itnrwMapPopupMarker');
    divMarker.setAttribute('class', 'itnrwMapPopup');
    document.body.appendChild(divMarker);
  }

  /**
   * @desc Fügt ein div-Element zur Darstellung von Benachrichtigungen hinzu
   * @since 2.0.0
   */
  _addElementNotify() {
    const mapDiv = document.getElementById(this.domNode.id);

    const divNotify = document.createElement('div');
    for (const cls of ['itnrwNotify']) {
      divNotify.classList.add(cls);
    }
    divNotify.setAttribute('id', this.domNode.id + '_notify');
    divNotify.setAttribute('style', 'display: none;');
    mapDiv.appendChild(divNotify);
  }

  /**
   * @desc Fügt ein div-Element zur Darstellung von maximierten FeatureInfo-Ausgaben hinzu
   * @since 2.0.0
   */
  _addElementFeatureInfo() {
    const mapDiv = document.getElementById(this.domNode.id);

    const divFeatureInfo = document.createElement('div');
    for (const cls of ['itnrwFeatureInfo']) {
      divFeatureInfo.classList.add(cls);
    }
    divFeatureInfo.setAttribute('id', this.domNode.id + '_featureInfo');
    divFeatureInfo.setAttribute('style', 'display: none;');
    mapDiv.appendChild(divFeatureInfo);
  }

  /**
   * @desc Fügt ein div-Containerelement für die Suche/Koordinateneingabe hinzu
   * @since 2.0.0
   */
  _addPanelbar() {
    const mapDiv = document.getElementById(this.domNode.id);

    /**
     * Panelbar erstellen und dem div-Element übergeben
     */
    const panelbarContainer = document.createElement('div');
    for (const cls of ['mt-2', 'itnrwPanelbar']) {
      panelbarContainer.classList.add(cls);
    }
    panelbarContainer.setAttribute('id', this.domNode.id + '_panelbar');
    mapDiv.appendChild(panelbarContainer);
  }

  /**
   * @desc Fügt ein Overlay zur Karte hinzu
   * @since 2.0.0
   */
  _addOverlays() {
    const label = new Overlay({
      id: 'label',
      element: document.getElementById(this.domNode.id + '_itnrwMapLabel'),
      autoPan: true,
      offset: [0, -10],
      positioning: 'bottom-center',
      stopEvent: false
    });
    const popupCluster = new Overlay({
      id: 'popupCluster',
      element: document.getElementById(this.domNode.id + '_itnrwMapPopupCluster'),
      autoPan: true,
      autoPanAnimation: {
        duration: 100
      }
    });
    const popupFeatureInfo = new Overlay({
      id: 'popupFeatureInfo',
      element: document.getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo'),
      autoPan: true,
      autoPanAnimation: {
        duration: 100
      }
    });
    const popupMarker = new Overlay({
      id: 'popupMarker',
      element: document.getElementById(this.domNode.id + '_itnrwMapPopupMarker'),
      autoPan: true,
      autoPanAnimation: {
        duration: 100
      }
    });

    this.map.addOverlay(label);
    this.map.addOverlay(popupCluster);
    this.map.addOverlay(popupFeatureInfo);
    this.map.addOverlay(popupMarker);
  }

  /**
   * @private
   * @desc Gibt anhand der Konfiguration eine OpenLayers Source 'WMTS' zurück
   * @arg {object} config
   * @since 2.0.0
   * @returns {ol.source.WMTS}
   */
  _returnSourceWMTS(config) {
    return new WMTS({
      attributions: config.attribution,
      url: config.url,
      requestEncoding: config.requestEncoding,
      layer: config.layer,
      matrixSet: config.matrixSet,
      format: config.format,
      tileGrid: new WMTSTileGrid({
        origin: config.tileGrid.origin,
        resolutions: this.resolutions,
        matrixIds: config.tileGrid.matrixIds
      }),
      projection: config.projection,
      style: config.style
    });
  }

  /**
   * @private
   * @desc Gibt anhand der Konfiguration eine OpenLayers Layer 'TileLayer' zurück,
   * @arg {object} config
   * @since 2.0.0
   * @returns {ol.layer.TileLayer}
   */
  _returnTileLayerWMTS(config) {
    return new TileLayer({
      source: config.source,
      visible: config.visible,
      zIndex: config.zIndex,
      name: config.name,
      minZoom: config.minZoom,
      maxZoom: config.maxZoom,
      itnrwName: config.itnrwName,
      defaultBasemap: config.defaultBasemap
    });
  }

  /**
   * @desc Fügt die Hintergrundkarten zur Karte hinzu. Zunächst wird die Konfiguration für die Hintergrunden sowie die WMTS-Zoomstufen (resolution) erstellt und die Lizenzangabe (Jahr) aktualisiert.
   * @since 2.0.0
   */
  _addBasemaps() {
    this._getBasemapConfig();
    this._getMapResolutions();
    this._updateBasemapAttribution();

    /**
     * Überprüfe, ob die vom Nutzer angegebende Hintergrundkarte in den verfügbaren Hintergrundkarten enthalten ist
     * Wenn nicht, setze die Hintergrundkarte neu
     * Achtung: Hat der Nutzer keine Hintergrundkarte definiert 'none', so darf die Hintergrundkarte nicht neu gesetzt werden!
     */
    const basemapsKeys = Object.keys(this.basemaps);
    if (!basemapsKeys.includes(this.config.map.basemap) && this.config.map.basemap !== 'none') {
      this.config.map.basemap = 'topplus_open_col';
    }

    for (const basemap in this.basemaps) {
      if (Object.prototype.hasOwnProperty.call(this.basemaps, this.config.map.basemap)) {
        let visible = false;
        let defaultBasemap = false;
        if (this.config.map.basemap === basemap) {
          visible = true;
          defaultBasemap = true;
        }

        // OpenLayers WMTS Konfiguration erstellen
        let source;
        let layer;

        if (basemap !== 'none') {
          // LayerGroups verarbeiten
          if (this.basemaps[basemap].length) {
            const layers = [];
            for (let i = 0; i < this.basemaps[basemap].length; i++) {
              source = this._returnSourceWMTS(this.basemaps[basemap][i]);
              layer = this._returnTileLayerWMTS({
                source: source,
                visible: visible,
                zIndex: this.basemaps[basemap][i].zIndex,
                name: this.basemaps[basemap][i].name,
                minZoom: this.basemaps[basemap][i].minZoom,
                maxZoom: this.basemaps[basemap][i].maxZoom,
                itnrwName: basemap,
                defaultBasemap: defaultBasemap
              });
              layers.push(layer);
            }

            layer = new LayerGroup({
              layers: layers,
              itnrwName: basemap,
              defaultBasemap: defaultBasemap
            });
          } else {
            source = this._returnSourceWMTS(this.basemaps[basemap]);
            layer = this._returnTileLayerWMTS({
              source: source,
              visible: visible,
              zIndex: this.basemaps[basemap].zIndex,
              name: this.basemaps[basemap].name,
              minZoom: this.basemaps[basemap].minZoom,
              maxZoom: this.basemaps[basemap].maxZoom,
              itnrwName: basemap,
              defaultBasemap: defaultBasemap
            });
          }
        } else {
          source = new WMTS({});
          layer = this._returnTileLayerWMTS({
            source: source,
            visible: visible,
            zIndex: this.basemaps[basemap].zIndex,
            name: this.basemaps[basemap].name,
            minZoom: this.basemaps[basemap].minZoom,
            maxZoom: this.basemaps[basemap].maxZoom,
            itnrwName: basemap,
            defaultBasemap: defaultBasemap
          });
        }

        // Kartenwerk zur Karte hinzufügen
        if (layer) {
          this.map.addLayer(layer);
        }
      }
    }
  }

  /**
   * @desc Definiert die Konfiguration für die Hintergrundkarten
   * @since 2.0.0
   */
  _getBasemapConfig() {
    let WMTS_URL = 'https://www.wmts.nrw.de';

    if (window.itnrwConfig.ENV === undefined) {
      WMTS_URL = 'https://www.wmts.nrw.de';
    } else if (window.itnrwConfig.ENV === 'lvn') {
      WMTS_URL = 'https://lv.wmts.nrw.de';
    } else if (window.itnrwConfig.ENV === 'doi') {
      WMTS_URL = 'https://lv.kommunen.nrw.doi-de.net/wmts';
    } else if (window.itnrwConfig.ENV === 'testa') {
      WMTS_URL = 'https://lv.kommunen.nrw.testa-de.net/wmts';
    }

    this.basemaps = {
      topplus_open_col: [
        {
          url: WMTS_URL + '/topplus_open/tiles/topplus_col/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
          requestEncoding: 'REST',
          layer: 'topplus_col',
          matrixSet: 'EPSG_25832_14',
          format: 'image/png',
          tileGrid: {
            origin: [-46133.17, 6301219.54],
            matrixIds: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14']
          },
          style: 'default',
          attribution:
            '&copy; <a target="_blank" href = "https://www.bkg.bund.de/DE/Home/home.html">BKG<a> {jahr}, <a target="_blank" href ="https://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf">Datenquellen</a>',
          zIndex: 1000,
          name: 'Hintergrundkarte: Topplus Open (Farbe)',
          projection: 'EPSG:25832',
          minZoom: 0,
          maxZoom: 18
        },
        {
          url: WMTS_URL + '/geobasis/wmts_nw_alkis/tiles/nw_alkis/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
          requestEncoding: 'REST',
          layer: 'nw_alkis',
          matrixSet: 'EPSG_25832_16',
          format: 'image/png',
          tileGrid: {
            origin: [-46133.17, 6301219.54],
            matrixIds: [
              '00',
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16'
            ]
          },
          style: 'default',
          attribution: '&copy; Land NRW ({jahr})',
          zIndex: 1001,
          name: 'Hintergrundkarte: ALKIS',
          projection: 'EPSG:25832',
          minZoom: 18,
          maxZoom: 20
        }
      ],
      topplus_open: [
        {
          url: WMTS_URL + '/topplus_open/tiles/topplus_grau/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
          requestEncoding: 'REST',
          layer: 'topplus_grau',
          matrixSet: 'EPSG_25832_14',
          format: 'image/png',
          tileGrid: {
            origin: [-46133.17, 6301219.54],
            matrixIds: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14']
          },
          style: 'default',
          attribution:
            '&copy; <a target="_blank" href = "https://www.bkg.bund.de/DE/Home/home.html">BKG<a> {jahr}, <a target="_blank" href ="https://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf">Datenquellen</a>',
          zIndex: 1000,
          name: 'Hintergrundkarte: Topplus Open (Graustufen)',
          projection: 'EPSG:25832',
          minZoom: 0,
          maxZoom: 18
        },
        {
          url:
            WMTS_URL +
            '/geobasis/wmts_nw_alkis/tiles/nw_alkis_grau/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
          requestEncoding: 'REST',
          layer: 'nw_alkis_grau',
          matrixSet: 'EPSG_25832_16',
          format: 'image/png',
          tileGrid: {
            origin: [-46133.17, 6301219.54],
            matrixIds: [
              '00',
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16'
            ]
          },
          style: 'default',
          attribution: '&copy; Land NRW ({jahr})',
          zIndex: 2000,
          name: 'Hintergrundkarte: ALKIS (Graustufen)',
          projection: 'EPSG:25832',
          minZoom: 18,
          maxZoom: 20
        }
      ],
      dtk: {
        url: WMTS_URL + '/geobasis/wmts_nw_dtk/tiles/nw_dtk_col/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
        requestEncoding: 'REST',
        layer: 'nw_dtk_col',
        matrixSet: 'EPSG_25832_16',
        format: 'image/png',
        tileGrid: {
          origin: [-46133.17, 6301219.54],
          matrixIds: [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16'
          ]
        },
        style: 'default',
        attribution: '&copy; Land NRW ({jahr})',
        zIndex: 1000,
        name: 'Hintergrundkarte: DTK (Farbe)',
        projection: 'EPSG:25832',
        minZoom: 0,
        maxZoom: 20
      },
      dtk_sw: {
        url: WMTS_URL + '/geobasis/wmts_nw_dtk/tiles/nw_dtk_sw/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
        requestEncoding: 'REST',
        layer: 'nw_dtk_sw',
        matrixSet: 'EPSG_25832_16',
        format: 'image/png',
        tileGrid: {
          origin: [-46133.17, 6301219.54],
          matrixIds: [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16'
          ]
        },
        style: 'default',
        attribution: '&copy; Land NRW ({jahr})',
        zIndex: 1000,
        name: 'Hintergrundkarte: DTK (Graustufen)',
        projection: 'EPSG:25832',
        minZoom: 0,
        maxZoom: 20
      },
      dop: {
        url: WMTS_URL + '/dop/tiles/dop/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.jpeg',
        requestEncoding: 'REST',
        layer: 'dop',
        matrixSet: 'EPSG_25832_15',
        format: 'image/png',
        tileGrid: {
          origin: [-46133.17, 6301219.54],
          matrixIds: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']
        },
        style: 'default',
        attribution: '&copy; Land NRW ({jahr}), Copernicus Sentinel-2 Daten (2018)',
        zIndex: 1000,
        name: 'Hintergrundkarte: DOP',
        projection: 'EPSG:25832',
        minZoom: 0,
        maxZoom: 20
      },
      dop_overlay: [
        {
          url: WMTS_URL + '/dop/tiles/dop/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.jpeg',
          requestEncoding: 'REST',
          layer: 'dop',
          matrixSet: 'EPSG_25832_15',
          format: 'image/png',
          tileGrid: {
            origin: [-46133.17, 6301219.54],
            matrixIds: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']
          },
          style: 'default',
          attribution: '&copy; Land NRW ({jahr}), Copernicus Sentinel-2 Daten (2018)',
          zIndex: 1000,
          name: 'Hintergrundkarte: DOP',
          projection: 'EPSG:25832',
          minZoom: 0,
          maxZoom: 20
        },
        {
          url:
            WMTS_URL +
            '/geobasis/wmts_nw_dop_overlay/tiles/nw_dop_overlay/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
          requestEncoding: 'REST',
          layer: 'nw_dop_overlay',
          matrixSet: 'EPSG_25832_16',
          format: 'image/png',
          tileGrid: {
            origin: [-46133.17, 6301219.54],
            matrixIds: [
              '00',
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16'
            ]
          },
          style: 'default',
          attribution: '',
          zIndex: 2000,
          name: 'Hintergrundkarte: DOP Overlay',
          projection: 'EPSG:25832',
          minZoom: 0,
          maxZoom: 20
        }
      ],
      none: {
        zIndex: 1000,
        name: 'Hintergrundkarte: Ohne Karte',
        minZoom: 0,
        maxZoom: 20
      }
    };
  }

  /**
   * @desc Definiert die WMTS-Zoomstufen
   * @since 2.0.0
   */
  _getMapResolutions() {
    this.resolutions = [
      4891.96981025128, // AdV-Level 0 (1:17471320.7508974)
      2445.98490512564, // AdV-Level 1 (1:8735660.37544872)
      1222.99245256282, // AdV-Level 2 (1:4367830.18772436)
      611.49622628141, // AdV-Level 3 (1:2183915.09386218)
      305.748113140705, // AdV-Level 4 (1:1091957.54693109)
      152.874056570353, // AdV-Level 5 (1:545978.773465545)
      76.4370282851763, // AdV-Level 6 (1:272989,386732772)
      38.2185141425881, // AdV-Level 7 (1:136494,693366386)
      19.1092570712941, // AdV-Level 8 (1:68247,3466831931)
      9.55462853564703, // AdV-Level 9 (1:34123,6733415966)
      4.77731426782352, // AdV-Level 10 (1:17061,8366707983)
      2.38865713391176, // AdV-Level 11 (1:8530,91833539914)
      1.19432856695588, // AdV-Level 12 (1:4265,45916769957)
      0.59716428347794, // AdV-Level 13 (1:2132,72958384978)
      0.29858214173897, // AdV-Level 14 (1:1066,36479192489)
      0.14929107086949 //  (1:533,182395962445)
      // 0.07464553543475 //  (1:266,5911979812214)
    ];
  }

  /**
   * @desc Aktualisiert die Lizenzangabe (Jahr)
   * @since 2.0.0
   */
  _updateBasemapAttribution() {
    const year = new Date().getFullYear();

    for (const basemap in this.basemaps) {
      if (basemap !== 'none') {
        // LayerGroups verarbeiten
        if (this.basemaps[basemap].length) {
          for (let i = 0; i < this.basemaps[basemap].length; i++) {
            this.basemaps[basemap][i].attribution = this.basemaps[basemap][i].attribution.replace('{jahr}', year);
          }
        } else {
          this.basemaps[basemap].attribution = this.basemaps[basemap].attribution.replace('{jahr}', year);
        }
      }
    }
  }

  /**
   * @desc Fügt OpenLayers POI-Layer zur Karte hinzu
   * @since 2.0.0
   */
  _addPOILayer() {
    const poiLayer = new POILayer(this);
    this.poiLayer = poiLayer;
  }

  /**
   * @desc Fügt OpenLayers Search-Layer zur Karte hinzu
   * @since 2.0.0
   */
  _addSearchLayer() {
    const searchLayer = new SearchLayer(this);
    this.searchLayer = searchLayer;
  }

  /**
   * @desc Fügt OpenLayers Interaktionen zur Karte hinzu
   * @since 2.0.0
   */
  _addInteractions() {
    this.interactions = {};
    this._addSelectInteraction();
    this._addHoverInteraction();
    this._addGetFeatureInfoInteraction();
  }

  /**
   * @desc Fügt OpenLayers Select-Interaktion für einzelne Marker zur Karte hinzu
   * @since 2.0.0
   */
  _addSelectInteraction() {
    const select = new SelectInteraction(this);
    this.interactions.select = select;
  }

  /**
   * @desc Fügt OpenLayers Hover-Interaktion zur Karte hinzu
   * @since 2.0.0
   */
  _addHoverInteraction() {
    const hover = new HoverInteraction(this);
    this.interactions.hover = hover;
  }

  /**
   * @desc Fügt eine neue Instanz zur Abfrage von Sachdaten zur Karte hinzu
   * @since 2.0.0
   */
  _addGetFeatureInfoInteraction() {
    /**
     * Der GetFeatureInfo-Request darf nur ausgeführt werden, wenn beim Klick
     * kein Feature gefunden bzw. wenn kein Tool-Funktionalität ausgewählt ist!
     */
    this.featureInfo = e => {
      const pixel = this.map.getEventPixel(e.originalEvent);
      const featureFound = this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
        if (layer) {
          /**
           * Wenn Geometrien im Layer Geometry enthalten sind, soll der Klick durch den
           * Layer gehen. Bei allen anderen Layer soll der Klick das Default-Verhalten
           * ausführen, z.B. Klick in ein Cluster soll den Cluster auffächern/auf diesen
           * zoomen.
           */
          if (
            layer.getProperties().name &&
            layer.getProperties().name !== 'Geometry' &&
            layer.getProperties().name !== 'Search_Marker' &&
            layer.getProperties().name !== 'Search_Polygon' &&
            layer.getProperties().name !== 'Tools_Marker' &&
            layer.getProperties().name !== 'Tools_LineString' &&
            layer.getProperties().name !== 'Tools_Polygon'
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

      let toolsActive = 0;
      if (document.getElementById(this.domNode.id + '_toolbar')) {
        toolsActive =
          document.getElementById(this.domNode.id + '_toolbar').querySelectorAll('.itnrwTool.activated').length || 0;
      }

      if (featureFound || toolsActive) {
        return true;
      } else {
        return new GetFeatureInfo({
          event: e,
          extraLayer: this.extraLayer,
          map: this.map,
          frame: true,
          config: this.config,
          domNode: this.domNode
        });
      }
    };

    this.map.on('singleclick', this.featureInfo);
  }

  /**
   * @desc Entfernt eine zuvor hinzugefügte Instanz zur Abfrage von Sachdaten von der Karte
   * @since 2.0.0
   */
  _removeGetFeatureInfoInteraction() {
    this.map.un('singleclick', this.featureInfo);
  }

  /**
   * @desc Fügt OpenLayers Geometry-Layer zur Karte hinzu
   * @since 2.0.0
   */
  _addGeometryLayer() {
    const geometryLayer = new GeometryLayer(this);
    this.geometryLayer = geometryLayer;
  }

  /**
   * @desc Fügt OpenLayers Geometry-Layer zur Karte hinzu
   * @since 2.0.0
   */
  _addGeolocationLayer() {
    const geolocationLayer = new GeolocationLayer(this);
    this.geolocationLayer = geolocationLayer;
  }

  /**
   * @desc Fügt OpenLayers Tools-Layer zur Karte hinzu
   * @since 2.0.0
   */
  _addToolsLayer() {
    const toolsLayer = new ToolsLayer(this);
    this.toolsLayer = toolsLayer;
  }

  /**
   * @desc Fügt ein leeres Array hinzu, um alle extra hinzugeladenen Dienste darin zu vereinen, um diese einfacher löschen zu können
   * @since 2.0.0
   */
  _addExtraLayer() {
    this.extraLayer = [];
  }

  /**
   * @desc Verarbeitet weitere, vom Nutzer konfigurierte Attribute
   * @since 2.0.0
   */
  _processAttributes() {
    /**
     * Geocoding starten bzw. Koordinaten visualisieren
     *
     * Priorität:
     * -> Kartenausschnitt
     * -> Adressen
     * -> Flurstücke
     * -> Koordinaten
     *
     * Der Kartenausschnitt wird innerhalb der Verarbeitung von Adressen, Flurstücken und Koordinaten gesetzt.
     */
    if (this.config.extent && this.config.extent.length === 4) {
      /**
       * Für den Fall, dass der Nutzer keine Adressen, Flurstücke und Koordinaten konfiguriert hat, muss der
       * konfigurierte Kartenausschnitt gesetzt werden.
       */
      // Zoom auf data-itnrw-extent
      setExtent(this.map, this.config.extent);
    } else {
      /**
       * Wenn der Nutzer gar nichts konfiguriert hat, so wird auf die Default-Ausdehnung gezoomt
       */
      setExtent(this.map, this.config.map.initExtent);
    }

    if (this.config.addresses.length > 0) {
      // eslint-disable-next-line no-unused-vars
      const geocode = new Geocoder({
        addresses: this.config.addresses,
        extent: this.config.extent,
        geocode: true,
        map: this.map,
        zoomlevel: this.config.map.zoomlevel,
        cluster: this.config.cluster.visible
      });
    } else if (this.config.flurstuecke.length > 0) {
      // eslint-disable-next-line no-unused-vars
      const geocode = new GeocoderFlur({
        flurstuecke: this.config.flurstuecke,
        extent: this.config.extent,
        map: this.map,
        zoomlevel: this.config.map.zoomlevel,
        cluster: this.config.cluster.visible
      });
    } else if (this.config.coords.length > 0) {
      // eslint-disable-next-line no-unused-vars
      const geocode = new Geocoder({
        coordinates: this.config.coords,
        extent: this.config.extent,
        geocode: false,
        map: this.map,
        zoomlevel: this.config.map.zoomlevel,
        cluster: this.config.cluster.visible
      });
    }

    /**
     * Initiale Geometrien in die Karte zeichnen
     *
     * Geometrietypen:
     * -> Circle
     * -> Extent
     * -> Polygon
     */
    if (this.config.geometries.length > 0) {
      // eslint-disable-next-line no-unused-vars
      const geometry = new Geometry({
        geometries: this.config.geometries,
        map: this.map
      });
    }

    if (this.config.layers.length > 0) {
      [].forEach.call(this.config.layers, service => {
        const type = service.type;

        if (type === 'wms') {
          // eslint-disable-next-line no-unused-vars
          const layer = new WMSLayer({
            wms: service,
            map: this.map,
            extraLayer: this.extraLayer
          });
        } else if (type === 'wmts') {
          // eslint-disable-next-line no-unused-vars
          const layer = new WMTSLayer({
            wmts: service,
            map: this.map,
            extraLayer: this.extraLayer
          });
        } else if (type === 'ags') {
          // eslint-disable-next-line no-unused-vars
          const layer = new AGSLayer({
            ags: service,
            map: this.map,
            extraLayer: this.extraLayer
          });
        }
      });
    }

    /**
     * Module laden und anordnen (links nach rechts)
     *
     * Module:
     * -> search
     * -> coords
     * -> toggler
     * -> tools
     */
    if (
      this.config.modules.search.length > 0 ||
      this.config.modules.coords.length > 0 ||
      this.config.modules.toggler.length > 0
    ) {
      if (this.config.modules.search.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const search = new SearchPanel({
          map: this.map,
          geocodersFilter: this.config.modules.search,
          zoomlevel: this.config.map.zoomlevel,
          callback: this.config.callbacks.search,
          domNode: this.domNode,
          interactions: this.interactions
        });
      }

      if (this.config.modules.coords.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const coordinate = new CoordinatePanel({
          map: this.map,
          crsFilter: this.config.modules.coords,
          zoomlevel: this.config.map.zoomlevel,
          callback: this.config.callbacks.coords,
          domNode: this.domNode,
          interactions: this.interactions
        });
      }

      if (this.config.modules.toggler.length > 0) {
        /**
         * Hat der Nutzer eine Hintergrundkarte unter data-itnrw-map konfiguriert,
         * welche jedoch nicht data-itnrw-module-toggler konfiguriert wurde,
         * so soll diese Karte trotzdem zum Toggler hinzugefügt werden.
         */
        if (this.config.modules.toggler.indexOf(this.config.map.basemap) === -1) {
          this.config.modules.toggler.push(this.config.map.basemap);
        }

        // eslint-disable-next-line no-unused-vars
        const toggler = new Toggler({
          basemapFilter: this.config.modules.toggler,
          map: this.map,
          domNode: this.domNode
        });
      }
    }

    if (this.config.modules.tools.length > 0) {
      // eslint-disable-next-line no-unused-vars
      const tools = new Toolbar({
        map: this.map,
        tools: this.config.modules.tools,
        callback: this.config.callbacks,
        domNode: this.domNode,
        interactions: this.interactions
      });
    }
  }

  /**
   * @desc Getter: Rückgabe des aktuellen Kartenmittelpunktes
   * @memberof InitMap
   * @public
   * @since 2.0.0
   * @returns {number}
   */
  get center() {
    return getCenter(this.map);
  }

  /**
   * @desc Setter: Setzt den Kartenmittelpunkt neu
   * @memberof InitMap
   * @public
   * @arg {array} center
   * @since 2.0.0
   */
  set center(center) {
    setCenter(this.map, center);
  }

  /**
   * @desc Getter: Rückgabe des aktuellen Kartenausschnittes
   * @memberof InitMap
   * @public
   * @since 2.0.0
   * @returns {number}
   */
  get extent() {
    return getExtent(this.map);
  }

  /**
   * @desc Setter: Setzt den Kartenausschnittes neu
   * @memberof InitMap
   * @public
   * @arg {array} extent
   * @since 2.0.0
   */
  set extent(extent) {
    setExtent(this.map, extent);
  }

  /**
   * @desc Getter: Rückgabe des aktuellen Zoomlevels
   * @memberof InitMap
   * @public
   * @since 2.0.0
   * @returns {number}
   */
  get zoom() {
    return getZoom(this.map);
  }

  /**
   * @desc Setter: Setzt das Zoomlevel neu
   * @memberof InitMap
   * @public
   * @arg {number} zoom
   * @since 2.0.0
   */
  set zoom(zoom) {
    setZoom(this.map, zoom);
  }

  /**
   * @desc Getter: Rückgabe des ZIndex jedes Layers
   * @memberof InitMap
   * @public
   * @since 2.0.0
   * @returns {array}
   */
  get zindex() {
    const layers = this.map.getLayers().getArray();

    const array = [];

    let zindex;
    let name;

    for (let i = 0; i < layers.length; i++) {
      zindex = layers[i].getZIndex();
      name = layers[i].getProperties().name;

      array.push({
        name: name,
        zindex: zindex
      });
    }

    return array;
  }
}
export { InitMap };
