// OpenLayers
import { transform, transformExtent } from 'ol/proj';

// map.nrw
import { setExtent } from './Helper';
import { Marker } from './Marker';

/**
 * @classdesc Erzeugt neue Geocoder Instanz zur Darstellung von Marker aus "data-itnrw-flurstuecke"
 * @since 2.0.0
 */
class GeocoderFlur {
  /**
   * @desc Ermittelt die OpenLayers Source vom Type Cluster und ermittelt Koordinaten aus Flurstücken
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.mapCrs = this.map.getView().getProjection().getCode().toUpperCase();

    // Ermittle Cluster-Layer, um später die Objekte hinzuzufügen
    this.map.getLayers().forEach(layer => {
      if ((layer.get('name') !== undefined) & (layer.get('name') === 'POI')) {
        const layers = layer.getLayers().getArray();

        this.poiSource = {
          cluster: null,
          marker: null
        };

        for (let i = 0; i < layers.length; i++) {
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Cluster')) {
            this.poiSource.cluster = layers[i].getSource().getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Marker')) {
            this.poiSource.marker = layers[i].getSource();
          }
        }
      }
    });

    if (this.flurstuecke) {
      this._performQueries();
    }
  }

  /**
   * @desc Führt die Anfragen an den Dienst aus und übergibt die Ergebnisse an [_visualizeResults]{@link GeocoderFlur#_visualizeResults}
   * @since 2.0.0
   */
  async _performQueries() {
    const requestArr = [];
    const responseArr = [];
    for (let i = 0; i < this.flurstuecke.length; i++) {
      const singleRequest = fetch(
        'https://www.gis-rest.nrw.de/flurstuecksuche/2.0/search?limit=1&query=' + this.flurstuecke[i].flurstueck
      )
        .then(response => response.json())
        .then(data => {
          return { input: this.flurstuecke[i], output: data };
        });

      /**
       * Geokodierung abbrechen, wenn zu viele Adressen konfiguriert sind
       */
      if (i === 100) {
        break;
      }

      requestArr.push(singleRequest);
    }

    await Promise.allSettled(requestArr).then(results => {
      for (const result of results) {
        if (result.status === 'fulfilled' && result.value !== undefined) {
          for (let i = 0; i < result.value.output.results.length; i++) {
            responseArr.push({
              input: result.value.input,
              output: result.value.output.results[i]
            });
          }
        }
      }
    });

    this._visualizeResults(responseArr);
  }

  /**
   * @desc Erzeugt Marker für die Flurstücke aus "data-itnrw-flurstuecke" in der Karte
   * @arg {object} result
   * @since 2.0.0
   */
  _visualizeResults(result) {
    if (result.length !== 0) {
      let ext = null;
      for (let i = 0; i < result.length; i++) {
        const coords = transform(result[i].output.punkt, 'EPSG:25832', this.mapCrs);

        // TooltipText
        let tooltip = '';

        // InfoFensterInhalt
        let content = '';

        // Punktbeschriftung
        let label = null;

        // alternativesSymbol
        let image = null;

        // Eigenschaften des Features
        const properties = result[i].output;

        if (Object.prototype.hasOwnProperty.call(properties, 'gemeinde')) {
          content = 'Gemeinde: ' + properties.gemeinde + '<br>';
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'gemarkung')) {
          content += 'Gemarkung: ' + properties.gemarkung + '<br>';
          tooltip = properties.gemarkung;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'gemarkungsnummer')) {
          content += 'Gemarkungsnr: ' + properties.gemarkungsnummer + '<br>';
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'flur')) {
          content += 'Flur: ' + properties.flur.toString().padStart(3, 0) + '<br>';
          tooltip += '-' + properties.flur.toString().padStart(3, 0);
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'zaehler')) {
          content += 'Flurstück: ' + properties.zaehler.toString().padStart(5, 0);
          tooltip += '-' + properties.zaehler.toString().padStart(5, 0);
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'nenner')) {
          if (properties.nenner !== '0' && properties.nenner !== null && properties.nenner !== undefined) {
            content += '/' + properties.nenner.toString().padStart(4, 0);
            tooltip += '/' + properties.nenner.toString().padStart(4, 0);
          } else {
            content += '';
            tooltip += '';
          }
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'kennzeichen')) {
          content += '<br>Kennzeichen: ' + properties.kennzeichen;
        }

        if (result[i].input.content !== null && result[i].input.content.length > 0) {
          content = result[i].input.content;
        }

        if (result[i].input.tooltip !== null && result[i].input.tooltip.length > 0) {
          tooltip = result[i].input.tooltip;
        }

        if (result[i].input.label !== null && result[i].input.label.length > 0) {
          label = result[i].input.label;
        }

        if (result[i].input.image !== null && result[i].input.image.length > 0) {
          image = result[i].input.image;
        }

        // eslint-disable-next-line no-unused-vars
        const marker = new Marker(this, {
          coords: coords,
          content: content,
          tooltip: tooltip,
          label: label,
          image: image,
          layer: 'POI',
          cluster: this.cluster
        });

        if (result.length === 1) {
          if (Object.prototype.hasOwnProperty.call(properties, 'type')) {
            if (properties.type !== 'gema') {
              if (this.extent === null) {
                this.map.getView().setCenter(coords);
                this.map.getView().setZoom(this.zoomlevel);
              } else {
                // Zoom auf data-itnrw-extent
                setExtent(this.map, this.extent);
              }
              return;
            } else {
              if (Object.prototype.hasOwnProperty.call(properties, 'bbox')) {
                ext = transformExtent(properties.bbox, 'EPSG:25832', this.mapCrs);
              }
            }
          }
        } else if (result.length > 1) {
          if (this.cluster) {
            ext = this.poiSource.cluster.getExtent();
          } else {
            ext = this.poiSource.marker.getExtent();
          }
        }
      }

      if (this.extent === null) {
        setExtent(this.map, ext, [4, 4, 4, 4]);
      } else {
        // Zoom auf data-itnrw-extent
        setExtent(this.map, this.extent);
      }
    }
  }
}

export { GeocoderFlur };
