// Bootstrap
import { Dropdown } from 'bootstrap';

// Turf
import turfBboxPolygon from '@turf/bbox-polygon';
import turfCentroid from '@turf/centroid';
import { point as turfPoint } from '@turf/helpers';

// OpenLayers
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { transform, transformExtent } from 'ol/proj';

// map.nrw
import { setExtent } from './../Helper';
import { Marker } from './../Marker';
import { PolygonMarker } from './../PolygonMarker';

/**
 * @classdesc Erzeugt neue Instanz zur Darstellung des Suchpanels
 * @memberOf Module
 * @since 2.0.0
 */
class SearchPanel {
  /**
   * @desc Erstellt und führt das Setup für das Suchpanel aus
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.mapCrs = this.map.getView().getProjection().getCode().toUpperCase();

    // Ermittle Search-Layer, um später die Objekte hinzuzufügen
    this.map.getLayers().forEach(layer => {
      if ((layer.get('name') !== undefined) & (layer.get('name') === 'Search')) {
        const layers = layer.getLayers().getArray();

        this.searchSource = {
          marker: null,
          polygon: null
        };

        for (let i = 0; i < layers.length; i++) {
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Search_Marker')) {
            this.searchSource.marker = layers[i].getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Search_Polygon')) {
            this.searchSource.polygon = layers[i].getSource();
          }
        }
      }
    });

    this._setupSearchPanel();
    this._getSearchEntries();

    if (this.geocoders.length > 0) {
      this._createSearchContainer();
      this._createSearchPanel();
      this._addEventListenerClickSearchEntry();
      this._setSearchEntry(this.geocoders[0].id); // Default-Eintrag setzen
      this.add();
    }
  }

  /**
   * @desc Fügt die Funktionalität für die Suche hinzu
   * @since 2.0.0
   */
  _setupSearchPanel() {
    this.searchPanelConfig = {
      adressen: {
        url: 'https://www.gis-rest.nrw.de/location_finder/lookup?limit=10&filter=type:ort,straße,adress.lan:Nordrhein-Westfalen&query={query}',
        name: 'Adressen',
        placeholder: 'Adresse oder Ort eingeben ..',
        id: 'adressen'
      },
      flurstuecke: {
        url: 'https://www.gis-rest.nrw.de/flurstuecksuche/2.0/search?limit=10&query={query}',
        name: 'Flurstücken',
        placeholder: 'Flurstückskennzeichen oder Gemeinde eingeben ..',
        id: 'flurstuecke'
      },
      verwaltungsgebiete: {
        url: 'https://www.gis-rest.nrw.de/grs/rest/geocoding/search/division.json?data={"type":"Feature","properties":{"search":"{query}"}}',
        name: 'Verwaltungsgebieten',
        placeholder: 'Verwaltungsgebiet eingeben ..',
        id: 'verwaltungsgebiete'
      }
    };
  }

  /**
   * @desc Filter die entsprechenden Sucheinträge heraus
   * @since 2.0.0
   */
  _getSearchEntries() {
    this.geocoders = [];

    if (this.geocodersFilter[0] === 'alle') {
      for (const node in this.searchPanelConfig) {
        this.geocoders.push(this.searchPanelConfig[node]);
      }
    } else {
      for (let i = 0; i < this.geocodersFilter.length; i++) {
        if (Object.prototype.hasOwnProperty.call(this.searchPanelConfig, this.geocodersFilter[i])) {
          this.geocoders.push(this.searchPanelConfig[this.geocodersFilter[i]]);
        }
      }
    }
  }

  /**
   * @desc Erstellt das div-Element (Container) für die Suche
   * @since 2.0.0
   */
  _createSearchContainer() {
    const mapDiv = document.getElementById(this.domNode.id + '_panelbar');
    /**
     * SearchPanel erstellen und dem div-Element übergeben
     */
    const searchContainer = document.createElement('div');
    for (const cls of ['me-2', 'mb-2', 'itnrwSearchPanel', 'search']) {
      searchContainer.classList.add(cls);
    }
    searchContainer.setAttribute('id', this.domNode.id + '_searchPanel');
    mapDiv.appendChild(searchContainer);

    /**
     * ResultList für SearchPanel erstellen und dem div-Element übergeben
     */
    const resultContainer = document.createElement('div');
    for (const cls of ['mt-2', 'itnrwSearchPanelResultList']) {
      resultContainer.classList.add(cls);
    }
    resultContainer.setAttribute('id', this.domNode.id + '_searchPanelResultList');
    mapDiv.appendChild(resultContainer);
  }

  /**
   * @desc Erstellt das Dropdown-Menü sowie die Sucheingabe für die Suche
   * @since 2.0.0
   */
  _createSearchPanel() {
    const searchPanelDiv = document.getElementById(this.domNode.id + '_searchPanel');

    // Sucheinträge für die Liste zusammenfügen
    let list = '';
    for (let i = 0; i < this.geocoders.length; i++) {
      if (i === 0) {
        list += '<li><h6 class="dropdown-header itnrwSearchItem">Suche nach ..</h6></li>';
      }
      list += `<li><a class="dropdown-item itnrwSearchItem" href="#" data-id="${this.geocoders[i].id}">${this.geocoders[i].name}</a></li>`;
    }

    /**
     * border-start-0 und border-end-0 wird benötigt, damit die Buttons und das Input-Feld miteinander von Styling her miteinander verschmelzen.
     * Dies wird jedoch nur benötigt, wenn mehr als 1 Eintrag vorhanden ist
     */
    let htmlPrepend = '';
    if (this.geocoders.length > 1) {
      htmlPrepend = `<button class="btn btn-outline-dark border-end-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" title="Suchdienst ändern"></button>
      <ul class="dropdown-menu" id="${this.domNode.id}_searchPanelList">${list}</ul>
      <input class="form-control searchPanelInput border-start-0 border-end-0" id="${this.domNode.id}_searchPanelInput" type="search" placeholder="" aria-label="Suche">`;
    } else {
      htmlPrepend = `<input class="form-control searchPanelInput border-end-0" id="${this.domNode.id}_searchPanelInput" type="search" placeholder="" aria-label="Suche">`;
    }

    const html = `<div class="input-group input-group-sm">
      ${htmlPrepend}
      <div style="background-color:red; position:absolute; top:31px; left:24px; width:349px;"></div>
      <button id="${this.domNode.id}_searchPanelClear" class="btn btn-outline-dark border-start-0" type="button" title="Suche löschen">×</button>
    </div>`;

    searchPanelDiv.innerHTML = html;
  }

  /**
   * @desc Fügt einen Event Listener vom Typ 'Click' hinzu, um bei Auswahl eines Sucheintrages (Adresse, Flurstück, Verwaltungsgebiete) den Input-Placeholder zu aktualisieren
   * @since 2.0.0
   */
  _addEventListenerClickSearchEntry() {
    if (document.getElementById(this.domNode.id + '_searchPanelList')) {
      document.getElementById(this.domNode.id + '_searchPanelList').addEventListener('click', e => {
        e.preventDefault();
        this.clear();

        const id = e.target.getAttribute('data-id');
        this._setSearchEntry(id);

        document.getElementById(this.domNode.id + '_searchPanelInput').focus();
      });
    }
  }

  /**
   * @desc Setzt den Placeholder und die aktuelle ID für die weitere Verwendung
   * @since 2.0.0
   */
  _setSearchEntry(id) {
    const searchInput = document.getElementById(this.domNode.id + '_searchPanelInput');

    for (let i = 0; i < this.geocoders.length; i++) {
      if (this.geocoders[i].id === id) {
        this.actualSearchId = this.geocoders[i].id;
        this.actualSearchUrl = this.geocoders[i].url;
        searchInput.setAttribute('placeholder', this.geocoders[i].placeholder);
      }
    }
  }

  /**
   * @desc Erzeugt die Namen für die Auflistung der Suchergebnisse für Adressen, Flurstücke und Verwaltungsgebiete
   * @since 2.0.0
   */
  _formatResults(feature) {
    // Ausgabe Suchergebnis
    let value = '';
    let text = '';

    // Für die Darstellung in der Karte
    let content = '';
    let tooltip = '';
    let extent = null;
    let point = null;
    let polygon = null; // Kann auch MultiPolygon sein -> RB Köln
    let polygonType = '';
    let useZoomlevel = false; // Zur Steuerung des Zoomlevels von außen (falls type='Address' -> zoomlevel erzwingen)

    // Für Callback
    const geojson = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: this.mapCrs
        }
      },
      features: [
        {
          bbox: [],
          geometry: {
            type: 'Point',
            coordinates: []
          },
          properties: {},
          type: 'Feature'
        }
      ]
    };

    if (this.actualSearchId === 'adressen') {
      value = feature.name;
      text = feature.name;
      content = feature.name.replace(',', '<br>');
      tooltip = feature.name;
      const type = feature.type;

      if (type === 'Ort') {
        text += ' (Ort)';
      } else if (type === 'Straße') {
        text += ' (Straße)';
      }

      if (
        Object.prototype.hasOwnProperty.call(feature, 'xmin') &&
        Object.prototype.hasOwnProperty.call(feature, 'ymin') &&
        Object.prototype.hasOwnProperty.call(feature, 'xmax') &&
        Object.prototype.hasOwnProperty.call(feature, 'ymax')
      ) {
        feature.xmin = parseFloat(feature.xmin);
        feature.ymin = parseFloat(feature.ymin);
        feature.xmax = parseFloat(feature.xmax);
        feature.ymax = parseFloat(feature.ymax);

        const bbox = transformExtent(
          [feature.xmin, feature.ymin, feature.xmax, feature.ymax],
          'EPSG:25832',
          this.mapCrs
        );
        extent = turfBboxPolygon(bbox);

        geojson.features[0].bbox = bbox;
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'cx') && Object.prototype.hasOwnProperty.call(feature, 'cy')) {
        const coords = transform([feature.cx, feature.cy], 'EPSG:25832', this.mapCrs);
        point = turfPoint(coords);

        geojson.features[0].geometry.coordinates = coords;
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'type')) {
        if (feature.type === 'Address') {
          useZoomlevel = true;
        }
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'fields')) {
        geojson.features[0].properties = feature.fields;
      }

      geojson._info = {};
      geojson._info.service = 'adressen';
    } else if (this.actualSearchId === 'flurstuecke') {
      if (Object.prototype.hasOwnProperty.call(feature, 'type')) {
        if (feature.type === 'gema') {
          /**
           * Gemarkungen enthalten eine Bbox sowie eine Geometrie. Um auf eine Gemarkung
           * zu zoomen, muss zunächst auf Gemarkung und Bbox überprüft werden, da im
           * weiteren Verlauf überprüft wird, ob eine Bbox vorhanden ist. Anschließend
           * wird auf die eigentliche Geometrie überprüft.
           */
          if (Object.prototype.hasOwnProperty.call(feature, 'bbox')) {
            const bbox = transformExtent(feature.bbox, 'EPSG:25832', this.mapCrs);
            extent = turfBboxPolygon(bbox);
            point = turfCentroid(extent);

            geojson.features[0].bbox = bbox;
            geojson.features[0].geometry.coordinates = point.geometry.coordinates;
          }
        }

        if (Object.prototype.hasOwnProperty.call(feature, 'punkt')) {
          const coords = transform(feature.punkt, 'EPSG:25832', this.mapCrs);
          point = turfPoint(coords);

          geojson.features[0].geometry.coordinates = coords;
        }
      }

      const props = {};
      let type = '';

      if (Object.prototype.hasOwnProperty.call(feature, 'gemeinde')) {
        value = feature.gemeinde;
        text = feature.gemeinde;
        content = 'Gemeinde: ' + feature.gemeinde + '<br>';
        // tooltip = feature.gemeinde;
        props.gemeinde = feature.gemeinde;
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'gemarkung')) {
        value += ' ' + feature.gemarkung;
        text += ' ' + feature.gemarkung;
        content += 'Gemarkung: ' + feature.gemarkung + '<br>';
        tooltip += feature.gemarkung;
        props.gemarkungsname = feature.gemarkung;
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'gemarkungsnummer')) {
        value += ' ' + feature.gemarkungsnummer;
        text += ' ' + feature.gemarkungsnummer;
        content += 'Gemarkungsnr: ' + feature.gemarkungsnummer + '<br>';
        // tooltip += feature.gemarkungsnr;
        props.gemarkungsnr = feature.gemarkungsnummer;
        type = ' (Gemarkung)';
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'flur')) {
        value += ' ' + feature.flur;
        text += ' ' + feature.flur;
        content += 'Flur: ' + feature.flur.toString().padStart(3, 0) + '<br>';
        tooltip += '-' + feature.flur.toString().padStart(3, 0);
        props.flur = feature.flur;
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'zaehler')) {
        value += ' ' + feature.zaehler;
        text += ' ' + feature.zaehler;
        content += 'Flurstück: ' + feature.zaehler.toString().padStart(5, 0);
        tooltip += '-' + feature.zaehler.toString().padStart(5, 0);
        props.zaehler = feature.zaehler;
        type = ' (Flurstück)';
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'nenner')) {
        if (feature.nenner !== '0' && feature.nenner !== null && feature.nenner !== undefined) {
          value += ' ' + feature.nenner;
          text += ' ' + feature.nenner;
          content += '/' + feature.nenner.toString().padStart(4, 0);
          tooltip += '/' + feature.nenner.toString().padStart(4, 0);
        } else {
          value += '';
          text += '';
          content += '';
          tooltip += '';
        }
        props.nenner = feature.nenner;
      }
      if (Object.prototype.hasOwnProperty.call(feature, 'kennzeichen')) {
        // value += ', (' + feature.kennzeichen + ')';
        // text += ', (' + feature.kennzeichen + ')';
        content += '<br>Kennzeichen: ' + feature.kennzeichen;
        // tooltip += ' ' + feature.kennzeichen;
        props.kennzeichen = feature.kennzeichen;
      }

      text += type;

      geojson.features[0].properties = props;
      geojson._info = {};
      geojson._info.service = 'flurstuecke';
    } else if (this.actualSearchId === 'verwaltungsgebiete') {
      if (Object.prototype.hasOwnProperty.call(feature, 'bbox')) {
        const bbox = transformExtent(
          [feature.bbox[0], feature.bbox[1], feature.bbox[2], feature.bbox[3]],
          'EPSG:25832',
          this.mapCrs
        );
        extent = turfBboxPolygon(bbox);
        point = turfCentroid(extent);

        geojson.features[0].bbox = bbox;
      }
      if (
        Object.prototype.hasOwnProperty.call(feature, 'geometry') &&
        Object.prototype.hasOwnProperty.call(feature.geometry, 'coordinates')
      ) {
        polygon = feature.geometry.coordinates;
        polygonType = feature.geometry.type;

        if (polygonType === 'Polygon') {
          polygon = new Polygon(feature.geometry.coordinates).transform('EPSG:25832', this.mapCrs).getCoordinates();
        } else if (polygonType === 'MultiPolygon') {
          polygon = new MultiPolygon(feature.geometry.coordinates)
            .transform('EPSG:25832', this.mapCrs)
            .getCoordinates();
        }

        geojson.features[0].geometry.type = polygonType;
        geojson.features[0].geometry.coordinates = polygon;
      }

      if (Object.prototype.hasOwnProperty.call(feature, 'properties')) {
        if (Object.prototype.hasOwnProperty.call(feature.properties, 'gen')) {
          value = feature.properties.gen;
          text = feature.properties.gen;
          content = feature.properties.gen;
          tooltip = feature.properties.gen;
        }
        if (Object.prototype.hasOwnProperty.call(feature.properties, 'des')) {
          // value += ' (' + feature.properties.des + ')';
          text += ' (' + feature.properties.des + ')';
          content += ' (' + feature.properties.des + ')<br>';
          tooltip += ' (' + feature.properties.des + ')';
        }
        if (Object.prototype.hasOwnProperty.call(feature.properties, 'ags')) {
          // value += ' (' + feature.properties.ags + ')';
          // text += ' (' + feature.properties.ags + ')';
          content += 'Amtl. Gemeindeschlüssel: ' + feature.properties.ags;
          // tooltip += ' (' + feature.properties.ags + ')';
        }

        geojson.features[0].properties = feature.properties;
        geojson._info = {};
        geojson._info.service = 'verwaltungsgebiete';
      }
    }

    return {
      value: value,
      text: text,
      content: content,
      tooltip: tooltip,
      extent: extent,
      point: point,
      polygon: polygon,
      polygonType: polygonType,
      useZoomlevel: useZoomlevel,
      geojson: geojson
    };
  }

  /**
   * @desc Stellt die Suchergebnisse in der Karte dar
   * @since 2.0.0
   */
  _visualizeResults(id) {
    document.getElementById(this.domNode.id + '_searchPanelResultList').classList.add('d-none');

    const formattedResult = this._formatResults(this.receivedData[id]);

    // eslint-disable-next-line no-unused-vars
    const marker = new Marker(this, {
      coords: formattedResult.point.geometry.coordinates,
      content: formattedResult.content,
      tooltip: formattedResult.tooltip,
      label: null,
      image: null,
      layer: 'Search'
    });

    /**
     * Zeichne Polygone, wenn diese vorhanden sind
     * Der Inhalt des Layers Search (Marker/Polygon) darf hier nicht geleert werden,
     * da sonst der Marker mit entfernt wird. Der Inhalt der beiden Search Layer
     * (Marker/Polygon) wird bereits beim Aufruf von new Marker geleert.
     */
    if (formattedResult.polygon !== null && formattedResult.polygon.length > 0) {
      if (formattedResult.polygonType === 'Polygon') {
        // eslint-disable-next-line no-unused-vars
        const marker = new PolygonMarker(this, {
          map: this.map,
          pointArray: formattedResult.polygon[0]
        });
      } else if (formattedResult.polygonType === 'MultiPolygon') {
        for (let i = 0; i < formattedResult.polygon.length; i++) {
          // eslint-disable-next-line no-unused-vars
          const marker = new PolygonMarker(this, {
            map: this.map,
            pointArray: formattedResult.polygon[i][0]
          });
        }
      }
    }

    if (formattedResult.useZoomlevel || formattedResult.extent === null) {
      this.map.getView().setCenter(formattedResult.point.geometry.coordinates);
      this.map.getView().setZoom(this.zoomlevel);
    } else {
      setExtent(this.map, formattedResult.extent.bbox);
    }

    // Callback-Funktion aufrufen
    if (typeof window[this.callback] === 'function') {
      window[this.callback](formattedResult.geojson);
    }
  }

  /**
   * @desc Fügt die AutoComplete Funktionalität hinzu
   * @since 2.0.0
   */
  add() {
    document.getElementById(this.domNode.id + '_searchPanelInput').addEventListener('paste', e => {
      setTimeout(() => {
        requestService(e.target.value);
      }, 250);
    });

    document.getElementById(this.domNode.id + '_searchPanelInput').addEventListener('keyup', e => {
      requestService(e.target.value);
    });

    document.getElementById(this.domNode.id + '_searchPanelInput').addEventListener('click', e => {
      if (e.target.value !== '') {
        document.getElementById(this.domNode.id + '_searchPanelResultList').classList.remove('d-none');
      }
    });

    const requestService = query => {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        let tempUrl = this.actualSearchUrl;
        tempUrl = tempUrl.replace('{query}', query);

        const url = new URL(tempUrl);
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error('Es ist ein Fehler aufgetreten.');
        }
        const data = await response.json();

        if (this.actualSearchId === 'adressen') {
          this.receivedData = data.locs;
        } else if (this.actualSearchId === 'flurstuecke') {
          this.receivedData = data.results;
        } else if (this.actualSearchId === 'verwaltungsgebiete') {
          this.receivedData = data.features;
        }

        createResultList();
      }, 750);
    };

    const createResultList = () => {
      let html = '<div class="list-group">';

      if (this.receivedData.length === 0) {
        html += '<a href="#" class="list-group-item list-group-item-action">Kein Ergebnis gefunden</a>';
      } else {
        for (let i = 0; i < this.receivedData.length; i++) {
          const formattedResult = this._formatResults(this.receivedData[i]);
          html += `<a href="#" data-itnrw-result-id="${i}" class="list-group-item list-group-item-action itnrwSearchResultItem">${formattedResult.text}</a>`;
        }
      }
      html += '</div>';

      document.getElementById(this.domNode.id + '_searchPanelResultList').innerHTML = html;
      document.getElementById(this.domNode.id + '_searchPanelResultList').classList.remove('d-none');

      setTimeout(() => {
        document.querySelectorAll('.itnrwSearchResultItem').forEach(item => {
          item.addEventListener('click', e => {
            e.preventDefault();

            const id = parseInt(e.target.getAttribute('data-itnrw-result-id'));
            this._visualizeResults(id);
          });
          item.addEventListener('touchend', e => {
            e.preventDefault();

            const id = parseInt(e.target.getAttribute('data-itnrw-result-id'));
            this._visualizeResults(id);
          });
        });
      }, 100);
    };

    document.getElementById(this.domNode.id + '_searchPanelClear').addEventListener('click', () => {
      this.clear();
    });

    document.getElementById(this.domNode.id + '_searchPanelClear').addEventListener('touchend', () => {
      this.clear();
    });

    /**
     * Fix
     * Wenn mehrere BS-Versionen eingebunden sind, soll auf einem
     * Klick auf den DropDown-Button das Menü ausgeklappt werden.
     */
    this.dropdownBtn = document.getElementById(this.domNode.id + '_searchPanel').getElementsByTagName('button')[0];
    this.dropdownBs = new Dropdown(this.dropdownBtn);

    this.dropdownBtn.addEventListener('click', () => {
      this.dropdownBs.show();
    });
  }

  /**
   * @desc Löscht den Inhalt des Input-Elementes
   * @since 2.0.0
   */
  clear() {
    document.getElementById(this.domNode.id + '_searchPanelInput').value = '';
    document.getElementById(this.domNode.id + '_searchPanelResultList').innerHTML = '';

    // Deselektierte Features, damit das Popover geschlossen wird
    this.interactions.select.marker.getFeatures().clear();
    this.interactions.select.cluster.getFeatures().clear();

    // Sourcen leeren
    if (this.searchSource.marker) {
      this.searchSource.marker.clear();
    }
    if (this.searchSource.polygon) {
      this.searchSource.polygon.clear();
    }
  }
}

export { SearchPanel };
