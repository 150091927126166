// Bootstrap
import { Popover } from 'bootstrap';

/**
 * @classdesc Erzeugt neue Instanz zur Darstellung von GetFeatureInfo-Requests
 * @since 2.0.0
 */
class GetFeatureInfo {
  /**
   * @desc Fügt ein EventListener zur Darstellung von GetFeatureInfo-Requests hinzu
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.mapCrs = this.map.getView().getProjection().getCode().toUpperCase();

    this.urls = this._getUrls();

    if (this.urls.length > 0) {
      if (this.frame) {
        this.data = this.urls;
        this._visualizeResults();
      } else {
        this._getResults();
      }
    }
  }

  /**
   * @desc Ermittelt für jeden Dienst, welcher unter this.extraLayer verfügbar ist, einen GetFeatureInfo-Request
   * @returns {array}
   * @since 2.0.0
   */
  _getUrls() {
    const urls = [];

    [].forEach.call(this.extraLayer, layer => {
      let url;
      let queryLayer = '';

      try {
        queryLayer = layer.getSource().getParams().QUERY_LAYERS;
      } catch (error) {
        queryLayer = [];
      }

      try {
        url = layer
          .getSource()
          .getFeatureInfoUrl(this.event.coordinate, this.map.getView().getResolution(), this.mapCrs, {});
      } catch (error) {
        url = '';
      }

      if (url !== '' && queryLayer.length > 0) {
        urls.push(url);
      }
    });

    return urls;
  }

  /**
   * @desc Führt die GetFeatureInfo-Requests aus und ruft die Methode [_visualizeResults]{@link GetFeatureInfo#_visualizeResults} auf
   * @since 2.0.0
   */
  _getResults() {
    Promise.all(this.urls.map(url => fetch(url).then(responses => responses.text())))
      .then(response => {
        this.data = response;
        this._visualizeResults();
      })
      .catch(error => {
        console.error(error);
      });
  }

  /**
   * @desc Stellt die GetFeatureInfo Ausgabe(n) in einem Bootstrap Popup dar und ruft zum Erstellen des HTML die Methode [_createHTML]{@link GetFeatureInfo#_createHTML} auf
   * @since 2.0.0
   */
  _visualizeResults() {
    const overlayPopup = this.map.getOverlayById('popupFeatureInfo');
    const popover = overlayPopup.getElement();

    const divFeatureInfo = document.getElementById(this.domNode.id + '_featureInfo');
    const mapDiv = document.getElementById(this.domNode.id);

    // Es wird zeitgleich das HTML für das minimierte Popup sowie das maximierte Div-Element erstellt
    const contentMinimize = this._createHTML('minimize');
    const contentMaximize = this._createHTML('maximize');

    /**
     * Minimiertes Popup
     */
    // Position des Popovers neu setzen
    overlayPopup.setPosition(this.event.coordinate);

    if (Popover.getInstance(overlayPopup.getElement())) {
      Popover.getInstance(overlayPopup.getElement()).dispose();
    }

    /**
     * Popover konfigurieren und öffnen
     *
     * sanitize muss den Wert false haben, da ansonsten das
     * codierte HTML nicht darstellt wird.
     *
     * Das gleiche Problem tritt auch bei den Tooltips auf.
     * Siehe: https://stackoverflow.com/questions/57200475/bootstrap-doesnt-display-table-inside-tooltip
     */
    this.popoverBs = new Popover(document.getElementById(popover.id), {
      container: overlayPopup.getElement(),
      placement: 'auto',
      html: true,
      content: contentMinimize,
      sanitize: false,
      trigger: 'manual',
      title: `<div class="btn-group btn-group-sm float-end" role="group" aria-label="Maximieren / Schließen">
        <button type="button" class="btn btn-outline-secondary itnrwMapPopupMaximize" id="${this.domNode.id}_itnrwMapPopupMaximize" aria-label="Maximieren" title="Maximieren"><i class="bi bi-arrows-angle-expand"></i></button>
        <button type="button" class="btn btn-outline-secondary itnrwMapPopupClose" id="${this.domNode.id}_itnrwMapPopupCloseMinimize" aria-label="Schließen" title="Schließen"><i class="bi bi-x-lg"></i></button>
      </div>`
    });

    this.popoverBs.show();

    /**
     * Höhe und Breite des Popups setzen
     *
     * Die Klasse popover besitzt maxWidth: 276px. Daher muss
     * hier der Wert überschrieben werden.
     */
    document
      .getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo')
      .getElementsByClassName('popover')[0].style.maxWidth = this.config.popup.width;
    document
      .getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo')
      .getElementsByClassName('popover')[0]
      .querySelectorAll('.popover-body')[0].style.width = this.config.popup.width;
    document
      .getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo')
      .getElementsByClassName('popover')[0]
      .querySelectorAll('.popover-body')[0].style.height = this.config.popup.height;
    document
      .getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo')
      .getElementsByClassName('popover')[0]
      .querySelectorAll('.popover-body')[0]
      .classList.add('itnrwMapPopupOverflow');

    /**
     * Maximiertes Popup
     */
    const width = mapDiv.offsetWidth + 'px';
    const height = mapDiv.offsetHeight + 'px';
    // TODO: Schließen / Minimize
    const html = `<div class="popover fade bs-popover-auto show" role="tooltip" x-placement="bottom"
                    style="max-width: ${width}; display: block; position:absolute; height:auto; bottom:0; top:0; left:0; right:0; margin: 20px 30px;">
                    <h3 class="popover-header">
                    <div class="btn-group btn-group-sm float-end" role="group" aria-label="Minimieren / Schließen">
                      <button type="button" class="btn btn-outline-secondary itnrwMapPopupMinimize" id="${this.domNode.id}_itnrwMapPopupMinimize" aria-label="Minimieren" title="Minimieren"><i class="bi bi-arrows-angle-contract"></i></button>
                      <button type="button" class="btn btn-outline-secondary itnrwMapPopupClose" id="${this.domNode.id}_itnrwMapPopupCloseMaximize" aria-label="Schließen" title="Schließen"><i class="bi bi-x-lg"></i></button>
                    </div>
                    </h3>
                    <div class="popover-body itnrwMapPopupOverflow" style="max-width: ${width}; height: ${height};">${contentMaximize}</div>
                  </div>`;
    divFeatureInfo.innerHTML = html;

    // Es muss nachträglich die Höhe der iFrames angepasst werden
    const iFramesMinimize = document
      .getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo')
      .getElementsByTagName('iframe');
    const iFramesMaximize = document.getElementById(this.domNode.id + '_featureInfo').getElementsByTagName('iframe');

    for (let i = 0; i < iFramesMinimize.length; i++) {
      iFramesMinimize[i].style.height = parseInt(this.config.popup.height.replace('px', '') - 36) + 'px';
    }
    for (let i = 0; i < iFramesMaximize.length; i++) {
      const tempHeight = document
        .getElementById(this.domNode.id + '_featureInfo')
        .getElementsByClassName('popover')[0]
        .querySelectorAll('.popover-body')[0].style.height;
      iFramesMaximize[i].style.height = parseInt(tempHeight.replace('px', '') - 120) + 'px';
    }

    /**
     * Höhe des Titel anpassen, da durch float-end
     */
    document.getElementsByClassName('itnrwMapPopupMinimize')[0].parentElement.parentElement.style.height = '48px';
    document.getElementsByClassName('itnrwMapPopupMaximize')[0].parentElement.parentElement.style.height = '48px';

    /**
     * Maximized FeatureInfo ausgeben, wenn maximize === true
     */
    if (this.config.popup.maximize) {
      divFeatureInfo.removeAttribute('style');
      setTimeout(() => {
        document.getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo').setAttribute('style', 'display: none;');
      }, 250);
    }

    setTimeout(() => {
      // Minimiertes Popup maximieren
      document.getElementById(this.domNode.id + '_itnrwMapPopupMaximize').addEventListener('click', () => {
        divFeatureInfo.removeAttribute('style');
        document.getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo').setAttribute('style', 'display: none;');
      });

      // Inhalte entfernen, wenn minimiertes Popup geschlossen wird
      document.getElementById(this.domNode.id + '_itnrwMapPopupCloseMinimize').addEventListener('click', () => {
        this.popoverBs.dispose();
        divFeatureInfo.innerHTML = '';
        divFeatureInfo.setAttribute('style', 'display: none;');
      });

      // Maximiertes Popup minimieren
      document.getElementById(this.domNode.id + '_itnrwMapPopupMinimize').addEventListener('click', () => {
        divFeatureInfo.setAttribute('style', 'display: none;');
        document.getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo').removeAttribute('style');
      });

      // Inhalte entfernen, wenn maximiertes Popup geschlossen wird
      document.getElementById(this.domNode.id + '_itnrwMapPopupCloseMaximize').addEventListener('click', () => {
        this.popoverBs.dispose();
        document.getElementById(this.domNode.id + '_itnrwMapPopupFeatureInfo').removeAttribute('style');

        divFeatureInfo.innerHTML = '';
        divFeatureInfo.setAttribute('style', 'display: none;');
      });
    }, 250);
  }

  /**
   * @desc Erstellt HTML für das Modal
   * @arg {string} elem
   * @returns {string}
   * @since 2.0.0
   */
  _createHTML(elem = 'popup') {
    let html = '';

    if (this.frame) {
      if (this.data.length > 1) {
        for (let i = 0; i < this.data.length; i++) {
          if (i === 0) {
            html += `<nav><div class="nav nav-tabs" role="tablist">
              <button class="nav-link active" id="tab-${elem}-${i}" data-bs-toggle="tab" data-bs-target="#content-${elem}-${i}" type="button" role="tab" aria-controls="content-${elem}-${i}" aria-selected="true">${i}</button>`;
          } else {
            html += `<a class="nav-link" id="tab-${elem}-${i}" data-bs-toggle="tab" data-bs-target="#content-${elem}-${i}" type="button" role="tab" aria-controls="content-${elem}-${i}" aria-selected="false">${i}</a>`;
          }

          if (i === this.data.length - 1) {
            html += '</div></nav>';
          }
        }

        for (let i = 0; i < this.data.length; i++) {
          if (i === 0) {
            html += `<div class="tab-content my-2" id="content-gfi">
              <div class="tab-pane fade show active" id="content-${elem}-${i}" role="tabpanel" aria-labelledby="tab-${elem}-${i}">
                <iframe style="width: 100%; border: none;" src="${this.data[i]}" title="Sachdaten"></iframe>
              </div>`;
          } else {
            html += `<div class="tab-pane fade" id="content-${elem}-${i}" role="tabpanel" aria-labelledby="${i}">
              <iframe style="width: 100%; border: none;" src="${this.data[i]}" title="Sachdaten"></iframe>
            </div>`;
          }

          if (i === this.data.length - 1) {
            html += '</div>';
          }
        }
      } else {
        html = `<iframe style="width: 100%; border: none;" src="${this.data[0]}" title="Sachdaten"></iframe>`;
      }
    } else {
      if (this.data.length > 1) {
        for (let i = 0; i < this.data.length; i++) {
          if (i === 0) {
            html += `<nav><div class="nav nav-tabs" role="tablist">
              <button class="nav-link active" id="tab-${elem}-${i}" data-bs-toggle="tab" data-bs-target="#content-${elem}-${i}" type="button" role="tab" aria-controls="content-${elem}-${i}" aria-selected="true">${i}</button>`;
          } else {
            html += `<button class="nav-link" id="tab-${elem}-${i}" data-bs-toggle="tab" data-bs-target="#content-${elem}-${i}" type="button" role="tab" aria-controls="content-${elem}-${i}" aria-selected="false">${i}</button>`;
          }

          if (i === this.data.length - 1) {
            html += '</div></nav>';
          }
        }

        for (let i = 0; i < this.data.length; i++) {
          if (i === 0) {
            html += `<div class="tab-content my-2" id="content-gfi">
              <div class="tab-pane fade show active" id="content-${elem}-${i}" role="tabpanel" aria-labelledby="tab-${elem}-${i}">${this.data[i]}</div>`;
          } else {
            html += `<div class="tab-pane fade" id="content-${elem}-${i}" role="tabpanel" aria-labelledby="${i}">${this.data[i]}</div>`;
          }

          if (i === this.data.length - 1) {
            html += '</div>';
          }
        }
      } else {
        html = this.data[0];
      }
    }

    return html;
  }
}

export { GetFeatureInfo };
