/**
 * @desc Erstellt Zoom Button
 * @since 2.0.0
 */
const createZoom = (domNodeId, zoomInFunc, zoomOutFunc) => {
  const zoomInButton = document.createElement('button');
  for (const cls of ['btn', 'btn-sm', 'btn-outline-dark']) {
    zoomInButton.classList.add(cls);
  }
  zoomInButton.setAttribute('type', 'button');
  zoomInButton.setAttribute('title', 'Vergrößern');
  zoomInButton.innerHTML = '<i class="bi bi-zoom-in"></i>';

  const zoomOutButton = document.createElement('button');
  for (const cls of ['btn', 'btn-sm', 'btn-outline-dark']) {
    zoomOutButton.classList.add(cls);
  }
  zoomOutButton.setAttribute('type', 'button');
  zoomOutButton.setAttribute('title', 'Verkleinern');
  zoomOutButton.innerHTML = '<i class="bi bi-zoom-out"></i>';

  const wrapper = document.getElementById(domNodeId).querySelector('.itnrwCustomControl');
  wrapper.appendChild(zoomInButton);
  wrapper.appendChild(zoomOutButton);

  zoomInButton.addEventListener('click', zoomInFunc);
  zoomOutButton.addEventListener('click', zoomOutFunc);
};

export { createZoom };
