// OpenLayers
import { Feature } from 'ol';
import Point from 'ol/geom/Point';

/**
 * @classdesc Erzeugt neuen Marker und fügt diesen zur Kartenansicht hinzu
 * @since 2.0.0
 */
class Marker {
  /**
   * @desc Ermittelt die OpenLayers Source vom Type Cluster und ermittelt Koordinaten aus Adressen bzw. stellt die Koordinaten in der Karte dar
   * @arg {object} that Übergibt "this"
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(that, params) {
    Object.assign(this, that, params);

    const feature = new Feature({
      geometry: new Point(this.coords)
    });

    feature.setProperties({
      content: this.content,
      tooltip: this.tooltip,
      label: this.label,
      image: this.image
    });

    if (this.layer === 'POI') {
      this.map.getLayers().forEach(layer => {
        if ((layer.get('name') !== undefined) & (layer.get('name') === 'POI')) {
          const layers = layer.getLayers().getArray();

          this.poiSource = {
            cluster: null,
            marker: null
          };

          for (let i = 0; i < layers.length; i++) {
            if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Cluster')) {
              this.poiSource.cluster = layers[i].getSource().getSource();
            }
            if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Marker')) {
              this.poiSource.marker = layers[i].getSource();
            }
          }
        }
      });

      if (this.cluster) {
        this.poiSource.cluster.addFeature(feature);
      } else {
        this.poiSource.marker.addFeature(feature);
      }
    } else if (this.layer === 'Search') {
      this.map.getLayers().forEach(layer => {
        if ((layer.get('name') !== undefined) & (layer.get('name') === 'Search')) {
          const layers = layer.getLayers().getArray();
          for (let i = 0; i < layers.length; i++) {
            if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Search_Marker')) {
              this.searchSource.marker = layers[i].getSource();
            }
            if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Search_Polygon')) {
              this.searchSource.polygon = layers[i].getSource();
            }
          }
        }
      });

      // Deselektierte Features, damit das Popover geschlossen wird
      this.interactions.select.cluster.getFeatures().clear();

      // Sourcen leeren und Feature hinzufügen
      if (this.searchSource.marker) {
        this.searchSource.marker.clear();
      }
      if (this.searchSource.polygon) {
        this.searchSource.polygon.clear();
      }
      this.searchSource.marker.addFeature(feature);
    } else if (this.layer === 'Tools') {
      this.map.getLayers().forEach(layer => {
        if ((layer.get('name') !== undefined) & (layer.get('name') === 'Tools')) {
          const layers = layer.getLayers().getArray();
          for (let i = 0; i < layers.length; i++) {
            if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_Marker')) {
              this.toolsSource.marker = layers[i].getSource();
            }
            if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_LineString')) {
              this.toolsSource.linestring = layers[i].getSource();
            }
            if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_Polygon')) {
              this.toolsSource.polygon = layers[i].getSource();
            }
          }
        }
      });

      // // Deselektierte Features, damit das Popover geschlossen wird
      this.interactions.select.cluster.getFeatures().clear();

      // Sourcen leeren und Feature hinzufügen
      if (this.toolsSource.marker) {
        this.toolsSource.marker.clear();
      }
      if (this.toolsSource.linestring) {
        this.toolsSource.linestring.clear();
      }
      if (this.toolsSource.polygon) {
        this.toolsSource.polygon.clear();
      }
      this.toolsSource.marker.addFeature(feature);
    }
  }
}

export { Marker };
