// OpenLayers
import { Feature } from 'ol';
import Circle from 'ol/geom/Circle';
import GeoJSON from 'ol/format/GeoJSON';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';

// Turf
import bboxPolygon from '@turf/bbox-polygon';

/**
 * @classdesc Erzeugt neue Geometry Instanz zur Darstellung von Geometrien aus "data-itnrw-geometries"
 * @since 2.0.0
 */
class Geometry {
  /**
   * @desc Ermittelt die OpenLayers Source vom Type Vektor und stellt die Geometrien in der Karte dar
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    // Ermittle Geometrie-Layer, um später die Objekte hinzuzufügen
    this.map.getLayers().forEach(layer => {
      if ((layer.get('name') !== undefined) & (layer.get('name') === 'Geometry')) {
        this.geometrySource = layer.getSource();
      }
    });

    this._drawGeometries();
  }

  /**
   * @desc Prüft Geometrien auf den Typ (circle, extent, polygon) und übergibt diese an die entsprechende Methode {@link Geometry#_drawCircle}, {@link Geometry#_drawExtent}, {@link Geometry#_drawPolygon}
   * @since 2.0.0
   */
  _drawGeometries() {
    for (let i = 0; i < this.geometries.length; i++) {
      if (this.geometries[i].type === 'circle') {
        this._drawCircle(this.geometries[i]);
      }

      if (this.geometries[i].type === 'extent') {
        this._drawExtent(this.geometries[i]);
      }

      if (this.geometries[i].type === 'polygon') {
        this._drawPolygon(this.geometries[i]);
      }

      if (this.geometries[i].type === 'linestring') {
        this._drawLineString(this.geometries[i]);
      }
    }
  }

  /**
   * @desc Erzeugt einen Kreis in der Karte
   * @param {object} data
   * @since 2.0.0
   */
  _drawCircle(data) {
    const feature = new Feature({
      geometry: new Circle([data.x, data.y], data.radius)
    });

    this.geometrySource.addFeature(feature);
  }

  /**
   * @desc Erzeugt ein Polygon (aus einem Kartenausschnitt) in der Karte
   * @param {object} data
   * @since 2.0.0
   */
  _drawExtent(data) {
    /**
     * Der Kartenausschnitt enthält zwei Koordinatenpaare, jedoch erwartet OpenLayers ein Polygon (erstes Koordinatenpaar === letztes Koordinatenpaar).
     * Turf (bboxPolygon) wird hier verwendet, um aus einem Kartenausschnitt ein Polygon zu erstellen.
     */
    const feature = new GeoJSON().readFeature(
      bboxPolygon([data.extent[0], data.extent[1], data.extent[2], data.extent[3]])
    );

    this.geometrySource.addFeature(feature);
  }

  /**
   * @desc Erzeugt ein Polygon in der Karte
   * @param {object} data
   * @since 2.0.0
   */
  _drawPolygon(data) {
    const feature = new Feature({
      geometry: new Polygon([data.ring])
    });

    this.geometrySource.addFeature(feature);
  }

  /**
   * @desc Erzeugt ein LineString in der Karte
   * @param {object} data
   * @since 2.0.0
   */
  _drawLineString(data) {
    const feature = new Feature({
      geometry: new LineString(data.linestring)
    });

    this.geometrySource.addFeature(feature);
  }
}

export { Geometry };
