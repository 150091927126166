// OpenLayers
import { transform, transformExtent } from 'ol/proj';

// map.nrw
import { setExtent } from './Helper';
import { Marker } from './Marker';

/**
 * @classdesc Erzeugt neue Geocoder Instanz zur Darstellung von Marker aus "data-itnrw-addresses" und "data-itnrw-coords"
 * @since 2.0.0
 */
class Geocoder {
  /**
   * @desc Ermittelt die OpenLayers Source vom Type Cluster und ermittelt Koordinaten aus Adressen bzw. stellt die Koordinaten in der Karte dar
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.mapCrs = this.map.getView().getProjection().getCode().toUpperCase();

    // Ermittle Cluster-Layer, um später die Objekte hinzuzufügen
    this.map.getLayers().forEach(layer => {
      if ((layer.get('name') !== undefined) & (layer.get('name') === 'POI')) {
        const layers = layer.getLayers().getArray();

        this.poiSource = {
          cluster: null,
          marker: null
        };

        for (let i = 0; i < layers.length; i++) {
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Cluster')) {
            this.poiSource.cluster = layers[i].getSource().getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Marker')) {
            this.poiSource.marker = layers[i].getSource();
          }
        }
      }
    });

    if (this.geocode !== undefined) {
      if (this.geocode) {
        if (this.addresses) {
          this._performQueries();
        }
      } else {
        if (this.coordinates) {
          this._drawCoordinates();
        }
      }
    }
  }

  /**
   * @desc Führt die Anfragen an den Dienst aus und übergibt die Ergebnisse an [_visualizeResults]{@link Geocoder#_visualizeResults}
   * @since 2.0.0
   */
  async _performQueries() {
    const requestArr = [];
    const responseArr = [];
    for (let i = 0; i < this.addresses.length; i++) {
      const singleRequest = fetch(
        'https://www.gis-rest.nrw.de/location_finder/lookup?limit=1&filter=type:ort,straße,adress.lan:Nordrhein-Westfalen&query=' +
          this.addresses[i].address
      )
        .then(response => response.json())
        .then(data => {
          return { input: this.addresses[i], output: data };
        });

      /**
       * Geokodierung abbrechen, wenn zu viele Adressen konfiguriert sind
       */
      if (i === 100) {
        break;
      }

      requestArr.push(singleRequest);
    }

    await Promise.allSettled(requestArr).then(results => {
      for (const result of results) {
        if (result.status === 'fulfilled' && result.value !== undefined) {
          for (let i = 0; i < result.value.output.locs.length; i++) {
            responseArr.push({
              input: result.value.input,
              output: result.value.output.locs[i]
            });
          }
        }
      }
    });

    this._visualizeResults(responseArr);
  }

  /**
   * @desc Erzeugt Marker für die Adressen aus "data-itnrw-addresses" in der Karte
   * @arg {object} result
   * @since 2.0.0
   */
  _visualizeResults(result) {
    if (result.length !== 0) {
      let ext = null;

      for (let i = 0; i < result.length; i++) {
        const coords = transform([result[i].output.cx, result[i].output.cy], 'EPSG:25832', this.mapCrs);

        // TooltipText
        let tooltip = null;

        // InfoFensterInhalt
        let content = null;

        // Punktbeschriftung
        let label = null;

        // alternativesSymbol
        let image = null;

        content = tooltip = result[i].output.name + '<br>';
        content = content.replace(',', '<br>');

        if (result[i].input.content !== null && result[i].input.content.length > 0) {
          content = result[i].input.content;
        }

        if (result[i].input.tooltip !== null && result[i].input.tooltip.length > 0) {
          tooltip = result[i].input.tooltip;
        }

        if (result[i].input.label !== null && result[i].input.label.length > 0) {
          label = result[i].input.label;
        }

        if (result[i].input.image !== null && result[i].input.image.length > 0) {
          image = result[i].input.image;
        }

        // eslint-disable-next-line no-unused-vars
        const marker = new Marker(this, {
          coords: coords,
          content: content,
          tooltip: tooltip,
          label: label,
          image: image,
          layer: 'POI',
          cluster: this.cluster
        });

        if (result.length === 1) {
          ext = transformExtent(
            [result[i].output.xmin, result[i].output.ymin, result[i].output.xmax, result[i].output.ymax],
            'EPSG:25832',
            this.mapCrs
          );
        } else if (result.length > 1) {
          if (this.cluster) {
            ext = this.poiSource.cluster.getExtent();
          } else {
            ext = this.poiSource.marker.getExtent();
          }
        }
      }

      if (this.extent === null) {
        setExtent(this.map, ext);
      } else {
        // Zoom auf data-itnrw-extent
        setExtent(this.map, this.extent);
      }
    }
  }

  /**
   * @desc Erzeugt Marker für die Koordinaten aus "data-itnrw-coords" in der Karte
   * @since 2.0.0
   */
  _drawCoordinates() {
    let ext = null;
    /**
     * this.coordinates kann wie folgt aufgebaut sein:
     * [epsg:25832; Koordinate; TooltipText; InfoFensterInhalt; Punktbeschriftung; alternativesSymbol]
     */
    for (let i = 0; i < this.coordinates.length; i++) {
      if (this.coordinates[i].crs) {
        const coords = transform([this.coordinates[i].x, this.coordinates[i].y], this.coordinates[i].crs, this.mapCrs);

        if (coords[0] !== Infinity || coords[1] !== Infinity) {
          // TooltipText
          let tooltip = null;

          // InfoFensterInhalt
          let content = null;

          // Punktbeschriftung
          let label = null;

          // alternativesSymbol
          let image = null;

          /**
           * content und tooltip sollen zunächst die Koordinaten enthalten,
           * bevor die Werte ggf. überschrieben werden.
           */
          content = tooltip = 'x: ' + this.coordinates[i].x + '<br>y: ' + this.coordinates[i].y + '<br>';

          if (this.coordinates[i].tooltip !== null && this.coordinates[i].tooltip.length > 0) {
            tooltip = this.coordinates[i].tooltip;
          }

          if (this.coordinates[i].content !== null && this.coordinates[i].content.length > 0) {
            content = this.coordinates[i].content;
          }

          if (this.coordinates[i].label !== null && this.coordinates[i].label.length > 0) {
            label = this.coordinates[i].label;
          }

          if (this.coordinates[i].image !== null && this.coordinates[i].image.length > 0) {
            image = this.coordinates[i].image;
          }

          // eslint-disable-next-line no-unused-vars
          const marker = new Marker(this, {
            coords: coords,
            content: content,
            tooltip: tooltip,
            label: label,
            image: image,
            layer: 'POI',
            cluster: this.cluster
          });

          /**
           * Übeprüfung, ob ein Koordinatenpaar oder mehrere Koordinatenpaare konfiguriert sind.
           * Wenn ein Extent angegeben worden ist, so soll immer auf diesen Extent gezoomt werden!
           * Anderfalls wird auf den einzelnen Punkt bzw. auf den Extent des Vektor-Layers gezoomt.
           */
          if (this.coordinates.length === 1) {
            if (this.extent === null) {
              this.map.getView().setCenter(coords);
              this.map.getView().setZoom(this.zoomlevel);

              return;
            } else {
              // Zoom auf data-itnrw-extent
              setExtent(this.map, this.extent);
            }
          } else if (this.coordinates.length > 1) {
            if (this.cluster) {
              ext = this.poiSource.cluster.getExtent();
            } else {
              ext = this.poiSource.marker.getExtent();
            }
          }
        } else {
          console.warn(
            'Die Koordinatentransformation war fehlerhaft. Bitte überprüfen Sie die konfigurierten Koordinaten:',
            [this.coordinates[i].x, this.coordinates[i].y]
          );
        }
      }
    }

    if (this.extent === null) {
      setExtent(this.map, ext);
    } else {
      // Zoom auf data-itnrw-extent
      setExtent(this.map, this.extent);
    }
  }
}

export { Geocoder };
