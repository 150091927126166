/**
 * @func getZoom
 * @desc Ermittelt das aktuelle Zoomlevel
 * @arg {object} map
 * @memberOf module:Helper
 * @since 2.0.0
 * @returns {number} Zoomlevel
 */
const getZoom = map => {
  return map.getView().getZoom();
};

/**
 * @func setZoom
 * @desc Zoom auf einen übergebenen Zoomlevel
 * @arg {object} map
 * @arg {number} zoom
 * @memberOf module:Helper
 * @since 2.0.0
 */
const setZoom = (map, zoom) => {
  if (typeof zoom === 'number' && zoom >= map.getView().getMinZoom() && zoom <= map.getView().getMaxZoom()) {
    map.getView().setZoom(zoom);
  }
};

export { getZoom, setZoom };
