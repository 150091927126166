// OpenLayers
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import { transform } from 'ol/proj';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

// map.nrw
import { Marker } from './../Marker';

/**
 * @classdesc Erzeugt neue NearestAddressTool Instanz
 * @memberOf Module
 * @since 2.0.0
 */
class NearestAddressTool {
  /**
   * @desc Erstellt und führt das Setup für das div-Element aus
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.mapCrs = this.map.getView().getProjection().getCode().toUpperCase();

    // Ermittle Tool-Layer, um später die Objekte hinzuzufügen
    this.map.getLayers().forEach(layer => {
      if ((layer.get('name') !== undefined) & (layer.get('name') === 'Tools')) {
        const layers = layer.getLayers().getArray();

        this.toolsSource = {
          marker: null,
          linestring: null,
          polygon: null
        };

        for (let i = 0; i < layers.length; i++) {
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_Marker')) {
            this.toolsSource.marker = layers[i].getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_LineString')) {
            this.toolsSource.linestring = layers[i].getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_Polygon')) {
            this.toolsSource.polygon = layers[i].getSource();
          }
        }
      }
    });

    this.toolDiv = document.getElementById(this.toolId);
    for (const cls of ['itnrwTool', 'ms-1', 'itnrwTool_nearestAddressTool']) {
      this.toolDiv.classList.add(cls);
    }
    this.toolDiv.setAttribute('title', 'Nächstgelegene Adresse finden');

    this.toolDiv.addEventListener(
      'mouseover',
      e => {
        this.toolDiv.setAttribute('style', 'background-color: #e1e1e1; cursor: pointer');
      },
      false
    );

    this.toolDiv.addEventListener(
      'mouseout',
      e => {
        this.toolDiv.setAttribute('style', 'background-color: #ffffff; cursor: default');
      },
      false
    );

    this.tool = e => {
      const features = [];
      this.map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
        if (feature.getGeometry().getType() === 'Point') {
          features.push(feature);
        }
      });

      /**
       * Der Marker darf nur zur Karte hinzugefügt werden, wenn beim Klick kein Feature gefunden wurde.
       * Konnte ein Feature ermittelt werden, so soll die Select-Funktionalität ausgeführt werden.
       */
      if (features.length === 0) {
        this.inputCoords = this.map.getCoordinateFromPixel(e.pixel);

        clearTimeout(this.timeout);

        this.timeout = setTimeout(async () => {
          let tempUrl = 'https://www.gis-rest.nrw.de/grs/rest/geocoding/location/reverse_open.json';
          if (window.itnrwConfig.ENV === 'testa') {
            tempUrl = 'https://lv.kommunen.nrw.testa-de.net/grs/rest/geocoding/location/reverse_open.json';
          }

          const url = new URL(
            `${tempUrl}?data={"type":"Feature","geometry":{"type":"Point","coordinates":[${this.inputCoords}]},"crs":{"type":"name","properties":{"name":"${this.mapCrs}"}}}`
          );
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error('Es ist ein Fehler aufgetreten.');
          }
          const data = await response.json();

          if (data.features[0]) {
            this._receivedResults(data);
          }
        }, 100);
      }
    };
  }

  _receivedResults(geojson) {
    this.outputCoords = transform(geojson.features[0].geometry.coordinates, 'EPSG:25832', this.mapCrs);

    this.toolsSource.polygon.clear();

    const olPointFeature = new Feature({
      geometry: new Point(this.inputCoords)
    });
    olPointFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 2,
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.5)'
          }),
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, 0.5)',
            width: 1
          })
        })
      })
    );

    const olLineStringFeature = new Feature({
      geometry: new LineString([this.inputCoords, this.outputCoords])
    });
    olLineStringFeature.setStyle(
      new Style({
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 0.5)',
          width: 1
        })
      })
    );

    this.distance = (Math.round(olLineStringFeature.getGeometry().getLength() * 100) / 100)
      .toFixed(1)
      .replace('.', ',');

    const content = geojson.features[0].properties.name.replace(',', '<br>') + `<br> Entfernung: ${this.distance}m`;
    const tooltip = geojson.features[0].properties.name.replace(',', '<br>') + `<br> Entfernung: ${this.distance}m`;

    // eslint-disable-next-line no-unused-vars
    const marker = new Marker(this, {
      coords: this.outputCoords,
      content: content,
      tooltip: tooltip,
      label: null,
      image: null,
      layer: 'Tools'
    });

    this.toolsSource.polygon.addFeatures([olPointFeature, olLineStringFeature]);

    const mapFeature = {
      geometry: {
        coordinates: this.inputCoords,
        type: 'Point'
      },
      properties: {
        description: 'MapPoint'
      },
      type: 'Feature'
    };

    // zusätzliches Attribut zu ermittelter Adresse
    geojson.features[0].properties.description = 'Address';

    // MapPoint zusätzlich dem GeoJSON-Callback hinzufügen (IGNRW-Anforderung)
    geojson.features.push(mapFeature);

    // Callback-Funktion aufrufen
    if (typeof window[this.callback] === 'function') {
      window[this.callback](geojson);
    }
  }

  /**
   * @desc Aktiviert die Funktionalität
   * @since 2.0.0
   */
  activate() {
    if (this.toolDiv.classList.contains('activated')) {
      this.deactivate();
    } else {
      this.toolDiv.classList.add('activated');

      this.map.on('click', this.tool);
    }
  }

  /**
   * @desc Deaktiviert die Funktionalität
   * @since 2.0.0
   */
  deactivate() {
    this.toolDiv.classList.remove('activated');
    this.map.un('click', this.tool);

    // Deselektierte Features, damit das Popover geschlossen wird
    this.interactions.select.marker.getFeatures().clear();
    this.interactions.select.cluster.getFeatures().clear();

    // Sourcen leeren
    if (this.toolsSource.marker) {
      this.toolsSource.marker.clear();
    }
    if (this.toolsSource.linestring) {
      this.toolsSource.linestring.clear();
    }
    if (this.toolsSource.polygon) {
      this.toolsSource.polygon.clear();
    }
  }
}

export { NearestAddressTool };
