/**
 * @desc Erstellt Fullscreen Button
 * @since 2.0.0
 */
const createFullscreen = (domNodeId, that) => {
  const iconEnlarge = '<i class="bi bi-arrows-fullscreen"></i>';
  const iconShrink = '<i class="bi bi-fullscreen-exit"></i>';

  const toggleFullscreen = button => {
    if (button.innerHTML === iconEnlarge) {
      enlarge(button);
    } else {
      shrink(button);
    }
  };

  const enlarge = button => {
    document.getElementById(domNodeId).requestFullscreen();
    button.innerHTML = iconShrink;
  };

  const shrink = button => {
    document.exitFullscreen();
    button.innerHTML = iconEnlarge;
  };

  const button = document.createElement('button');
  for (const cls of ['btn', 'btn-sm', 'btn-outline-dark']) {
    button.classList.add(cls);
  }
  button.setAttribute('type', 'button');
  button.setAttribute('title', 'Vollbildansicht umschalten');

  const wrapper = document.getElementById(domNodeId).querySelector('.itnrwCustomControl');
  wrapper.appendChild(button);

  button.innerHTML = iconEnlarge;
  button.addEventListener('click', toggleFullscreen.bind(that, button));

  document.getElementById(domNodeId).addEventListener('fullscreenchange', () => {
    if (!document.fullscreenElement) {
      button.innerHTML = iconEnlarge;
    }
  });
};

export { createFullscreen };
