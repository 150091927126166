// Bootstrap
import { Popover } from 'bootstrap';

// OpenLayers
import { click } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import { Stroke, Style } from 'ol/style';
import SelectCluster from 'ol-ext/interaction/SelectCluster';

// map.nrw
import { setExtent } from './../Helper';
import { identifyPOIStyle, POIMarkerStyle } from './../Styles';

/**
 * @classdesc Erzeuge eine neue ClusterSelect-Interaktion und füge zur Karte hinzu
 * @memberOf Interaction
 * @since 2.0.0
 */
class SelectInteraction {
  /**
   * @desc Erzeugt eine neue OpenLayers Interaktion vom Type Select
   * @arg {object} that Übergibt "this"
   * @since 2.0.0
   */
  constructor(that) {
    Object.assign(this, that);

    this.feature = {
      marker: null,
      cluster: null
    };

    this.select = {
      marker: null,
      cluster: null
    };

    this.add();

    return this.select;
  }

  /**
   * @desc Fügt Select Interaktionen hinzu
   * @since 2.0.0
   */
  add() {
    this.overlayPopupCluster = this.map.getOverlayById('popupCluster');
    this.popoverCluster = this.overlayPopupCluster.getElement();

    this.overlayPopupMarker = this.map.getOverlayById('popupMarker');
    this.popoverMarker = this.overlayPopupMarker.getElement();

    this.select.cluster = new SelectCluster({
      layers: [this.poiLayer.cluster],
      pointRadius: window.itnrwConfig.MARKER_SIZE * 1.2,
      featureStyle: feature => {
        /**
         * Verbindungslinie zwischen den Punkten
         */
        const style = {
          stroke: new Stroke({
            color: '#A9A9A9',
            width: 1
          })
        };

        return POIMarkerStyle(feature, style);
      },
      style: feature => {
        return identifyPOIStyle(feature);
      }
    });

    this.select.marker = new Select({
      layers: [this.poiLayer.marker, this.searchLayer.marker, this.toolsLayer.marker],
      condition: click,
      /**
       * Set to null if this interaction should not apply any style changes for
       * selected features. If set to a falsey value, the selected feature's
       * style will not change.
       */
      style: false
    });

    this.map.addInteraction(this.select.cluster);
    this.map.addInteraction(this.select.marker);

    this.select.cluster.getFeatures().on('remove', () => {
      this.close('Cluster');
    });

    this.select.marker.getFeatures().on('remove', () => {
      this.close('Marker');
    });

    this.select.cluster.getFeatures().on('add', e => {
      const features = e.element.get('features');

      if (features !== undefined && features.length === 1) {
        this.feature.cluster = features[0];

        if (this.feature.cluster) {
          const coords = e.element.values_.geometry.flatCoordinates;
          const content = this.feature.cluster.get('content');

          this.open('Cluster', {
            coords: coords,
            content: content
          });
        } else {
          this.close('Cluster');
        }
      }
    });

    this.select.marker.getFeatures().on('add', e => {
      this.feature.marker = e.element;

      if (this.feature.marker) {
        const coords = this.feature.marker.getGeometry().getCoordinates();
        const content = this.feature.marker.get('content');

        this.open('Marker', {
          coords: coords,
          content: content
        });
      } else {
        this.close('Marker');
      }
    });

    this.select.cluster.on('select', e => {
      const features = e.selected;

      if (Array.isArray(features) && features.length > 0) {
        const feature = features[0];

        if (feature.get('features').length > 1) {
          let extent = this.select.cluster.getClusterExtent(feature);
          if (!extent) {
            extent = feature.getGeometry().getExtent();

            setTimeout(() => {
              this.poiLayer.cluster.getSource().forEachFeatureInExtent(extent, selectedClusterFeature => {
                selectedClusterFeature.setStyle(new Style({}));
              });

              // Trigger cluster selection after zoom to extent (timeout = 100ms)
              this.select.cluster.selectCluster({
                selected: [feature]
              });
            }, 100);
          }

          setExtent(this.map, extent, [
            4 * window.itnrwConfig.MARKER_SIZE,
            2 * window.itnrwConfig.MARKER_SIZE,
            2 * window.itnrwConfig.MARKER_SIZE,
            2 * window.itnrwConfig.MARKER_SIZE
          ]);
        }
      } else {
        // Cluster-Darstellung muss mit einem Refresh erzwungen werden
        this.poiLayer.cluster.getSource().refresh();
      }
    });
  }

  /**
   * @desc Öffnet das Popup für Cluster und Marker
   * @since 2.0.0
   */
  open(type, data) {
    let overlayPopup;
    let popover;

    if (type === 'Cluster') {
      overlayPopup = this.overlayPopupCluster;
      popover = this.popoverCluster;
    } else if (type === 'Marker') {
      overlayPopup = this.overlayPopupMarker;
      popover = this.popoverMarker;
    }

    // Position des Popovers neu setzen
    overlayPopup.setPosition(data.coords);

    /**
     * Popover konfigurieren und öffnen
     *
     * sanitize muss den Wert false haben, da ansonsten das
     * codierte HTML nicht darstellt wird.
     *
     * Das gleiche Problem tritt auch bei den Tooltips auf.
     * Siehe: https://stackoverflow.com/questions/57200475/bootstrap-doesnt-display-table-inside-tooltip
     */
    this.popoverBs = new Popover(document.getElementById(popover.id), {
      container: overlayPopup.getElement(),
      placement: 'auto',
      html: true,
      content: data.content,
      sanitize: false,
      title: `<div class="d-flex justify-content-end"><button type="button" class="btn-sm btn-close itnrwMapPopupClose" aria-label="Schließen" id="${this.domNode.id}_itnrwMapPopupClose"></button></div>`
    });

    this.popoverBs.show();

    /**
     * Höhe und Breite des Popups setzen
     *
     * Die Klasse popover besitzt maxWidth: 276px. Daher muss
     * hier der Wert überschrieben werden.
     */
    document
      .getElementById(this.domNode.id + '_itnrwMapPopup' + type)
      .getElementsByClassName('popover')[0].style.maxWidth = this.config.popup.width;
    document
      .getElementById(this.domNode.id + '_itnrwMapPopup' + type)
      .getElementsByClassName('popover')[0]
      .querySelectorAll('.popover-body')[0].style.width = this.config.popup.width;
    document
      .getElementById(this.domNode.id + '_itnrwMapPopup' + type)
      .getElementsByClassName('popover')[0]
      .querySelectorAll('.popover-body')[0].style.height = this.config.popup.height;
    document
      .getElementById(this.domNode.id + '_itnrwMapPopup' + type)
      .getElementsByClassName('popover')[0]
      .querySelectorAll('.popover-body')[0]
      .classList.add('itnrwMapPopupOverflow');

    const closeByEsc = () => {
      // Wenn Popover Element vorhanden
      if (this.popoverBs._element) {
        this.popoverBs.dispose();
        this.select.cluster.getFeatures().clear();
        this.select.marker.getFeatures().clear();
      }
    };

    // EventListener Klick zum Schließen des Popovers
    setTimeout(() => {
      document.getElementById(this.domNode.id + '_itnrwMapPopupClose').addEventListener('click', e => {
        closeByEsc();
      });

      /**
       * INFO: Mit der Escape-Taste werden alle Popovers in alles Maps geschlossen,
       * auch wenn eigentlich nur ein Popover in einer Map geschlossen werden soll.
       * Daher auskommentiert. EventListener auf ein DIV war nicht erfolgreich.
       */
      // // EventListener Keyup zum Schließen des Popovers
      // document.addEventListener('keyup', function handler(event) {
      //   if (event.key === 'Escape') {
      //     closeByEsc();
      //     event.currentTarget.removeEventListener(event.type, handler);
      //   }
      // });
    }, 100);
  }

  /**
   * @desc Schließt das Popup für Cluster und Marker
   * @since 2.0.0
   */
  close(type) {
    if (type === 'Cluster') {
      this.feature.cluster = null;
      if (Popover.getInstance(this.popoverCluster)) {
        Popover.getInstance(this.popoverCluster).dispose();
      }
    }

    if (type === 'Marker') {
      this.feature.marker = null;
      if (Popover.getInstance(this.popoverMarker)) {
        Popover.getInstance(this.popoverMarker).dispose();
      }
    }
  }
}

export { SelectInteraction };
