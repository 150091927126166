/**
 * @classdesc Erzeugt neue Toggler Instanz
 * @memberOf Module
 * @since 2.0.0
 */
class Toggler {
  /**
   * @desc Erstellt und führt das Setup für das div-Element aus
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this._createToggler();
    this._setupToggler();

    this._addClickListener();
  }

  /**
   * @desc Entfernt das div-Element aus dem DOM
   * @since 2.0.0
   */
  destroy() {
    document.getElementById(this.domNode.id + '_basemapToggler').remove();
  }

  /**
   * @desc Fügt ein Click Event Listener zum Togglen hinzu
   * @since 2.0.0
   */
  _addClickListener() {
    document.getElementById(this.domNode.id + '_basemapToggler').addEventListener('click', () => {
      this.toggle();
    });
  }

  /**
   * @desc Erstellt das div-Element für den Toggler
   * @since 2.0.0
   */
  _createToggler() {
    const mapDiv = document.getElementById(this.domNode.id);

    /**
     * Toggler erstellen und dem div-Element übergeben
     */
    const togglerContainer = document.createElement('div');
    for (const cls of ['m-2', 'itnrwBasemapToggler']) {
      togglerContainer.classList.add(cls);
    }
    togglerContainer.setAttribute('id', this.domNode.id + '_basemapToggler');
    togglerContainer.setAttribute('title', 'Basiskarte umschalten');
    mapDiv.appendChild(togglerContainer);
  }

  /**
   * @desc Fügt die Funktionalität für Toggler hinzu, um die Hintergrundkarten zu wechseln
   * @since 2.0.0
   */
  _setupToggler() {
    this._setBasemaps();
    this._setBasemapImage();
  }

  /**
   * @desc Setzt die neue und die nächste Hintergrundkarte durch Aufruf der Methoden [_setActiveBasemap]{@link Toggle#_setActiveBasemap} und [_setNextBasemap]{@link Toggle#_setNextBasemap}
   * @since 2.0.0
   */
  _setBasemaps() {
    this.basemaps = [];

    const layers = this.map.getLayers().getArray();
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].getProperties().itnrwName) {
        this.basemaps.push(layers[i]);
      }
    }

    if (this.basemapFilter[0] !== 'alle') {
      const tempArr = [];

      for (let i = 0; i < this.basemaps.length; i++) {
        if (this.basemapFilter.indexOf(this.basemaps[i].getProperties().itnrwName) > -1) {
          tempArr.push(this.basemaps[i]);
        }
      }

      this.basemaps = tempArr;
    }

    if (this.basemaps.length < 2) {
      this.destroy();
    } else {
      this._setActiveBasemap();
      this._setNextBasemap();
    }
  }

  /**
   * @desc Setzt die Grafik für die nächste Hintergrundkarte
   * @since 2.0.0
   */
  _setBasemapImage() {
    this.position = null;

    switch (this.nextBasemap) {
      case 'webatlas':
        this.position = '0px 0px';
        break;
      case 'webatlas_grau':
        this.position = '-50px 0px';
        break;
      case 'dtk':
        this.position = '-100px 0px';
        break;
      case 'dtk_sw':
        this.position = '-151px 0px';
        break;
      case 'dop':
        this.position = '-201px 0px';
        break;
      case 'dop_overlay':
        this.position = '-250px 0px';
        break;
      case 'none':
        this.position = '-301px 0px';
        break;
      case 'topplus_open':
        this.position = '-400px 0px';
        break;
      case 'topplus_open_col':
        this.position = '-351px 0px';
        break;
      default:
        this.position = null;
        break;
    }

    if (this.domNode) {
      const mapDiv = document.getElementById(this.domNode.id + '_basemapToggler');
      mapDiv.setAttribute('style', 'background-position:' + this.position + ';');
    }
  }

  /**
   * @desc Setzt die aktive Hintergrundkarte
   * @since 2.0.0
   */
  _setActiveBasemap() {
    const togglerContainer = document.getElementById(this.domNode.id + '_basemapToggler');

    for (let i = 0; i < this.basemaps.length; i++) {
      if (this.basemaps[i].getProperties().defaultBasemap) {
        this.activeBasemap = this.basemaps[i].getProperties().itnrwName;
        togglerContainer.setAttribute('data-active-basemap', this.activeBasemap);
      }
    }
  }

  /**
   * @desc Setzt die nächste Hintergrundkarte
   * @since 2.0.0
   */
  _setNextBasemap() {
    const togglerContainer = document.getElementById(this.domNode.id + '_basemapToggler');

    for (let i = 0; i < this.basemaps.length; i++) {
      if (this.basemaps[i].getProperties().itnrwName === this.activeBasemap) {
        if (i + 1 < this.basemaps.length) {
          this.nextBasemap = this.basemaps[i + 1].getProperties().itnrwName;
          togglerContainer.setAttribute('data-next-basemap', this.nextBasemap);
          break;
        } else {
          this.nextBasemap = this.basemaps[0].getProperties().itnrwName;
          togglerContainer.setAttribute('data-next-basemap', this.nextBasemap);
          break;
        }
      }
    }
  }

  /**
   * @desc Blendet die Hintergrundkarte ein
   * @arg {ol.layer.WMTS|ol.layer.Group} basemap
   * @since 2.0.0
   */
  _showBasemap(basemap) {
    try {
      // LayerGroups verarbeiten
      const layers = basemap.getLayers().getArray();
      for (let i = 0; i < layers.length; i++) {
        layers[i].setVisible(true);
      }
      basemap.setVisible(true);
    } catch (error) {
      basemap.setVisible(true);
    }
  }

  /**
   * @desc Blendet die Hintergrundkarte aus
   * @arg {ol.layer.WMTS|ol.layer.Group} basemap
   * @since 2.0.0
   */
  _hideBasemap(basemap) {
    try {
      // LayerGroups verarbeiten
      const layers = basemap.getLayers().getArray();
      for (let i = 0; i < layers.length; i++) {
        layers[i].setVisible(false);
      }
      basemap.setVisible(false);
    } catch (error) {
      basemap.setVisible(false);
    }
  }

  /**
   * @desc Setzt die aktive und die nächste Hintergrund durch Klick des Nutzers auf den Toggle-Button
   * @since 2.0.0
   */
  toggle() {
    const togglerContainer = document.getElementById(this.domNode.id + '_basemapToggler');

    // nächste aktive Karte ermitteln sowie die darauffolg. Karte für Bildvorschau!
    for (let i = 0; i < this.basemaps.length; i++) {
      if (this.basemaps[i].getProperties().itnrwName === this.activeBasemap) {
        if (i + 1 < this.basemaps.length) {
          this.activeBasemap = this.basemaps[i + 1].getProperties().itnrwName;
          togglerContainer.setAttribute('data-active-basemap', this.activeBasemap);

          if (i + 2 < this.basemaps.length) {
            this.nextBasemap = this.basemaps[i + 2].getProperties().itnrwName;
          } else {
            this.nextBasemap = this.basemaps[0].getProperties().itnrwName;
          }
          togglerContainer.setAttribute('data-next-basemap', this.nextBasemap);
          break;
        } else {
          this.activeBasemap = this.basemaps[0].getProperties().itnrwName;
          togglerContainer.setAttribute('data-active-basemap', this.activeBasemap);

          this.nextBasemap = this.basemaps[1].getProperties().itnrwName;
          togglerContainer.setAttribute('data-next-basemap', this.nextBasemap);
          break;
        }
      }
    }

    // nächstes Kartenwerk sichtbar schalten, Copyright erneuern
    for (let i = 0; i < this.basemaps.length; i++) {
      if (this.basemaps[i].getProperties().itnrwName === this.activeBasemap) {
        this._showBasemap(this.basemaps[i]);
      } else {
        this._hideBasemap(this.basemaps[i]);
      }
    }

    // Schaltflächenbild setzen
    this._setBasemapImage();
  }
}

export { Toggler };
