// OpenLayers
import { transform } from 'ol/proj';

// map.nrw
import { Marker } from './../Marker';

/**
 * @classdesc Erzeugt neue SetCoordinateTool Instanz
 * @memberOf Module
 * @since 2.0.0
 */
class SetCoordinateTool {
  /**
   * @desc Erstellt und führt das Setup für das div-Element aus
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.mapCrs = this.map.getView().getProjection().getCode().toUpperCase();

    // Ermittle Tool-Layer, um später die Objekte hinzuzufügen
    this.map.getLayers().forEach(layer => {
      if ((layer.get('name') !== undefined) & (layer.get('name') === 'Tools')) {
        const layers = layer.getLayers().getArray();

        this.toolsSource = {
          marker: null,
          linestring: null,
          polygon: null
        };

        for (let i = 0; i < layers.length; i++) {
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_Marker')) {
            this.toolsSource.marker = layers[i].getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_LineString')) {
            this.toolsSource.linestring = layers[i].getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Tools_Polygon')) {
            this.toolsSource.polygon = layers[i].getSource();
          }
        }
      }
    });

    this.toolDiv = document.getElementById(this.toolId);
    for (const cls of ['itnrwTool', 'ms-1', 'itnrwTool_setCoordinateTool']) {
      this.toolDiv.classList.add(cls);
    }
    this.toolDiv.setAttribute('title', 'Koordinate in Karte setzen');

    this.toolDiv.addEventListener(
      'mouseover',
      e => {
        this.toolDiv.setAttribute('style', 'background-color: #e1e1e1; cursor: pointer');
      },
      false
    );

    this.toolDiv.addEventListener(
      'mouseout',
      e => {
        this.toolDiv.setAttribute('style', 'background-color: #ffffff; cursor: default');
      },
      false
    );

    this.tool = e => {
      const features = [];
      this.map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
        if (feature.getGeometry().getType() === 'Point') {
          features.push(feature);
        }
      });

      /**
       * Der Marker darf nur zur Karte hinzugefügt werden, wenn beim Klick kein Feature gefunden wurde.
       * Konnte ein Feature ermittelt werden, so soll die Select-Funktionalität ausgeführt werden.
       */
      if (features.length === 0) {
        const coords = this.map.getCoordinateFromPixel(e.pixel);

        // Gerundete Koordinaten für content und tooltip
        const ctCoords = transform(coords, this.mapCrs, 'EPSG:25832');
        ctCoords[0] = parseFloat((Math.round(ctCoords[0] * 100) / 100).toFixed(1));
        ctCoords[1] = parseFloat((Math.round(ctCoords[1] * 100) / 100).toFixed(1));

        const content = `<b>UTM-Koordinaten Zone 32-Nord</b><br>Ost: ${ctCoords[0]} <br>Nord: ${ctCoords[1]}`;
        const tooltip = `32N ${ctCoords[0]}, ${ctCoords[1]}`;

        // eslint-disable-next-line no-unused-vars
        const marker = new Marker(this, {
          coords: coords,
          content: content,
          tooltip: tooltip,
          label: null,
          image: null,
          layer: 'Tools'
        });

        const geojson = {
          type: 'FeatureCollection',
          crs: {
            type: 'name',
            properties: {
              name: this.mapCrs
            }
          },
          features: [
            {
              geometry: {
                type: 'Point',
                coordinates: []
              },
              properties: {},
              type: 'Feature'
            }
          ]
        };

        geojson.features[0].geometry.coordinates = coords;

        // Callback-Funktion aufrufen
        if (typeof window[this.callback] === 'function') {
          window[this.callback](geojson);
        }
      }
    };
  }

  /**
   * @desc Aktiviert die Funktionalität
   * @since 2.0.0
   */
  activate() {
    if (this.toolDiv.classList.contains('activated')) {
      this.deactivate();
    } else {
      this.toolDiv.classList.add('activated');

      this.map.on('click', this.tool);
    }
  }

  /**
   * @desc Deaktiviert die Funktionalität
   * @since 2.0.0
   */
  deactivate() {
    this.toolDiv.classList.remove('activated');
    this.map.un('click', this.tool);

    // Deselektierte Features, damit das Popover geschlossen wird
    this.interactions.select.marker.getFeatures().clear();
    this.interactions.select.cluster.getFeatures().clear();

    // Sourcen leeren
    if (this.toolsSource.marker) {
      this.toolsSource.marker.clear();
    }
    if (this.toolsSource.linestring) {
      this.toolsSource.linestring.clear();
    }
    if (this.toolsSource.polygon) {
      this.toolsSource.polygon.clear();
    }
  }
}

export { SetCoordinateTool };
