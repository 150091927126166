// OpenLayers
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';

// map.nrw
import MARKER_SRC from './../../images/marker.png';

/**
 * @func ToolsCircleStyle
 * @desc Erzeuge OpenLayers Stil für Tool-Geometrien: Circle
 * @memberOf module:Styles
 * @arg {object} feature
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const ToolsCircleStyle = feature => {
  let radius = (Math.round(feature.getGeometry().getRadius() * 100) / 100).toFixed(1);
  radius = radius.replace('.', ',');

  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 1)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.15)'
    }),
    text: new Text({
      textBaseline: 'middle',
      font: 'normal 15px Arial',
      text: `Radius: ${radius}m`,
      fill: new Fill({
        color: 'rgba(255, 0, 0, 1)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.8 )',
        width: 3
      }),
      placement: 'Point'
    })
  });

  return style;
};

/**
 * @func ToolsMarkerStyle
 * @desc Erzeuge OpenLayers Stil für Tool-Geometrien: Point|Marker
 * @memberOf module:Styles
 * @arg {object} feature
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const ToolsMarkerStyle = feature => {
  const style = new Style({
    image: new Icon({
      anchor: [12, 40],
      anchorXUnits: 'pixels',
      anchorYUnits: 'pixels',
      size: [25, 40],
      src: MARKER_SRC
    })
  });

  return style;
};

/**
 * @func ToolsLineStringStyle
 * @desc Erzeuge OpenLayers Stil für Tool-Geometrien: LineString
 * @memberOf module:Styles
 * @arg {object} feature
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const ToolsLineStringStyle = feature => {
  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 1)',
      width: 2
    })
  });

  return style;
};

/**
 * @func ToolsPolygonStyle
 * @desc Erzeuge OpenLayers Stil für Tool-Geometrien: Polygon
 * @memberOf module:Styles
 * @arg {object} feature
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const ToolsPolygonStyle = feature => {
  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 1)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.15)'
    })
  });

  return style;
};

export { ToolsCircleStyle, ToolsMarkerStyle, ToolsLineStringStyle, ToolsPolygonStyle };
