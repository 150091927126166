/**
 * @func getCenter
 * @desc Ermittelt den aktuellen Kartenmittelpunkt
 * @arg {object} map
 * @memberOf module:Helper
 * @since 2.0.0
 * @returns {array} Kartenmittelpunkt
 */
const getCenter = map => {
  return map.getView().getCenter();
};

/**
 * @func setCenter
 * @desc Center auf einen übergebenen Kartenmittelpunkt
 * @arg {object} map
 * @arg {array} center
 * @memberOf module:Helper
 * @since 2.0.0
 */
const setCenter = (map, center) => {
  if (Array.isArray(center) && center.length === 2) {
    map.getView().setCenter(center);
  }
};

export { getCenter, setCenter };
