// OpenLayers
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';

// map.nrw
import MARKER_SRC from './../../images/marker.png';

/**
 * @desc Verwendung der Primärfarben aus dem Leitfaden Corporate Design für digitale Publikationen ({@link https://lv.it.nrw.de/system/files/media/document/file/IT-NRW_Leitfaden_DigitalePublikationen.pdf})
 * @const COLOR
 * @type {object}
 * @since 2.0.0
 */
const COLOR = {
  fill: 'rgba(0, 100, 156, 1)',
  stroke: 'rgba(0, 100, 156, 0.5)'
};

/**
 * @func POIMarkerStyle
 * @desc Erzeuge OpenLayers Stil für Cluster-Geometrien: einzelner Punkt
 * @arg {object} feature OpenLayers Feature
 * @arg {object} extraDefs Zusätzliche Stil-Definition für Verbindungslinie
 * @memberOf module:Styles
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const POIMarkerStyle = (feature, extraDefs = {}) => {
  let imageWidth = 12;
  let imageHeight = 12;
  let imageAnchor = [0, 0];
  let imageCentroid = 5;
  let imageSrc = MARKER_SRC;
  let labelOffset = [0, 0];
  let label = '';

  if (feature.get('features')) {
    const features = feature.get('features');
    if (Array.isArray(features) && features.length === 1) {
      feature = features[0];
    }
  }

  if (feature.getProperties().image !== undefined && feature.getProperties().image === null) {
    imageWidth = 25; // 40
    imageHeight = 40;
    imageAnchor = [12, 40]; // [16, 40]
    imageSrc = MARKER_SRC;
    labelOffset = [0, 5];
  } else if (feature.getProperties().image !== undefined) {
    imageSrc = feature.getProperties().image;
    const filename = feature.getProperties().image.substring(feature.getProperties().image.lastIndexOf('/') + 1);
    const filenameArr = filename.match(/(\d+_\d+_\d)(.png|.jpg)$/);

    if (filenameArr !== null) {
      // Image mit width_height_centroid verarbeiten
      const whc = filenameArr[1].split('_');
      imageWidth = parseInt(whc[0]);
      imageHeight = parseInt(whc[1]);
      imageCentroid = parseInt(whc[2]);

      imageAnchor = calculateImageAnchor(imageCentroid, imageWidth, imageHeight);
      labelOffset = calculateLabelOffeset(imageCentroid, imageWidth, imageHeight);
    } else {
      // Image mit width_height verarbeiten
      const filenameArr = filename.match(/(\d+_\d+)(.png|.jpg)$/);
      if (filenameArr !== null) {
        // Rückgabe des Symbols mit eigenen Bildmaßen, anonsten Default!
        const wh = filenameArr[1].split('_');
        imageWidth = parseInt(wh[0]);
        imageHeight = parseInt(wh[1]);
        imageAnchor = [imageWidth / 2, imageHeight / 2];

        imageAnchor = calculateImageAnchor(imageCentroid, imageWidth, imageHeight);
        labelOffset = calculateLabelOffeset(imageCentroid, imageWidth, imageHeight);
      }
    }
  }

  if (feature.getProperties().label) {
    label = feature.getProperties().label;
    /**
     * Der Nutzer soll die Möglichkeit erhalten, ein Label über mehrere Zeilen zu erstellen.
     * Dies ist über die Angabe von \n sowie <br> möglich.
     *
     * Achtung: Bei Angabe von \n wird automatisch \\n generiert, daher muss der RegEx auf \\n prüfen
     * und anschließend mit \n ersetzen.
     */
    label = label.replace(/\\n|<br>/g, '\n');
  }

  return new Style(
    Object.assign(
      {},
      {
        image: new Icon({
          anchor: imageAnchor,
          anchorXUnits: 'pixels',
          anchorYUnits: 'pixels',
          size: [imageWidth, imageHeight],
          src: imageSrc
        }),
        text: new Text({
          font: '13px Verdana, Arial, Helvetica, sans-serif',
          offsetX: labelOffset[0],
          offsetY: labelOffset[1],
          text: label,
          textAlign: 'center',
          textBaseline: 'top',
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.9)'
          }),
          stroke: new Stroke({
            color: 'rgba(255, 255, 255, 1)',
            width: 2
          })
        })
      },
      extraDefs
    )
  );
};

/**
 * @func POIClusterStyle
 * @desc Erzeuge OpenLayers Stil für Cluster-Geometrien: mehrere Punkte als Cluster
 * @arg {number} size Anzahl der Features in einem Cluster
 * @memberOf module:Styles
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const POIClusterStyle = size => {
  const radius = Math.max(window.itnrwConfig.MARKER_SIZE * 0.75, Math.min(size * 0.75, window.itnrwConfig.MARKER_SIZE));
  const dash = (2 * Math.PI * radius) / 6;
  const dashes = [dash, dash];

  return new Style({
    image: new CircleStyle({
      radius: radius,
      stroke: new Stroke({
        color: COLOR.stroke,
        width: window.itnrwConfig.MARKER_SIZE * 0.75,
        lineDash: dashes,
        lineCap: 'butt'
      }),
      fill: new Fill({
        color: COLOR.fill
      })
    }),
    text: new Text({
      text: size.toString(),
      fill: new Fill({
        color: '#fff'
      })
    })
  });
};

/**
 * @func identifyPOIStyle
 * @desc Ermittle, welcher OpenLayers Stil verwendet werden
 * @arg {object} feature
 * @memberOf module:Styles
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const identifyPOIStyle = feature => {
  let size;

  if (feature && feature.get('features')) {
    size = feature.get('features').length;

    if (size === 1) {
      return POIMarkerStyle(feature);
    } else {
      return POIClusterStyle(size);
    }
  } else {
    return POIMarkerStyle([feature]);
  }
};

/**
 * @func calculateImageAnchor
 * @desc Berechnet den Versatz der Grafik
 * @arg {number} centroid
 * @arg {number} width
 * @arg {number} height
 * @since 2.0.0
 * @returns {array}
 */
const calculateImageAnchor = (centroid, width, height) => {
  // Standardmäßig soll der Icon mittig positioniert werden.
  let anchor = [width / 2, height / 2];

  switch (centroid) {
    case 1:
      anchor = [0, 0];
      break;

    case 2:
      anchor = [width / 2, 0];
      break;

    case 3:
      anchor = [width, 0];
      break;

    case 4:
      anchor = [0, height / 2];
      break;

    case 5:
      anchor = [width / 2, height / 2];
      break;

    case 6:
      anchor = [width, height / 2];
      break;

    case 7:
      anchor = [0, height];
      break;

    case 8:
      anchor = [width / 2, height];
      break;

    case 9:
      anchor = [width, height];
      break;
  }

  return anchor;
};

/**
 * @desc Berechnet den Versatz des Labels
 * @arg {number} centroid
 * @arg {number} width
 * @arg {number} height
 * @since 2.0.0
 * @returns {array}
 */
const calculateLabelOffeset = (centroid, width, height) => {
  let offset = [0, height / 2 + 5];

  switch (centroid) {
    case 1:
      offset = [width / 2, height + 5];
      break;

    case 2:
      offset = [0, height + 5];
      break;

    case 3:
      offset = [-width / 2, height + 5];
      break;

    case 4:
      offset = [width / 2, height / 2 + 5];
      break;

    case 5:
      offset = [0, height / 2 + 5];
      break;

    case 6:
      offset = [-width / 2, height / 2 + 5];
      break;

    case 7:
      offset = [width / 2, 5];
      break;

    case 8:
      offset = [0, 5];
      break;

    case 9:
      offset = [-width / 2, 5];
      break;
  }

  return offset;
};

export { identifyPOIStyle, POIClusterStyle, POIMarkerStyle };
