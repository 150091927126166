// OpenLayers
import ImageArcGISRest from 'ol/source/ImageArcGISRest';
import TileArcGISRest from 'ol/source/TileArcGISRest';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';

import { createTileGrid } from './../Helper';

/**
 * @classdesc Erzeugt neue AGS Instanz zur Darstellung von ArcGIS Server Diensten aus "data-itnrw-layers"
 * @memberOf Layer
 * @since 2.0.0
 */
class AGSLayer {
  /**
   * @desc Erzeugt eine neue OpenLayers Source vom Type ArgGISRest
   * @arg {object} params Konfiguration für AGS
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.add();

    return this.ags;
  }

  /**
   * @desc Fügt OpenLayers WMS Layer für ArcGIS Server Dienste hinzu
   * @since 2.0.0
   */
  add() {
    if (this.ags.singleTile) {
      const source = new ImageArcGISRest({
        url: this.ags.service,
        params: {
          LAYERS: 'show:' + this.ags.layer.toString()
        }
      });

      this.ags = new ImageLayer({
        source: source,
        opacity: this.ags.opacity,
        zIndex: 5000,
        name: 'Extra-Layer: AGS'
      });
    } else {
      const tileGrid = createTileGrid(this.map);

      const source = new TileArcGISRest({
        url: this.ags.service,
        params: {
          LAYERS: 'show:' + this.ags.layer.toString()
        },
        tileGrid
      });

      this.ags = new TileLayer({
        source: source,
        opacity: this.ags.opacity,
        zIndex: 5000,
        name: 'Extra-Layer: AGS'
      });
    }

    this.map.addLayer(this.ags);
    this.extraLayer.push(this.ags);
  }
}

export { AGSLayer };
