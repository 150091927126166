// OpenLayers
import AnimatedClusterLayer from 'ol-ext/layer/AnimatedCluster';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import ClusterSource from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';

// map.nrw
import { identifyPOIStyle, POIMarkerStyle } from './../Styles';

/**
 * @classdesc Erzeuge neuen POI Layer und deren Funktionalität (Cluster / Marker)
 * @memberOf Layer
 * @since 2.0.0
 */
class POILayer {
  /**
   * @desc Erzeugt eine neue OpenLayers Source vom Type Cluster / Vektor
   * @arg {object} map - Objekt mit domNode, Konfiguration, usw.
   * @since 2.0.0
   */
  constructor(map) {
    Object.assign(this, map);

    this.poi = {
      cluster: null,
      marker: null
    };

    this.add('Cluster');
    this.add('Marker');
    this.add('GroupLayer');
    this.map.addLayer(this.group);

    return this.poi;
  }

  /**
   * @desc Fügt OpenLayers Gruppen Layer, sowie Layer für den Cluster und Marker hinzu
   * @arg {string} type
   * @since 2.0.0
   */
  add(type) {
    if (type === 'Cluster') {
      const source = new VectorSource({
        features: []
      });
      const clusterSource = new ClusterSource({
        distance: this.config.cluster.distance,
        source: source
      });

      this.poi.cluster = new AnimatedClusterLayer({
        source: clusterSource,
        zIndex: 7000,
        name: 'Cluster',
        style: feature => {
          return identifyPOIStyle(feature);
        }
      });
    } else if (type === 'Marker') {
      const source = new VectorSource({
        features: []
      });

      this.poi.marker = new VectorLayer({
        source: source,
        zIndex: 7000,
        name: 'Marker',
        style: feature => {
          return POIMarkerStyle(feature);
        }
      });
    } else if (type === 'GroupLayer') {
      this.group = new LayerGroup({
        layers: [this.poi.cluster, this.poi.marker],
        name: 'POI'
      });
    }
  }
}

export { POILayer };
