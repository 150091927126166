// OpenLayers
import TileLayer from 'ol/layer/Tile';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';

/**
 * @classdesc Erzeugt neue WMTS Instanz zur Darstellung von WMTS Diensten aus "data-itnrw-layers"
 * Diese Klasse ist für eine zukünftige Version vorbereitet
 * @memberOf Layer
 * @since 2.0.0
 */
class WMTSLayer {
  /**
   * @desc Erzeugt eine neue OpenLayers Source vom Type
   * @arg {object} params Konfiguration für WMTS
   * @since Vorbereitet
   */
  constructor(params) {
    Object.assign(this, params);

    this.add();

    return this.wmts;
  }

  /**
   * @desc Fügt OpenLayers WMTS Layer hinzu
   * @since Vorbereitet
   */
  async add() {
    try {
      const rest = fetch(this.wmts.service + '/1.0.0/WMTSCapabilities.xml')
        .then(response => response.text())
        .then(data => {
          let capabilities = new WMTSCapabilities().read(data);

          if (!capabilities || !capabilities.ServiceIdentification) {
            capabilities = undefined;
          }

          return capabilities;
        });

      const kvp = fetch(this.wmts.service + '?REQUEST=GetCapabilities&SERVICE=WMTS&VERSION=1.0.0')
        .then(response => response.text())
        .then(data => {
          let capabilities = new WMTSCapabilities().read(data);

          if (!capabilities || !capabilities.ServiceIdentification) {
            capabilities = undefined;
          }

          return capabilities;
        });

      await Promise.allSettled([rest, kvp]).then(results => {
        for (const result of results) {
          if (result.status === 'fulfilled' && result.value !== undefined) {
            const options = optionsFromCapabilities(result.value, {
              layer: this.wmts.layer.toString(),
              projection: 'EPSG:25832'
            });

            this.wmts = new TileLayer({
              source: new WMTS(options),
              opacity: this.wmts.opacity,
              zIndex: 5000,
              name: 'Extra-Layer: WMTS'
            });

            this.map.addLayer(this.wmts);
            this.extraLayer.push(this.wmts);

            break;
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export { WMTSLayer };
