// OpenLayers
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

// map.nrw
import { GeometryCircleStyle, GeometryPolygonStyle, GeometryLineStringStyle } from './../Styles';

/**
 * @classdesc Erzeuge neuen Geometry Layer und deren Funktionalität
 * @memberOf Layer
 * @since 2.0.0
 */
class GeometryLayer {
  /**
   * @desc Erzeugt eine neue OpenLayers Source vom Type Vektor
   * @arg {object} map - Objekt mit domNode, Konfiguration, usw.
   * @since 2.0.0
   */
  constructor(map) {
    Object.assign(this, map);

    this.add();

    return this.geometry;
  }

  /**
   * @desc Fügt OpenLayers Geometrie Layer hinzu
   * @since 2.0.0
   */
  add() {
    const source = new VectorSource({
      features: []
    });

    this.geometry = new VectorLayer({
      source: source,
      zIndex: 6000,
      name: 'Geometry',
      style: feature => {
        let style;

        if (feature.getGeometry().getType() === 'Circle') {
          style = GeometryCircleStyle(feature);
        } else if (feature.getGeometry().getType() === 'Polygon') {
          style = GeometryPolygonStyle(feature);
        } else if (feature.getGeometry().getType() === 'LineString') {
          style = GeometryLineStringStyle(feature);
        }

        return style;
      }
    });

    this.map.addLayer(this.geometry);
  }
}

export { GeometryLayer };
