// OpenLayers
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';

import MARKER_SRC from './../../images/geolocation_blue_20_20.png';

/**
 * @classdesc Erzeuge neuen Geolocation Layer und deren Funktionalität
 * @memberOf Layer
 * @since 2.0.0
 */
class GeolocationLayer {
  /**
   * @desc Erzeugt eine neue OpenLayers Source vom Type Vektor
   * @arg {object} map - Objekt mit domNode, Konfiguration, usw.
   * @since 2.0.0
   */
  constructor(map) {
    Object.assign(this, map);

    this.add();

    return this.geolocation;
  }

  /**
   * @desc Fügt OpenLayers Geolocation Layer hinzu
   * @since 2.0.0
   */
  add() {
    const source = new VectorSource({
      features: []
    });

    this.geolocation = new VectorLayer({
      source: source,
      zIndex: 9000,
      name: 'Geolocation',
      style: () => {
        const imageSrc = MARKER_SRC;
        const style = new Style({
          image: new Icon({
            size: [20, 20],
            src: imageSrc
          })
        });
        return style;
      }
    });

    this.map.addLayer(this.geolocation);
  }
}

export { GeolocationLayer };
