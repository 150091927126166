// OpenLayers
import Hover from 'ol-ext/interaction/Hover';

/**
 * @classdesc Erzeuge eine neue Hover-Interaktion und füge zur Karte hinzu
 * @memberOf Interaction
 * @since 2.0.0
 */
class HoverInteraction {
  /**
   * @desc Erzeugt eine neue OpenLayers Interaktion vom Type Hover
   * @arg {object} that Übergibt "this"
   * @since 2.0.0
   */
  constructor(that) {
    Object.assign(this, that);

    this.add();

    return this.hover;
  }

  /**
   * @desc Fügt Hover Interaktion hinzu
   * @since 2.0.0
   */
  add() {
    /**
     * new Hover ermöglicht das Filtern von Layern über 'layers':
     *
     * layers: [this.cluster]
     *
     * Dies hat aber aktuell zur Folge, dass beim Spiderfier von
     * Clustern keine Tooltips dargestellt werden. Tooltips
     * einzelner Punkte werden dargestellt.
     *
     * Daher wird die Filtermöglichkeit deaktiviert und stattdessen
     * ein Filter auf die Features angewendet. Es wird immer true zu-
     * rückgegeben, außer es handelt sich um den Layer "Geometry".
     */
    const overlayLabel = this.map.getOverlayById('label');

    this.hover = new Hover({
      cursor: 'pointer',
      featureFilter: (feature, layer) => {
        if (layer) {
          if (layer.getProperties().name && layer.getProperties().name !== 'Geometry') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    });

    this.map.addInteraction(this.hover);

    this.hover.on('enter', e => {
      let features;

      /**
       * Die Hover-Funktionalität erwartet ein Array mit einem Feature.
       * Dies ist der Fall, da hier mit Clustern gearbeitet wird. Die
       * normalen Marker sind jedoch in keime Cluster enthalten, daher
       * muss das Array mit dem einzelnen Feature erzwungen werden:
       * -> features = [e.feature]
       */
      if (e.layer !== null) {
        if (e.layer.get('name') === 'Cluster') {
          features = e.feature.get('features');
        } else {
          if (e.feature.getGeometry().getType() === 'Point') {
            features = [e.feature];
          } else {
            overlayLabel.setPosition();
          }
        }
      } else {
        features = e.feature.get('features');
      }

      if (features && features.length === 1) {
        if (features[0].getProperties().tooltip !== undefined) {
          overlayLabel.getElement().innerHTML = features[0].getProperties().tooltip;
          overlayLabel.setPosition(e.feature.getGeometry().getCoordinates());
        }
      }
    });

    this.hover.on('leave', e => {
      overlayLabel.setPosition();
    });
  }
}

export { HoverInteraction };
