// OpenLayers
import { Fill, Stroke, Style } from 'ol/style';

/**
 * @func GeometryCircleStyle
 * @desc Erzeuge OpenLayers Stil für Geometry-Geometrien: Circle
 * @memberOf module:Styles
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const GeometryCircleStyle = feature => {
  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 1)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.15)'
    })
  });

  return style;
};

/**
 * @func GeometryPolygonStyle
 * @desc Erzeuge OpenLayers Stil für Geometry-Geometrien: Polygon
 * @memberOf module:Styles
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const GeometryPolygonStyle = feature => {
  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 1)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.15)'
    })
  });

  return style;
};

/**
 * @func GeometryLineStringStyle
 * @desc Erzeuge OpenLayers Stil für Geometry-Geometrien: LineString
 * @memberOf module:Styles
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const GeometryLineStringStyle = feature => {
  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 1)',
      width: 2
    })
  });

  return style;
};

export { GeometryCircleStyle, GeometryPolygonStyle, GeometryLineStringStyle };
