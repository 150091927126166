// OpenLayers
import { Feature } from 'ol';
import Polygon from 'ol/geom/Polygon';

/**
 * @classdesc Erzeugt für einen neuen Marker ein Polygon und fügt diesen zur Kartenansicht hinzu
 * @since 2.0.0
 */
class PolygonMarker {
  /**
   * @desc Stellt ein oder mehrere Polygone für einen Marker dar
   * @arg {object} that Übergibt "this"
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(that, params) {
    Object.assign(this, that, params);

    const feature = new Feature({
      geometry: new Polygon([this.pointArray])
    });

    this.searchSource.polygon.addFeature(feature);
  }
}

export { PolygonMarker };
