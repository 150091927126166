// OpenLayers
import { Fill, Icon, Stroke, Style } from 'ol/style';

// map.nrw
import MARKER_SRC from './../../images/marker.png';

/**
 * @func SearchMarkerStyle
 * @desc Erzeuge OpenLayers Stil für Suchergebnisse: Point|Marker
 * @memberOf module:Styles
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const SearchMarkerStyle = () => {
  const style = new Style({
    image: new Icon({
      anchor: [12, 40],
      anchorXUnits: 'pixels',
      anchorYUnits: 'pixels',
      size: [25, 40],
      src: MARKER_SRC
    })
  });

  return style;
};

/**
 * @func SearchPolygonStyle
 * @desc Erzeuge OpenLayers Stil für Suchergebnisse: Polygon
 * @memberOf module:Styles
 * @since 2.0.0
 * @returns {ol.style.Style}
 */
const SearchPolygonStyle = () => {
  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 1)',
      width: 2,
      lineDash: [7, 7]
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.15)'
    })
  });

  return style;
};

export { SearchMarkerStyle, SearchPolygonStyle };
