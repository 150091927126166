// OpenLayers
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';

import { createTileGrid } from './../Helper';

/**
 * @classdesc Erzeugt neue WMS Instanz zur Darstellung von WMS Diensten aus "data-itnrw-layers"
 * @memberOf Layer
 * @since 2.0.0
 */
class WMSLayer {
  /**
   * @desc Erzeugt eine neue OpenLayers Source vom Type ImageWMS
   * @arg {object} params Konfiguration für WMS
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.add();

    return this.wms;
  }

  /**
   * @desc Fügt OpenLayers WMS Layer hinzu
   * @since 2.0.0
   */
  add() {
    if (this.wms.singleTile) {
      const source = new ImageWMS({
        url: this.wms.service,
        params: {
          LAYERS: this.wms.layer.toString(),
          VERSION: this.wms.version,
          QUERY_LAYERS: this._getQueryLayers(),
          INFO_FORMAT: 'text/html'
        }
      });

      this.wms = new ImageLayer({
        source: source,
        opacity: this.wms.opacity,
        zIndex: 5000,
        name: 'Extra-Layer: WMS'
      });
    } else {
      const tileGrid = createTileGrid(this.map);

      const source = new TileWMS({
        url: this.wms.service,
        params: {
          LAYERS: this.wms.layer.toString(),
          VERSION: this.wms.version,
          QUERY_LAYERS: this._getQueryLayers(),
          INFO_FORMAT: 'text/html'
        },
        tileGrid
      });

      this.wms = new TileLayer({
        source: source,
        opacity: this.wms.opacity,
        zIndex: 5000,
        name: 'Extra-Layer: WMS'
      });
    }

    this.map.addLayer(this.wms);
    this.extraLayer.push(this.wms);
  }

  /**
   * @desc Ermittelt alle Layer für den GetFeatureInfo-Request und gibt diese aus
   * @since 2.0.0
   * @returns {array}
   */
  _getQueryLayers() {
    const layers = [];
    if (this.wms.layer.length === this.wms.featureInfo.length) {
      for (let i = 0; i < this.wms.featureInfo.length; i++) {
        if (this.wms.featureInfo[i]) {
          layers.push(this.wms.layer[i]);
        }
      }
    }

    return layers;
  }
}

export { WMSLayer };
