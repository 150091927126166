// OpenLayers
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

// map.nrw
import { SearchMarkerStyle, SearchPolygonStyle } from './../Styles';

/**
 * @classdesc Erzeuge neuen Search Gruppenlayer und deren Funktionalität für Marker/Polygone
 * @memberOf Layer
 * @since 2.0.0
 */
class SearchLayer {
  /**
   * @desc Erzeugt eine neue OpenLayers Source vom Type Vektor
   * @arg {object} map - Objekt mit domNode, Konfiguration, usw.
   * @since 2.0.0
   */
  constructor(map) {
    Object.assign(this, map);

    this.search = {
      marker: null,
      polygon: null
    };

    this.add('Marker');
    this.add('Polygon');
    this.add('GroupLayer');
    this.map.addLayer(this.group);

    return this.search;
  }

  /**
   * @desc Fügt OpenLayers Gruppen Layer, sowie Layer für den Marker und Poylgone hinzu
   * @arg {string} type
   * @since 2.0.0
   */
  add(type) {
    if (type === 'Marker') {
      const source = new VectorSource({
        features: []
      });

      this.search.marker = new VectorLayer({
        source: source,
        zIndex: 9001,
        name: 'Search_Marker',
        style: feature => {
          let style;

          if (feature.getGeometry().getType() === 'Point') {
            style = SearchMarkerStyle(feature);
          }

          return style;
        }
      });
    } else if (type === 'Polygon') {
      const source = new VectorSource({
        features: []
      });

      this.search.polygon = new VectorLayer({
        source: source,
        zIndex: 9000,
        name: 'Search_Polygon',
        style: feature => {
          let style;

          if (feature.getGeometry().getType() === 'Polygon') {
            style = SearchPolygonStyle(feature);
          }

          return style;
        }
      });
    }
    if (type === 'GroupLayer') {
      this.group = new LayerGroup({
        layers: [this.search.marker, this.search.polygon],
        name: 'Search'
      });
    }
  }
}

export { SearchLayer };
