// Bootstrap
import { Dropdown } from 'bootstrap';

// OpenLayers
import { transform } from 'ol/proj';

// Turf
import { point as turfPoint } from '@turf/helpers';

// map.nrw
import { Marker } from './../Marker';

/**
 * @classdesc Erzeugt neue Instanz zur Darstellung des Koordinatenpanels
 * @memberOf Module
 * @since 2.0.0
 */
class CoordinatePanel {
  /**
   * @desc Erstellt und führt das Setup für das Koordinatenpanel aus
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this.mapCrs = this.map.getView().getProjection().getCode().toUpperCase();

    // Ermittle Search-Layer, um später die Objekte hinzuzufügen
    this.map.getLayers().forEach(layer => {
      if ((layer.get('name') !== undefined) & (layer.get('name') === 'Search')) {
        const layers = layer.getLayers().getArray();

        this.searchSource = {
          marker: null,
          polygon: null
        };

        for (let i = 0; i < layers.length; i++) {
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Search_Marker')) {
            this.searchSource.marker = layers[i].getSource();
          }
          if ((layers[i].get('name') !== undefined) & (layers[i].get('name') === 'Search_Polygon')) {
            this.searchSource.polygon = layers[i].getSource();
          }
        }
      }
    });

    this._setupCoordinatePanel();
    this._getCoordinateEntries();

    if (this.crs.length > 0) {
      this._createCoordinateContainer();
      this._createCoordinatePanel();
      this._setCoordinateEntry(this.crs[0].id); // Default-Eintrag setzen
      this.add();
    }
  }

  /**
   * @desc Fügt die Funktionalität für das Koordinatenpanel hinzu
   * @since 2.0.0
   */
  _setupCoordinatePanel() {
    this.coordinatePanelConfig = {
      'epsg:25832': {
        name: 'UTM Zone 32N',
        placeholder: 'UTM Zone 32N',
        title: 'z.B. (32)344552.3, 5679423.4',
        id: 'epsg:25832'
      },
      'epsg:25833': {
        name: 'UTM Zone 33N',
        placeholder: 'UTM Zone 33N',
        title: 'z.B. (33)388040.9, 5819545.3',
        id: 'epsg:25833'
      },
      'epsg:31466': {
        name: 'Gauß-Krüger Zone 2',
        placeholder: 'Gauß-Krüger Zone 2',
        title: 'z.B. 2554004.9, 5679183.1',
        id: 'epsg:31466'
      },
      'epsg:31467': {
        name: 'Gauß-Krüger Zone 3',
        placeholder: 'Gauß-Krüger Zone 3',
        title: 'z.B. 3344563, 5681254.5',
        id: 'epsg:31467'
      },
      'epsg:4326': {
        name: 'Geographische Koordinaten',
        placeholder: 'Geographische Koordinaten',
        title: 'z.B. 6.77281, 51.24496',
        id: 'epsg:4326'
      }
    };
  }

  /**
   * @desc Filter die entsprechenden Koordinatenreferenzsystem-Einträge heraus
   * @since 2.0.0
   */
  _getCoordinateEntries() {
    this.crs = [];

    if (this.crsFilter[0] === 'alle') {
      for (const node in this.coordinatePanelConfig) {
        this.crs.push(this.coordinatePanelConfig[node]);
      }
    } else {
      for (let i = 0; i < this.crsFilter.length; i++) {
        if (Object.prototype.hasOwnProperty.call(this.coordinatePanelConfig, this.crsFilter[i])) {
          this.crs.push(this.coordinatePanelConfig[this.crsFilter[i]]);
        }
      }
    }
  }

  /**
   * @desc Erstellt das div-Element (Container) für das Koordinatenpanel
   * @since 2.0.0
   */
  _createCoordinateContainer() {
    const mapDiv = document.getElementById(this.domNode.id + '_panelbar');
    /**
     * Koordinatenpanel erstellen und dem div-Element übergeben
     */
    const togglerContainer = document.createElement('div');
    for (const cls of ['me-2', 'mb-2', 'itnrwCoordinatePanel', 'coords']) {
      togglerContainer.classList.add(cls);
    }
    togglerContainer.setAttribute('id', this.domNode.id + '_coordPanel');
    mapDiv.appendChild(togglerContainer);
  }

  /**
   * @desc Erstellt das Dropdown-Menü sowie die Eingabe für das Koordinatenpanel
   * @since 2.0.0
   */
  _createCoordinatePanel() {
    const coordinatePanelDiv = document.getElementById(this.domNode.id + '_coordPanel');

    // CRS-Einträge für die Liste zusammenfügen
    let list = '';
    for (let i = 0; i < this.crs.length; i++) {
      if (i === 0) {
        list += '<li><h6 class="dropdown-header itnrwCoordinateItem">Quellkoordinaten in ..</h6></li>';
      }
      list += `<li><a class="dropdown-item itnrwCoordinateItem" href="#" data-id="${this.crs[i].id}">${this.crs[i].name}</a></li>`;
    }

    /**
     * border-start-0 und border-end-0 wird benötigt, damit die Buttons und das Input-Feld miteinander von Styling her miteinander verschmelzen.
     * Dies wird jedoch nur benötigt, wenn mehr als 1 Eintrag vorhanden ist
     */
    let htmlPrepend = '';
    if (this.crs.length > 1) {
      htmlPrepend = `<button class="btn btn-outline-dark border-end-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" title="Koordinatensystem ändern"></button>
      <ul class="dropdown-menu" id="${this.domNode.id}_coordinatePanelList">${list}</ul>
      <input class="form-control coordinatePanelInput border-start-0 border-end-0" id="${this.domNode.id}_coordinatePanelInput" type="search" placeholder="" aria-label="Suche">`;
    } else {
      htmlPrepend = `<input class="form-control coordinatePanelInput border-end-0" id="${this.domNode.id}_coordinatePanelInput" type="search" placeholder="" aria-label="Suche">`;
    }

    const html = `<div class="input-group input-group-sm">
      ${htmlPrepend}
      <button id="${this.domNode.id}_coordinatePanelClear" class="btn btn-outline-dark border-start-0" type="button" title="Suche löschen">×</button>
    </div>`;

    coordinatePanelDiv.innerHTML = html;
  }

  /**
   * @desc Setzt den Placeholder und die aktuelle ID für die weitere Verwendung
   * @arg id
   * @since 2.0.0
   */
  _setCoordinateEntry(id) {
    const coordinateInput = document.getElementById(this.domNode.id + '_coordinatePanelInput');

    for (let i = 0; i < this.crs.length; i++) {
      if (this.crs[i].id === id) {
        this.actualCoordinateId = this.crs[i].id;
        coordinateInput.setAttribute('placeholder', this.crs[i].placeholder);
        coordinateInput.setAttribute('title', this.crs[i].title);
      }
    }
  }

  /**
   * @desc Erzeugt den Content und Tooltip zur Darstellung in der Karte
   * @arg coords UTM32 Koordinaten
   * @since 2.0.0
   */
  _formatResults(coords) {
    // UTM32 Koordinaten auf volle Meter runden
    coords[0] = parseFloat((Math.round(coords[0] * 100) / 100).toFixed(1));
    coords[1] = parseFloat((Math.round(coords[1] * 100) / 100).toFixed(1));

    // Für die Darstellung in der Karte
    let content = '';
    let tooltip = '';
    let point = null;

    // Für Callback
    const geojson = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: this.mapCrs
        }
      },
      features: [
        {
          geometry: {
            type: 'Point',
            coordinates: []
          },
          properties: {},
          type: 'Feature'
        }
      ]
    };

    content = `<b>UTM-Koordinaten Zone 32-Nord</b><br>Ost: ${coords[0]} <br>Nord: ${coords[1]}`;
    tooltip = `32N ${coords[0]}, ${coords[1]}`;

    coords = transform(coords, 'EPSG:25832', this.mapCrs);
    point = turfPoint(coords);
    geojson.features[0].geometry.coordinates = coords;

    return {
      point: point,
      content: content,
      tooltip: tooltip,
      geojson: geojson
    };
  }

  /**
   * @desc Stellt die Koordinaten in der Karte dar
   * @since 2.0.0
   */
  _visualizeResults() {
    // eslint-disable-next-line no-unused-vars
    const marker = new Marker(this, {
      coords: this.formattedResult.point.geometry.coordinates,
      content: this.formattedResult.content,
      tooltip: this.formattedResult.tooltip,
      label: null,
      image: null,
      layer: 'Search'
    });

    this.map.getView().setCenter(this.formattedResult.point.geometry.coordinates);
    this.map.getView().setZoom(this.zoomlevel);

    // Callback-Funktion aufrufen
    if (typeof window[this.callback] === 'function') {
      window[this.callback](this.formattedResult.geojson);
    }
  }

  /**
   * @desc Fügt die Funktionalität zur Verarbeitung der Koordinaten hinzu
   * @since 2.0.0
   */
  add() {
    // Event Listener Wechsel eines Eintrages
    if (document.getElementById(this.domNode.id + '_coordinatePanelList')) {
      document.getElementById(this.domNode.id + '_coordinatePanelList').addEventListener('click', e => {
        e.preventDefault();
        this.clear();

        const id = e.target.getAttribute('data-id');
        this._setCoordinateEntry(id);

        document.getElementById(this.domNode.id + '_coordinatePanelInput').focus();
      });
    }

    // Event Listener Klick Löschen-/Entfernen-Button
    document.getElementById(this.domNode.id + '_coordinatePanelClear').addEventListener('click', e => {
      e.preventDefault();
      this.clear();
    });

    // Event Listener Enter Input-Feld
    document.getElementById(this.domNode.id + '_coordinatePanelInput').addEventListener('keyup', e => {
      e.preventDefault();

      if (e.key === 'Enter') {
        const coordString = e.target.value;
        const crs = this.actualCoordinateId.toUpperCase();
        let coords = [];
        /**
         * Mögliche Werte zum Testen:
         *
         * EPSG:25832
         * -> 343809.13, 5676545.0
         * -> 32343809.13, 5676545.0
         * EPSG:25833
         * -> 389989.26, 5819704.59
         * -> 33389989.26, 5819704.59
         * EPSG:31466
         * -> 2553381.34, 5676276.55
         * EPSG:31467
         * -> 3343821.08, 5678375.1
         * EPSG:4326
         * -> 6.7634330, 51.2189015
         */
        if (crs === 'EPSG:25832') {
          if (
            coordString.match(/^\s*\d{6}\.*\d*\s*,\s*\d{7}\.*\d*/) ||
            coordString.match(/^\s*32\d{6}\.*\d*\s*,\s*\d{7}\.*\d*/)
          ) {
            coords = coordString.split(',');

            if (coords[0].match(/^\s*\d{8}\.*\d*\s*/) && coords[0].match(/^\s*32/)) {
              coords[0] = coords[0].substring(2, coords[0].length);
            }

            coords[0] = parseFloat(coords[0].trim());
            coords[1] = parseFloat(coords[1].trim());

            // Keine Transformation bei EPSG:25832
          }
        } else if (crs === 'EPSG:25833') {
          if (
            coordString.match(/^\s*\d{6}\.*\d*\s*,\s*\d{7}\.*\d*/) ||
            coordString.match(/^\s*33\d{6}\.*\d*\s*,\s*\d{7}\.*\d*/)
          ) {
            coords = coordString.split(',');

            if (coords[0].match(/^\s*\d{8}\.*\d*\s*/) && coords[0].match(/^\s*33/)) {
              coords[0] = coords[0].substring(2, coords[0].length);
            }

            coords[0] = parseFloat(coords[0].trim());
            coords[1] = parseFloat(coords[1].trim());

            coords = transform(coords, crs, 'EPSG:25832');
          }
        } else if (crs === 'EPSG:31466') {
          if (coordString.match(/^\s*2\d{6}\.*\d*\s*,\s*\d{7}\.*\d*/)) {
            coords = coordString.split(',');

            coords[0] = parseFloat(coords[0].trim());
            coords[1] = parseFloat(coords[1].trim());

            coords = transform(coords, crs, 'EPSG:25832');
          }
        } else if (crs === 'EPSG:31467') {
          if (coordString.match(/^\s*3\d{6}\.*\d*\s*,\s*\d{7}\.*\d*/)) {
            coords = coordString.split(',');

            coords[0] = parseFloat(coords[0].trim());
            coords[1] = parseFloat(coords[1].trim());

            coords = transform(coords, crs, 'EPSG:25832');
          }
        } else if (crs === 'EPSG:4326') {
          if (coordString.match(/^\s*\d+\.*\d*\s*,\s*\d+\.*\d*/)) {
            coords = coordString.split(',');

            coords[0] = parseFloat(coords[0].trim());
            coords[1] = parseFloat(coords[1].trim());

            /**
             * INFO: Why is the order of a coordinate [lon,lat], and not [lat,lon]?
             *
             * Because of two different and incompatible conventions. Latitude and longitude are normally given in that order. Maps are 2D representations/projections
             * of the earth's surface, with coordinates expressed in the x,y grid of the Cartesian system. As they are by convention drawn with west on the left and
             * north at the top, this means that x represents longitude, and y latitude. As stated above, OpenLayers is designed to handle all projections, but the
             * default view is in projected Cartesian coordinates. It would make no sense to have duplicate functions to handle coordinates in both the Cartesian x,y
             * and lat,lon systems, so the degrees of latitude and longitude should be entered as though they were Cartesian, in other words, they are lon,lat.
             *
             * If you have difficulty remembering which way round it is, use the language code for English, en, as a mnemonic: East before North.
             */
            if (coords[0] > coords[1]) {
              coords = transform([coords[1], coords[0]], crs, 'EPSG:25832');
            } else {
              coords = transform([coords[0], coords[1]], crs, 'EPSG:25832');
            }
          }
        }

        if (coords.length === 2) {
          this.formattedResult = this._formatResults(coords);
          this._visualizeResults();
        }
      }
    });

    /**
     * Fix
     * Wenn mehrere BS-Versionen eingebunden sind, soll auf einem
     * Klick auf den DropDown-Button das Menü ausgeklappt werden.
     */
    this.dropdownBtn = document.getElementById(this.domNode.id + '_coordPanel').getElementsByTagName('button')[0];
    this.dropdownBs = new Dropdown(this.dropdownBtn);

    this.dropdownBtn.addEventListener('click', () => {
      this.dropdownBs.show();
    });
  }

  /**
   * @desc Löscht den Inhalt des Input-Elementes
   * @since 2.0.0
   */
  clear() {
    document.getElementById(this.domNode.id + '_coordinatePanelInput').value = '';

    // Deselektierte Features, damit das Popover geschlossen wird
    this.interactions.select.marker.getFeatures().clear();
    this.interactions.select.cluster.getFeatures().clear();

    // Sourcen leeren
    if (this.searchSource.marker) {
      this.searchSource.marker.clear();
    }
    if (this.searchSource.polygon) {
      this.searchSource.polygon.clear();
    }
  }
}

export { CoordinatePanel };
