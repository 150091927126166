import { Geolocation } from 'ol';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

const TIMEOUT = 30000;
let geolocationSource;

/**
 * @desc Erstellt Geolocation Button
 * @since 2.0.0
 */
const createGeolocation = (domNodeId, that) => {
  const geolocation = new Geolocation({
    trackingOptions: {
      enableHighAccuracy: true,
      timeout: TIMEOUT,
      maximumAge: 2000
    }
  });

  const locate = async () => {
    geolocationSource.clear();

    try {
      await locateMe();
    } catch (error) {
      let text = '';

      switch (error.code) {
        case 1: // PERMISSION_DENIED
          text = 'Sie haben die Positionierung abgebrochen.';
          break;
        case 2: // POSITION_UNAVAILABLE
          text = 'Die Position kann nicht ermittelt werden.';
          break;
        case 3: // TIMEOUT
          text = 'Die Positionierung dauert zu lange (> ' + TIMEOUT / 1000 + ' Sekunden) und wurde daher abgebrochen.';
          break;
        default:
          text = 'Es ist ein unbekannter Fehler aufgetreten.';
      }

      alert(text);
    }
  };

  const locateMe = () => {
    return new Promise((resolve, reject) => {
      // Für Callback
      const geojson = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: that.map.getView().getProjection().getCode().toUpperCase()
          }
        },
        features: [
          {
            geometry: {
              type: 'Point',
              coordinates: []
            },
            properties: {},
            type: 'Feature'
          }
        ]
      };
      /**
       * Die Geolocation wird zunächst deaktiviert, wenn diese bereits aktiv ist.
       * Die Geolocation wird außerdem nach jeder Adresssuche bzw. bei einem Fehler
       * deaktiviert/zurückgesetzt.
       */
      if (geolocation.getTracking() === true) {
        geolocation.setTracking(false);
      }
      geolocation.setTracking(true);

      geolocation.once('change:position', () => {
        const coords = geolocation.getPosition();

        const feature = new Feature({
          geometry: new Point(coords)
        });

        geolocationSource.addFeature(feature);

        that.map.getView().setCenter(coords);
        that.map.getView().setZoom(that.config.map.zoomlevel);

        geojson.features[0].geometry.coordinates = coords;

        // Callback-Funktion aufrufen
        if (typeof window[that.config.callbacks.ermittleStandort] === 'function') {
          window[that.config.callbacks.ermittleStandort](geojson);
        }
      });

      geolocation.on('error', error => {
        geolocation.setTracking(false);
        reject(error);
      });
    });
  };

  that.map.getLayers().forEach(layer => {
    if ((layer.get('name') !== undefined) & (layer.get('name') === 'Geolocation')) {
      geolocationSource = layer.getSource();
    }
  });

  const geolocationButton = document.createElement('button');
  for (const cls of ['btn', 'btn-sm', 'btn-outline-dark']) {
    geolocationButton.classList.add(cls);
  }
  geolocationButton.setAttribute('type', 'button');
  geolocationButton.setAttribute('title', 'Standort ermitteln');
  geolocationButton.innerHTML = '<i class="bi bi-geo-alt-fill"></i>';

  const wrapper = document.getElementById(domNodeId).querySelector('.itnrwCustomControl');
  wrapper.appendChild(geolocationButton);

  geolocationButton.addEventListener('click', locate);
  geolocation.setProjection(that.map.getView().getProjection().getCode().toUpperCase());
};
export { createGeolocation };
