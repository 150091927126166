// map.nrw
import { DrawFreehandPolygonTool } from './../Tools/DrawFreehandPolygon';
import { DrawLineStringTool } from './../Tools/DrawLineString';
import { DrawPolygonTool } from './../Tools/DrawPolygon';
import { MapExtractByCircleTool } from './../Tools/MapExtractByCircle';
import { MapExtractByRectTool } from './../Tools/MapExtractByRect';
import { NearestAddressTool } from './../Tools/NearestAddress';
import { SetCoordinateTool } from './../Tools/SetCoordinate';

/**
 * @classdesc Erzeugt neue Toolbar Instanz
 * @memberOf Module
 * @since 2.0.0
 */
class Toolbar {
  /**
   * @desc Erstellt und führt das Setup für das div-Element aus
   * @arg {object} params
   * @since 2.0.0
   */
  constructor(params) {
    Object.assign(this, params);

    this._createToolbar();
    this._setupToolbar();
  }

  /**
   * @desc Erstellt das div-Element für die Toolbar
   * @since 2.0.0
   */
  _createToolbar() {
    const mapDiv = document.getElementById(this.domNode.id);

    /**
     * Toolbar erstellen und dem div-Element übergeben
     */
    const toolbarContainer = document.createElement('div');
    for (const cls of ['m-2', 'itnrwToolbar']) {
      toolbarContainer.classList.add(cls);
    }
    toolbarContainer.setAttribute('id', this.domNode.id + '_toolbar');
    mapDiv.appendChild(toolbarContainer);
  }

  /**
   * @desc Fügt die Funktionalität für die Toolbar hinzu
   * @since 2.0.0
   */
  _setupToolbar() {
    this.availTools = [
      'naechsteAdresse',
      'setzeKoordinate',
      'ausschnittRechteck',
      'ausschnittKreis',
      // 'zeichneLinie',
      'zeichnePolygon',
      'zeichneFreihandPolygon'
    ];

    if (this.tools[0] === 'alle') {
      this.tools = this.availTools;
    }

    for (let i = 0; i < this.tools.length; i++) {
      const toolbarDiv = document.getElementById(this.domNode.id + '_toolbar');

      /**
       * Tools erstellen und dem div-Element übergeben
       */
      const toolContainer = document.createElement('div');
      toolContainer.setAttribute('id', this.domNode.id + '_tool' + i);
      toolbarDiv.appendChild(toolContainer);

      if (this.tools[i] === 'naechsteAdresse') {
        this._createNearestAddressTool({
          map: this.map,
          callback: this.callback[this.tools[i]],
          toolId: toolContainer.id,
          interactions: this.interactions
        });
      } else if (this.tools[i] === 'setzeKoordinate') {
        this._createSetCoordinateTool({
          map: this.map,
          callback: this.callback[this.tools[i]],
          toolId: toolContainer.id,
          interactions: this.interactions
        });
      } else if (this.tools[i] === 'ausschnittRechteck') {
        this._createMapExtractByRectTool({
          map: this.map,
          callback: this.callback[this.tools[i]],
          toolId: toolContainer.id
        });
      } else if (this.tools[i] === 'ausschnittKreis') {
        this._createMapExtractByCircleTool({
          map: this.map,
          callback: this.callback[this.tools[i]],
          toolId: toolContainer.id
        });
      } else if (this.tools[i] === 'zeichneLinie') {
        this._createDrawLineStringTool({
          map: this.map,
          callback: this.callback[this.tools[i]],
          toolId: toolContainer.id
        });
      } else if (this.tools[i] === 'zeichnePolygon') {
        this._createDrawPolygonTool({
          map: this.map,
          callback: this.callback[this.tools[i]],
          toolId: toolContainer.id
        });
      } else if (this.tools[i] === 'zeichneFreihandPolygon') {
        this._createDrawFreehandPolygonTool({
          map: this.map,
          callback: this.callback[this.tools[i]],
          toolId: toolContainer.id
        });
      }
    }
  }

  /**
   * @desc Fügt die Funktionalität für die Tool NearestAddressTool hinzu
   * @since 2.0.0
   */
  _createNearestAddressTool(params) {
    this.toolNearAdr = new NearestAddressTool(params);

    const toolDiv = document.getElementById(params.toolId);
    toolDiv.addEventListener(
      'click',
      e => {
        this._NearestAddressToolHandle();
      },
      false
    );
  }

  /**
   * @desc Aktiviert / Deaktiviert die entsprechenden Tool-Funktionalitäten
   * @since 2.0.0
   */
  _NearestAddressToolHandle() {
    if (this.toolSetCoord) {
      this.toolSetCoord.deactivate();
    }
    if (this.toolMapExRect) {
      this.toolMapExRect.deactivate();
    }
    if (this.toolMapExCirc) {
      this.toolMapExCirc.deactivate();
    }
    if (this.toolDrawLineString) {
      this.toolDrawLineString.deactivate();
    }
    if (this.toolDrawPolygon) {
      this.toolDrawPolygon.deactivate();
    }
    if (this.toolDrawFreehandPolygon) {
      this.toolDrawFreehandPolygon.deactivate();
    }
    this.toolNearAdr.activate();
  }

  /**
   * @desc Fügt die Funktionalität für die Tool SetCoordinateTool hinzu
   * @since 2.0.0
   */
  _createSetCoordinateTool(params) {
    this.toolSetCoord = new SetCoordinateTool(params);

    const toolDiv = document.getElementById(params.toolId);
    toolDiv.addEventListener(
      'click',
      e => {
        this._SetCoordinateToolHandle();
      },
      false
    );
  }

  /**
   * @desc Aktiviert / Deaktiviert die entsprechenden Tool-Funktionalitäten
   * @since 2.0.0
   */
  _SetCoordinateToolHandle() {
    if (this.toolNearAdr) {
      this.toolNearAdr.deactivate();
    }
    if (this.toolMapExRect) {
      this.toolMapExRect.deactivate();
    }
    if (this.toolMapExCirc) {
      this.toolMapExCirc.deactivate();
    }
    if (this.toolDrawLineString) {
      this.toolDrawLineString.deactivate();
    }
    if (this.toolDrawPolygon) {
      this.toolDrawPolygon.deactivate();
    }
    if (this.toolDrawFreehandPolygon) {
      this.toolDrawFreehandPolygon.deactivate();
    }
    this.toolSetCoord.activate();
  }

  /**
   * @desc Fügt die Funktionalität für die Tool MapExtractByRectTool hinzu
   * @since 2.0.0
   */
  _createMapExtractByRectTool(params) {
    this.toolMapExRect = new MapExtractByRectTool(params);

    const toolDiv = document.getElementById(params.toolId);
    toolDiv.addEventListener(
      'click',
      e => {
        this._MapExtractByRectToolHandle();
      },
      false
    );
  }

  /**
   * @desc Aktiviert / Deaktiviert die entsprechenden Tool-Funktionalitäten
   * @since 2.0.0
   */
  _MapExtractByRectToolHandle() {
    if (this.toolNearAdr) {
      this.toolNearAdr.deactivate();
    }
    if (this.toolSetCoord) {
      this.toolSetCoord.deactivate();
    }
    if (this.toolMapExCirc) {
      this.toolMapExCirc.deactivate();
    }
    if (this.toolDrawLineString) {
      this.toolDrawLineString.deactivate();
    }
    if (this.toolDrawPolygon) {
      this.toolDrawPolygon.deactivate();
    }
    if (this.toolDrawFreehandPolygon) {
      this.toolDrawFreehandPolygon.deactivate();
    }
    this.toolMapExRect.activate();
  }

  /**
   * @desc Fügt die Funktionalität für die Tool MapExtractByCircleTool hinzu
   * @since 2.0.0
   */
  _createMapExtractByCircleTool(params) {
    this.toolMapExCirc = new MapExtractByCircleTool(params);

    const toolDiv = document.getElementById(params.toolId);
    toolDiv.addEventListener(
      'click',
      e => {
        this._MapExtractByCircleToolHandle();
      },
      false
    );
  }

  /**
   * @desc Aktiviert / Deaktiviert die entsprechenden Tool-Funktionalitäten
   * @since 2.0.0
   */
  _MapExtractByCircleToolHandle() {
    if (this.toolNearAdr) {
      this.toolNearAdr.deactivate();
    }
    if (this.toolSetCoord) {
      this.toolSetCoord.deactivate();
    }
    if (this.toolMapExRect) {
      this.toolMapExRect.deactivate();
    }
    if (this.toolDrawLineString) {
      this.toolDrawLineString.deactivate();
    }
    if (this.toolDrawPolygon) {
      this.toolDrawPolygon.deactivate();
    }
    if (this.toolDrawFreehandPolygon) {
      this.toolDrawFreehandPolygon.deactivate();
    }
    this.toolMapExCirc.activate();
  }

  /**
   * @desc Fügt die Funktionalität für die Tool DrawLineStringTool hinzu
   * @since 2.0.0
   */
  _createDrawLineStringTool(params) {
    this.toolDrawLineString = new DrawLineStringTool(params);

    const toolDiv = document.getElementById(params.toolId);
    toolDiv.addEventListener(
      'click',
      e => {
        this._DrawLineStringToolHandle();
      },
      false
    );
  }

  /**
   * @desc Aktiviert / Deaktiviert die entsprechenden Tool-Funktionalitäten
   * @since 2.0.0
   */
  _DrawLineStringToolHandle() {
    if (this.toolNearAdr) {
      this.toolNearAdr.deactivate();
    }
    if (this.toolSetCoord) {
      this.toolSetCoord.deactivate();
    }
    if (this.toolMapExRect) {
      this.toolMapExRect.deactivate();
    }
    if (this.toolMapExCirc) {
      this.toolMapExCirc.deactivate();
    }
    if (this.toolDrawFreehandPolygon) {
      this.toolDrawFreehandPolygon.deactivate();
    }
    if (this.toolDrawPolygon) {
      this.toolDrawPolygon.deactivate();
    }
    this.toolDrawLineString.activate();
  }

  /**
   * @desc Fügt die Funktionalität für die Tool DrawPolygonTool hinzu
   * @since 2.0.0
   */
  _createDrawPolygonTool(params) {
    this.toolDrawPolygon = new DrawPolygonTool(params);

    const toolDiv = document.getElementById(params.toolId);
    toolDiv.addEventListener(
      'click',
      e => {
        this._DrawPolygonToolHandle();
      },
      false
    );
  }

  /**
   * @desc Aktiviert / Deaktiviert die entsprechenden Tool-Funktionalitäten
   * @since 2.0.0
   */
  _DrawPolygonToolHandle() {
    if (this.toolNearAdr) {
      this.toolNearAdr.deactivate();
    }
    if (this.toolSetCoord) {
      this.toolSetCoord.deactivate();
    }
    if (this.toolMapExRect) {
      this.toolMapExRect.deactivate();
    }
    if (this.toolMapExCirc) {
      this.toolMapExCirc.deactivate();
    }
    if (this.toolDrawFreehandPolygon) {
      this.toolDrawFreehandPolygon.deactivate();
    }
    if (this.toolDrawLineString) {
      this.toolDrawLineString.deactivate();
    }
    this.toolDrawPolygon.activate();
  }

  /**
   * @desc Fügt die Funktionalität für die Tool DrawFreehandPolygonTool hinzu
   * @since 2.0.0
   */
  _createDrawFreehandPolygonTool(params) {
    this.toolDrawFreehandPolygon = new DrawFreehandPolygonTool(params);

    const toolDiv = document.getElementById(params.toolId);
    toolDiv.addEventListener(
      'click',
      e => {
        this._DrawFreehandPolygonToolHandle();
      },
      false
    );
  }

  /**
   * @desc Aktiviert / Deaktiviert die entsprechenden Tool-Funktionalitäten
   * @since 2.0.0
   */
  _DrawFreehandPolygonToolHandle() {
    if (this.toolNearAdr) {
      this.toolNearAdr.deactivate();
    }
    if (this.toolSetCoord) {
      this.toolSetCoord.deactivate();
    }
    if (this.toolMapExRect) {
      this.toolMapExRect.deactivate();
    }
    if (this.toolMapExCirc) {
      this.toolMapExCirc.deactivate();
    }
    if (this.toolDrawLineString) {
      this.toolDrawLineString.deactivate();
    }
    if (this.toolDrawPolygon) {
      this.toolDrawPolygon.deactivate();
    }
    this.toolDrawFreehandPolygon.activate();
  }
}

export { Toolbar };
