// Bootstrap
import 'bootstrap-icons/font/bootstrap-icons.css';
import './itnrw/styles/scss/map.nrw.scss';

import { InitMap } from './itnrw/js/Map';

/**
 * Ermittle, von welcher Umgebung die Anwendung aufgerufen wird
 */
let env;

if (window.location.host === 'lv.gis-rest.nrw.de') {
  env = 'lvn';
} else if (window.location.host === 'lv.kommunen.nrw.doi-de.net') {
  env = 'doi';
} else if (window.location.host === 'lv.kommunen.nrw.testa-de.net') {
  env = 'testa';
}

window.itnrwConfig = {
  ENV: env,
  MARKER_SIZE: 20
};

/**
 * EventListener: 'itnrw:reload-maps'
 * Erzwingt das Event 'load' zum erneutem Laden der Konfiguration
 *
 * Ausgelöst werden kann das Event durch:
 * window.dispatchEvent(new CustomEvent('itnrw:reload-maps'));
 */
window.addEventListener('itnrw:reload-maps', () => {
  setTimeout(() => {
    window.dispatchEvent(new Event('load'));
  }, 250);
});

/**
 * EventListener: 'load'
 * Wenn die Website fertig geladen wurde, wird jeder Inhalt eines
 * konfigurierten Kartenelementes gelöscht.
 * Anschließend wird für jedes konfigurierte Kartenelement eine
 * neue Instanz erstellt.
 */
window.addEventListener('load', () => {
  [].forEach.call(document.getElementsByClassName('itnrwMap'), mapDiv => {
    mapDiv.innerHTML = '';

    window.itnrwMaps = [];

    const timeout = mapDiv.getAttribute('data-itnrw-timeout') || 0;
    setTimeout(() => {
      window.itnrwMaps.push(new InitMap(mapDiv));
    }, timeout);
  });
});
