/**
 * VERARBEITUNG FOLGENDER ATTRIBUTE
 *
 * data-itnrw-addresses="[kennedydamm 15, düsseldorf; tooltip; content; label; imageUrl_width_height.png|jpg], [..]"
 * data-itnrw-flurstuecke="[Gemeinde,Gemarkungsname, Flur,Flurstücksnummer; TooltipText; InfoFensterInhalt; Punktbeschriftung; alternativesSymbol], [..]"
 * data-itnrw-coords="[epsg:code; x, y; tooltip; content; label; imageUrl_width_height.png|jpg], [..]"
 *
 * data-itnrw-cluster="visible:true, distance:100"
 *  -> visible: true|false
 *  -> distance: Pixel (Integerwert)
 *
 * data-itnrw-map="width:300px, height:200px, basemap:dop, zoomlevel:12, zoomrestriction:true, fullscreen:true, geolocation:true, epsg:3035"
 *  -> basemaps: "alle" || "topplus_open_col, topplus_open, dtk, dtk_sw, dop, dop_overlay, none"
 *  -> zoomlevel: 5-20
 *  -> zoomrestriction: true|false
 *  -> fullscreen: true|false
 *  -> geolocation: true|false
 *  -> epsg: 25832|25833|4258|3857|3035|3034|3044|3045|31466|31467|4326
 *
 * data-itnrw-popup="width: 300px, height: 150px, maximize:true"
 *
 * data-itnrw-layers="[ags; serviceUrl; layerName; opacity; singleTile], [wms; serviceUrl; layerName; version; opacity; featureInfo; singleTile], [wmts; serviceUrl; layerName; opacity]"
 *  -> ags: serviceUrl, layerName -> kommagetrennt mehrere Ids (optional, sonst kompletter Service genutzt); opacity (optional); singleTile (optional) -> default true
 *  -> wms: serviceUrl, layerName -> kommagetrennt mehrere Namen (muss); version (optional) -> default 1.3.0; opacity (optional); featureInfo -> in Abhängigkeit der def. Layer z.B. (3 Layer): true,false,true; singleTile (optional) -> default true
 *  -> wmts: serviceUrl, layerName -> ein Name (muss); opacity (optional)
 *
 * data-itnrw-extent="x_lu, y_lu, x_ro, y_ro"
 *
 * data-itnrw-geometries="[circle; centerX, centerY; radius], [extent; x_lu, y_lu, x_ro, y_ro], [polygon; x1, y1; x2, y2; x3, y3; ...; x1, y1]"
 *  -> Optionen: circle, extent, polygon
 *
 * data-itnrw-module-search="alle" || "adressen, flurstuecke, verwaltungsgebiete"
 * data-itnrw-module-coords="alle" || "epsg:25832, epsg:25833, epsg:31466, epsg:31467, epsg:4326"
 * data-itnrw-module-toggler="alle" || "topplus_open_col, topplus_open, dtk, dtk_sw, dop, dop_overlay, none"
 * data-itnrw-module-tools="alle" || "naechsteAdresse, setzeKoordinate, ausschnittRechteck, ausschnittKreis, zeichneLinie, zeichnePolygon, zeichneFreihandPolygon"
 */

// OpenLayers
import { transform, transformExtent } from 'ol/proj';
import { proj4 } from './Projection';

/**
 * @desc Verarbeitung von [Attributen]{@link parseAttributes} und [Modulen]{@link parseModules}, welche konfiguriert worden sind
 * @since 2.0.0
 */
const Parser = {
  /**
   * @func parseAttributes
   * @desc Parsen der folgenden Attribute: "data-itnrw-addresses", "data-itnrw-coords", "data-itnrw-flurstuecke", "data-itnrw-layers", "data-itnrw-extents", "data-itnrw-map", "data-itnrw-geometries", "data-itnrw-popup", "data-itnrw-module-search", "data-itnrw-module-coords", "data-itnrw-module-tools", "data-itnrw-module-toggler"
   * @arg {object} domNode
   * @since 2.0.0
   * @returns {object} Konfiguration mit Default-Werten
   */
  parseAttributes: domNode => {
    /**
     * @desc Default Konfiguration zum Erstellen der Karte
     * @typedef {object} configAttr
     * @prop {array} addresses - Array zum Speichern der konfigurierten Adressen
     * @prop {array} coords - Array zum Speichern der konfigurierten Koordinaten
     * @prop {array} flurstuecke - Array zum Speichern der konfigurierten Flurstücke
     * @prop {array} layers - Array zum Speichern der konfigurierten Dienste / Layer (WMS, WMTS, ArcGIS)
     * @prop {array} extent - Array zum Speichern des konfigurierten Kartenausschnittes
     * @prop {object} cluster - Objekt zum Speichern von Inhalten für die Clusterdarstellung
     * @prop {boolean} cluster.visible - Konfiguriertes Clustering der Karte
     * @prop {number} cluster.distance - Konfigurierte Distanz zwischen den POI in der Karte (in Pixel)
     * @prop {object} map - Objekt zum Speichern von Inhalten für die Kartendarstellung
     * @prop {string} map.width - Konfigurierte Breite der Karte
     * @prop {string} map.height - Konfigurierte Höhe der Karte
     * @prop {string} map.basemap - Konfigurierte Hintergrundkarte
     * @prop {number} map.zoomlevel - Konfigurierte Zoomstufe der Karte
     * @prop {boolean} map.zoomrestriction - Konfigurierte Einschränkung für Zoom (Drag) der Karte
     * @prop {boolean} map.fullscreen - Konfigurierter Fullscreen Button
     * @prop {boolean} map.geolocation - Konfigurierter Geolocation Button
     * @prop {string} map.crs - Konfigurierte Projektion der Karte
     * @prop {array} map.center - Konfigurierter Kartenmittelpunkt
     * @prop {number} map.minZoom - Konfigurierte min. Zoomstufe
     * @prop {number} map.maxZoom - Konfigurierte max. Zoomstufe
     * @prop {array} map.initExtent - Initialer Kartenausschnitt
     * @prop {array} map.restrictedExtent - Konfigurierter Kartenausschnitt
     * @prop {array} geometries - Array zum Speichern der konfigurierten Geometrien
     * @prop {object} popup - Objekt zum Speichern von Höhe und Breite des Popups
     * @prop {array} popup.width - Konfigurierte Breite des Popups
     * @prop {array} popup.height - Konfigurierte Höhe des Popups
     * @prop {boolean} popup.maximize - Boolean-Wert, ob Popop initial vergrößert geöffnet wird
     * @prop {object} modules - Objekt zum Speichern von konfigurierten Modulen
     * @prop {array} modules.search - Array zum Speichern des konfigurierten Moduls: search
     * @prop {array} modules.coords - Array zum Speichern des konfigurierten Moduls: coords
     * @prop {array} modules.tools - Array zum Speichern des konfigurierten Moduls: tools
     * @prop {array} modules.toggler - Array zum Speichern des konfigurierten Moduls: toggler
     * @prop {object} callbacks - Objekt zum Speichern von konfigurierten Callback-Funktionen
     * @prop {null} callbacks.search - Platzhalter zum Speichern der Callback-Funktion: search
     * @prop {null} callbacks.coords - Platzhalter zum Speichern der Callback-Funktion: coords
     * @prop {null} callbacks.naechsteAdresse - Platzhalter zum Speichern der Callback-Funktion: naechsteAdresse
     * @prop {null} callbacks.setzeKoordinate - Platzhalter zum Speichern der Callback-Funktion: setzeKoordinate
     * @prop {null} callbacks.ausschnittRechteck - Platzhalter zum Speichern der Callback-Funktion: ausschnittRechteck
     * @prop {null} callbacks.ausschnittKreis - Platzhalter zum Speichern der Callback-Funktion: ausschnittKreis
     * @prop {null} callbacks.zeichneLinie - Platzhalter zum Speichern der Callback-Funktion: zeichneLinie
     * @prop {null} callbacks.zeichnePolygon - Platzhalter zum Speichern der Callback-Funktion: zeichnePolygon
     * @prop {null} callbacks.zeichneFreihandPolygon - Platzhalter zum Speichern der Callback-Funktion: zeichneFreihandPolygon
     * @prop {null} callbacks.ermittleStandort - Platzhalter zum Speichern der Callback-Funktion: ermittleStandort
     * @since 2.0.0
     */
    const configAttr = {
      addresses: [],
      coords: [],
      flurstuecke: [],
      layers: [],
      extent: [],
      cluster: {
        visible: false,
        distance: 100
      },
      map: {
        width: '350px',
        height: '250px',
        basemap: 'topplus_open_col',
        zoomlevel: 19,
        zoomrestriction: false,
        fullscreen: false,
        geolocation: false,
        crs: 'EPSG:25832',
        /**
         * Mittelpunkt von Deutschland
         * Quelle: https://www.bkg.bund.de/SharedDocs/Downloads/BKG/DE/Downloads-DE-Flyer/BKG-D-kreuz-quer.pdf?__blob=publicationFile&v=4
         *
         * Der Mittelpunkt wird zur Positionierung der Karte beim initialen Aufruf benötigt. OpenLayers erwartet einen Mittelpunkt für ol.View.
         * Hat der Nutzer einen Extent konfiguriert, so wird auf diesen gezommt, ansonsten wird auf NRW gezoomt.
         */
        center: [596251, 5656866],
        minZoom: 5,
        maxZoom: 20,
        initExtent: [278000, 5572000, 538000, 5824000], // NRW-Ausdehnung in UTM32
        restrictedExtent: [280000, 5235000, 923000, 6103000] // Deutschland-Ausdehnung in UTM32
      },
      geometries: [],
      popup: {
        width: '200px',
        height: '150px',
        maximize: false
      },
      modules: {
        search: [],
        coords: [],
        tools: [],
        toggler: []
      },
      callbacks: {
        search: null,
        coords: null,
        naechsteAdresse: null,
        setzeKoordinate: null,
        ausschnittRechteck: null,
        ausschnittKreis: null,
        zeichneLinie: null,
        zeichnePolygon: null,
        zeichneFreihandPolygon: null,
        ermittleStandort: null
      }
    };

    // Adressen-Attribut parsen
    const addrString = domNode.getAttribute('data-itnrw-addresses') || '';
    const addrArr = addrString.split(']');
    for (let i = 0; i < addrArr.length; i++) {
      addrArr[i] = addrArr[i].replace(/^,?\s*\[/, '');
      if (addrArr[i].length > 0) {
        const addrElems = addrArr[i].split(';');
        const obj = {
          address: null,
          tooltip: null,
          content: null,
          label: null,
          image: null
        };
        for (let j = 0; j < addrElems.length; j++) {
          addrElems[j] = addrElems[j].trim();
          if (addrElems[j].length > 0) {
            if (j === 0) {
              obj.address = addrElems[j];
            }
            if (j === 1) {
              obj.tooltip = addrElems[j];
            }
            if (j === 2) {
              obj.content = addrElems[j];
            }
            if (j === 3) {
              obj.label = addrElems[j];
            }
            if (j === 4) {
              obj.image = addrElems[j];
            }
          }
        }
        if (obj.address) {
          configAttr.addresses.push(obj);
        }
      }
    }

    // Koordinaten-Attribut parsen
    const coordString = domNode.getAttribute('data-itnrw-coords') || '';
    const coordArr = coordString.split(']');
    for (let i = 0; i < coordArr.length; i++) {
      coordArr[i] = coordArr[i].replace(/^,?\s*\[/, '');
      if (coordArr[i].length > 0) {
        const coordElems = coordArr[i].split(';');
        const obj = {
          crs: null,
          x: null,
          y: null,
          tooltip: null,
          content: null,
          label: null,
          image: null
        };
        for (let j = 0; j < coordElems.length; j++) {
          coordElems[j] = coordElems[j].trim();
          if (coordElems[j].length > 0) {
            if (j === 0) {
              /**
               * OpenLayers erwartet das Referenzsystem mit Großbuchstaben.
               * Daher muss, um die Schnittstelle zwischen den vorherigen Version
               * beizubehalten, das Referenzsystem in Großbuchstaben umgewandelt
               * zurückgegeben werden:
               *
               * -> Input: 'epsg:25832'
               * -> Output: 'EPSG:25832'
               */

              const crs = coordElems[j].toUpperCase();
              try {
                proj4(crs);
                obj.crs = crs;
              } catch (error) {
                obj.crs = null;
                console.warn('Eine Transformation der Koordinaten mit dem Referenzsystem', crs, 'ist nicht möglich, da dieses in der map.nrw nicht konfiguriert ist.');
              }
            }
            if (j === 1) {
              const coords = coordElems[j].split(',');
              if (coords.length === 2) {
                obj.x = parseFloat(coords[0].trim());
                obj.y = parseFloat(coords[1].trim());
              }
            }
            if (j === 2) {
              obj.tooltip = coordElems[j];
            }
            if (j === 3) {
              obj.content = coordElems[j];
            }
            if (j === 4) {
              obj.label = coordElems[j];
            }
            if (j === 5) {
              obj.image = coordElems[j];
            }
          }
        }
        if (obj.x && obj.y) {
          configAttr.coords.push(obj);
        }
      }
    }

    // Flurstück-Attribut parsen
    const flurString = domNode.getAttribute('data-itnrw-flurstuecke') || '';
    const flurArr = flurString.split(']');
    for (let i = 0; i < flurArr.length; i++) {
      flurArr[i] = flurArr[i].replace(/^,?\s*\[/, '');
      if (flurArr[i].length > 0) {
        const flurElems = flurArr[i].split(';');
        const obj = {
          flurstueck: null,
          tooltip: null,
          content: null,
          label: null,
          image: null
        };
        for (let j = 0; j < flurElems.length; j++) {
          flurElems[j] = flurElems[j].trim();
          if (flurElems[j].length > 0) {
            if (j === 0) {
              obj.flurstueck = flurElems[j];
            }
            if (j === 1) {
              obj.tooltip = flurElems[j];
            }
            if (j === 2) {
              obj.content = flurElems[j];
            }
            if (j === 3) {
              obj.label = flurElems[j];
            }
            if (j === 4) {
              obj.image = flurElems[j];
            }
          }
        }
        if (obj.flurstueck) {
          configAttr.flurstuecke.push(obj);
        }
      }
    }

    // Layers-Attribut parsen
    const layerString = domNode.getAttribute('data-itnrw-layers') || '';
    const layerArr = layerString.split(']');
    for (let i = 0; i < layerArr.length; i++) {
      layerArr[i] = layerArr[i].replace(/^,?\s*\[/, '');
      if (layerArr[i].length > 0) {
        const layerElems = layerArr[i].split(';');
        let type = '';
        const ags = {
          type: 'ags',
          service: null,
          layer: [],
          opacity: 1.0,
          singleTile: true
        };
        const wms = {
          type: 'wms',
          service: null,
          layer: [],
          version: '1.3.0',
          opacity: 1.0,
          featureInfo: null,
          singleTile: true
        };
        const wmts = {
          type: 'wmts',
          service: null,
          layer: [],
          version: '1.0.0',
          opacity: 1.0
        };
        for (let j = 0; j < layerElems.length; j++) {
          layerElems[j] = layerElems[j].trim();
          if (layerElems[j].length > 0) {
            if (j === 0) {
              if (layerElems[0] === 'ags') {
                type = 'ags';
              } else if (layerElems[0] === 'wms') {
                type = 'wms';
              } else if (layerElems[0] === 'wmts') {
                type = 'wmts';
              }
            }
            if (j > 0 && type === 'ags') {
              // ArcGIS DynamicMapService
              if (j === 1) {
                ags.service = layerElems[j];
              }
              if (j === 2) {
                ags.layer = layerElems[j].split(',');
                for (let k = 0; k < ags.layer.length; k++) {
                  ags.layer[k] = parseInt(ags.layer[k].trim());
                }
              }
              if (j === 3 && layerElems[j] !== null) {
                ags.opacity = parseFloat(layerElems[j]);
              }
              if (j === 4) {
                ags.singleTile = layerElems[j].toLowerCase() === 'true';
              }
            } else if (j > 0 && type === 'wms') {
              // WMS Service
              if (j === 1) {
                wms.service = layerElems[j];
              }
              if (j === 2) {
                wms.layer = layerElems[j].split(',');
                for (let k = 0; k < wms.layer.length; k++) {
                  wms.layer[k] = wms.layer[k].trim();
                }
              }
              if (j === 3 && layerElems[j] !== null) {
                wms.version = layerElems[j];
              }
              if (j === 4 && layerElems[j] !== null) {
                wms.opacity = parseFloat(layerElems[j]);
              }
              if (j === 5) {
                wms.featureInfo = layerElems[j].split(',');
                for (let k = 0; k < wms.featureInfo.length; k++) {
                  if (wms.featureInfo[k].trim() === 'true') {
                    wms.featureInfo[k] = true;
                  } else {
                    wms.featureInfo[k] = false;
                  }
                }
              }
              if (j === 6) {
                wms.singleTile = layerElems[j].toLowerCase() === 'true';
              }
            } else if (j > 0 && type === 'wmts') {
              // WMTS Service
              if (j === 1) {
                wmts.service = layerElems[j];
              }
              if (j === 2) {
                wmts.layer = layerElems[j].split(',');
                // Löschen aller Einträge nach dem ersten Array-Eintrag mit splice(1)
                wmts.layer.splice(1);
                for (let k = 0; k < wmts.layer.length; k++) {
                  wmts.layer[k] = wmts.layer[k].trim();
                }
              }
              if (j === 3 && layerElems[j] !== null) {
                wmts.opacity = parseFloat(layerElems[j]);
              }
            }
          }
        }
        if (type === 'ags' && ags.service !== null) {
          configAttr.layers.push(ags);
        } else if (type === 'wms' && wms.service !== null && wms.layer !== null && wms.layer.length !== 0) {
          configAttr.layers.push(wms);
        } else if (type === 'wmts' && wmts.service !== null && wmts.layer !== null && wmts.layer.length === 1) {
          configAttr.layers.push(wmts);
        }
      }
    }

    // Extent-Attribut parsen
    const extentString = domNode.getAttribute('data-itnrw-extent') || '';
    const extentArr = extentString.split(',');
    if (extentArr.length === 4) {
      for (let i = 0; i < extentArr.length; i++) {
        extentArr[i] = parseFloat(extentArr[i].trim());
      }
      configAttr.extent = extentArr;
    } else {
      configAttr.extent = null;
    }

    // Cluster-Attribut parsen
    const clusterString = domNode.getAttribute('data-itnrw-cluster') || '';
    const clusterArr = clusterString.split(',');
    for (let i = 0; i < clusterArr.length; i++) {
      const kvp = clusterArr[i].split(':');
      if (kvp.length === 2) {
        kvp[0] = kvp[0].trim();
        kvp[1] = kvp[1].trim();

        if (kvp[0] === 'visible') {
          /**
           * Clusterangabe ist als String und soll als Boolean weiterverarbeitet werden,
           * daher Prüfung auf 'true|false'
           */
          if (kvp[1] === 'true' || kvp[1] === 'false') {
            configAttr.cluster[kvp[0]] = kvp[1] === 'true';
          }
        } else if (kvp[0] === 'distance') {
          configAttr.cluster[kvp[0]] = parseInt(kvp[1]);
        }
      }
    }

    // Map-Attribut parsen
    const mapConfString = domNode.getAttribute('data-itnrw-map') || '';
    const mapConfArr = mapConfString.split(',');
    for (let i = 0; i < mapConfArr.length; i++) {
      const kvp = mapConfArr[i].split(':');
      if (kvp.length === 2) {
        kvp[0] = kvp[0].trim();
        kvp[1] = kvp[1].trim();

        if (kvp[0].toUpperCase() === 'EPSG') {
          configAttr.map.crs = kvp[0].toUpperCase() + ':' + kvp[1];

          if (configAttr.map.crs !== 'EPSG:25832') {
            configAttr.map.center = transform(configAttr.map.center, 'EPSG:25832', configAttr.map.crs);
            configAttr.map.initExtent = transformExtent(configAttr.map.initExtent, 'EPSG:25832', configAttr.map.crs);
            configAttr.map.restrictedExtent = transformExtent(
              configAttr.map.restrictedExtent,
              'EPSG:25832',
              configAttr.map.crs
            );
          }
        }

        if (Object.prototype.hasOwnProperty.call(configAttr.map, kvp[0]) && kvp[1].length > 0) {
          kvp[1] = kvp[1].replace(/'/g, '');
          if (kvp[0] === 'zoomlevel' || kvp[0] === 'timeout') {
            configAttr.map[kvp[0]] = parseInt(kvp[1]);
          } else if (kvp[0] === 'zoomrestriction' || kvp[0] === 'fullscreen' || kvp[0] === 'geolocation') {
            /**
             * Einschränkung (Zoom, Drag) ist als String und soll als Boolean weiterverarbeitet werden,
             * daher Prüfung auf 'true|false'
             */
            if (kvp[1] === 'true' || kvp[1] === 'false') {
              configAttr.map[kvp[0]] = kvp[1] === 'true';
            }
          } else {
            configAttr.map[kvp[0]] = kvp[1];
          }
        }
      }
    }

    // Geometries-Attribut parsen
    const geomString = domNode.getAttribute('data-itnrw-geometries') || '';
    const geomArr = geomString.split(']');
    for (let i = 0; i < geomArr.length; i++) {
      geomArr[i] = geomArr[i].replace(/^,?\s*\[/, '');
      if (geomArr[i].length > 0) {
        const geomElems = geomArr[i].split(';');
        if (geomElems[0] === 'circle') {
          // Geometrietyp = "circle"
          const obj = {
            type: 'circle',
            x: null,
            y: null,
            radius: 0.0
          };
          if (geomElems.length === 3) {
            const xy = geomElems[1].split(',');
            obj.x = parseFloat(xy[0]);
            obj.y = parseFloat(xy[1]);
            obj.radius = parseFloat(geomElems[2]);
          }
          if (obj.x && obj.y && obj.radius > 0.0) {
            configAttr.geometries.push(obj);
          }
        } else if (geomElems[0] === 'extent') {
          // Geometrietyp = "extent"
          const obj = {
            type: 'extent',
            extent: []
          };
          if (geomElems[1] !== null) {
            const extentElems = geomElems[1].split(',');
            const extArr = [];
            for (let j = 0; j < extentElems.length; j++) {
              extArr.push(parseFloat(extentElems[j]));
            }
            obj.extent = extArr;
          }
          if (obj.extent.length === 4) {
            configAttr.geometries.push(obj);
          }
        } else if (geomElems[0] === 'polygon') {
          // Geometrietyp = "polygon"
          const obj = {
            type: 'polygon',
            ring: []
          };
          const ringArr = [];
          if (geomElems.length >= 5) {
            // type, dreieck (p1,p2,p3,p1) = 5 = min
            for (let j = 1; j < geomElems.length; j++) {
              // type auslassen, daher j=1
              const xy = geomElems[j].split(',');
              const xyArr = [];
              if (xy.length === 2) {
                xyArr.push(parseFloat(xy[0]));
                xyArr.push(parseFloat(xy[1]));
              }
              ringArr.push(xyArr);
            }
          }
          if (ringArr.length >= 4) {
            obj.ring = ringArr;
            configAttr.geometries.push(obj);
          }
        } else if (geomElems[0] === 'linestring') {
          // Geometrietyp = "linestring"
          const obj = {
            type: 'linestring',
            linestring: []
          };
          const linestringArr = [];
          if (geomElems.length >= 3) {
            for (let j = 1; j < geomElems.length; j++) {
              // type auslassen, daher j=1
              const xy = geomElems[j].split(',');
              const xyArr = [];
              if (xy.length === 2) {
                xyArr.push(parseFloat(xy[0]));
                xyArr.push(parseFloat(xy[1]));
              }
              linestringArr.push(xyArr);
            }
          }
          if (linestringArr.length >= 2) {
            obj.linestring = linestringArr;
            configAttr.geometries.push(obj);
          }
        }
      }
    }

    // Popup-Attribut parsen
    const popupString = domNode.getAttribute('data-itnrw-popup') || '';
    const popupArr = popupString.split(',');
    for (let i = 0; i < popupArr.length; i++) {
      const kvp = popupArr[i].split(':');

      if (kvp.length === 2) {
        kvp[0] = kvp[0].trim();
        kvp[1] = kvp[1].trim();

        if (kvp[0] === 'maximize') {
          /**
           * Maximize-Wert ist als String und soll als Boolean weiterverarbeitet werden,
           * daher Prüfung auf 'true|false'
           */
          if (kvp[1] === 'true' || kvp[1] === 'false') {
            configAttr.popup[kvp[0]] = kvp[1] === 'true';
          }
        } else if (Object.prototype.hasOwnProperty.call(configAttr.popup, kvp[0]) && kvp[1].length > 0) {
          kvp[1] = kvp[1].replace(/'/g, '');

          // INFO: Ausgabe der Höhe und Breite OHNE Angabe 'px'
          if (kvp[1].indexOf('px') > -1) {
            configAttr.popup[kvp[0]] = parseInt(kvp[1].replace('px', ''));
          } else {
            configAttr.popup[kvp[0]] = parseInt(kvp[1]);
          }

          // INFO: Ausgabe der Höhe und Breite MIT Angabe 'px'
          configAttr.popup[kvp[0]] = kvp[1];
        }
      }
    }

    // Callback-Attribut parsen
    const callbackString = domNode.getAttribute('data-itnrw-callbacks') || '';
    const callbackArr = callbackString.split(',');

    for (let i = 0; i < callbackArr.length; i++) {
      const kvp = callbackArr[i].split(':');

      if (kvp.length === 2) {
        kvp[0] = kvp[0].trim();
        kvp[1] = kvp[1].trim().replace('()', '');

        if (Object.prototype.hasOwnProperty.call(configAttr.callbacks, kvp[0]) && kvp[1].length > 0) {
          configAttr.callbacks[kvp[0]] = kvp[1];
        }
      }
    }

    // Module -> search, coords, tools, toggler) parsen
    configAttr.modules.search = Parser.parseModules('data-itnrw-module-search', domNode);
    configAttr.modules.coords = Parser.parseModules('data-itnrw-module-coords', domNode);
    configAttr.modules.tools = Parser.parseModules('data-itnrw-module-tools', domNode);

    configAttr.modules.toggler = Parser.parseModules('data-itnrw-module-toggler', domNode);

    return configAttr;
  },

  /**
   * @func parseModules
   * @desc Weiterverarbeitung der geparsten Attribute: "data-itnrw-module-search", "data-itnrw-module-coords", "data-itnrw-module-tools", "data-itnrw-module-toggler"
   * @arg {string} dataAttrName
   * @arg {object} domNode
   * @since 2.0.0
   * @returns {object}
   */
  parseModules(dataAttrName, domNode) {
    const attrString = domNode.getAttribute(dataAttrName) || '';
    const attrArr = attrString.split(',');
    const obj = [];
    for (let i = 0; i < attrArr.length; i++) {
      attrArr[i] = attrArr[i].trim();
      if (attrArr[i].length > 0) {
        obj.push(attrArr[i]);
      }
    }
    return obj;
  }
};

export { Parser };
