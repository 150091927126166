import TileGrid from 'ol/tilegrid/TileGrid';
import { get as getProjection } from 'ol/proj';
import { getWidth } from 'ol/extent';

/**
 * @func createTileGrid
 * @desc Erstellt TileGrid
 * @arg {object} map
 * @memberOf module:Helper
 * @since 2.0.0
 * @returns {number} Zoomlevel
 */
const createTileGrid = map => {
  const extent = map.getView().get('extent');
  const projExtent = getProjection(extent);
  const startResolution = getWidth(projExtent) / 256;
  const resolutions = new Array(22);
  for (let i = 0, ii = resolutions.length; i < ii; ++i) {
    resolutions[i] = startResolution / Math.pow(2, i);
  }
  return new TileGrid({
    extent,
    resolutions,
    tileSize: [512, 512]
  });
};

export { createTileGrid };
