// OpenLayers
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

// map.nrw
import { ToolsCircleStyle, ToolsMarkerStyle, ToolsLineStringStyle, ToolsPolygonStyle } from './../Styles';

/**
 * @classdesc Erzeuge neuen Tools Layer und deren Funktionalität
 * @memberOf Layer
 * @since 2.0.0
 */
class ToolsLayer {
  /**
   * @desc Erzeugt eine neue OpenLayers Source vom Type Vektor
   * @arg {object} map - Objekt mit domNode, Konfiguration, usw.
   * @since 2.0.0
   */
  constructor(map) {
    Object.assign(this, map);

    this.tools = {
      marker: null,
      polygon: null
    };

    this.add('Marker');
    this.add('LineString');
    this.add('Polygon');
    this.add('GroupLayer');
    this.map.addLayer(this.group);

    return this.tools;
  }

  /**
   * @desc Fügt OpenLayers Gruppen Layer, sowie Layer für den Marker und Poylgone hinzu
   * @arg {string} type
   * @since 2.0.0
   */
  add(type) {
    if (type === 'Marker') {
      const source = new VectorSource({
        features: []
      });

      this.tools.marker = new VectorLayer({
        source: source,
        zIndex: 8001,
        name: 'Tools_Marker',
        style: feature => {
          let style;

          if (feature.getGeometry().getType() === 'Point') {
            style = ToolsMarkerStyle(feature);
          }

          return style;
        }
      });
    } else if (type === 'LineString') {
      const source = new VectorSource({
        features: []
      });

      this.tools.linestring = new VectorLayer({
        source: source,
        zIndex: 8000,
        name: 'Tools_LineString',
        style: feature => {
          const style = ToolsLineStringStyle(feature);

          return style;
        }
      });
    } else if (type === 'Polygon') {
      const source = new VectorSource({
        features: []
      });

      this.tools.polygon = new VectorLayer({
        source: source,
        zIndex: 8000,
        name: 'Tools_Polygon',
        style: feature => {
          let style;

          if (feature.getGeometry().getType() === 'Circle') {
            style = ToolsCircleStyle(feature);
          } else if (feature.getGeometry().getType() === 'Polygon') {
            style = ToolsPolygonStyle(feature);
          }

          return style;
        }
      });
    }
    if (type === 'GroupLayer') {
      this.group = new LayerGroup({
        layers: [this.tools.marker, this.tools.linestring, this.tools.polygon],
        name: 'Tools'
      });
    }
  }
}

export { ToolsLayer };
